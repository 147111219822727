import { useCallback, useContext, useEffect, useState } from "react";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import UIContext from "../../../../contexts/uiContext/UIContext";
import BasicPagination from "../../../shared/basicPagination/BasicPagination";
import { IPagination } from "../../../../@types/interface/pagination";
import useGetExclusiveLeads from "../../../../utils/hooks/getExclusiveLeads/useGetExclusiveLeads";
import useModalOpen from "../../../../utils/hooks/modalOpen/useModalOpen";
import useQuerySearch from "../../../../utils/hooks/querySearch/useQuerySearch";
import CommonModal from "../../../shared/commonModal/CommonModal";
import CofirmPurchase from "../../../shared/payButton/confirmPurchase/CofirmPurchase";
import useSelectLeads from "../../../../utils/hooks/selectLeadstId/useSelectLeads";
import ProjectCardVertical from "../../../shared/projectCardV2/ProjectCardV2";
import FilterSidebar from "./filterSidebar/FilterSidebar";
import { IconButton } from "@mui/material";
import { api } from "../../../../utils/api";
import CustomerContext from "../../../../contexts/customerContext/customerContext";
import { toast } from "react-toastify";
import { ICustomer } from "../../../../@types/interface/Customer.interface";
import { useNavigate } from "react-router-dom";

const Marketplace = () => {
	const navigate = useNavigate();
	const { setDashboardHeader } = useContext(UIContext);
	const { user, setUser } = useContext(CustomerContext);
	const [pagination, setPagination] = useState<IPagination>({
		currentPage: 1,
		pageCount: 1
	});
	const [filters, setFilters] = useState({
		city: "",
		leadStatus: "",
		bidding_price: 10,
		minPrice: 0,
		maxPrice: 10000,
		property_type: "",
		lead_type: "",
	});

	const leads = useGetExclusiveLeads({ pagination, setPagination, accept_full_data: true, filter: filters });
	const { modalOpen, handleOpenModal, handleCloseModal } = useModalOpen();
	const { selectedLead, handleSetLeads } = useSelectLeads();
	const item = useQuerySearch("item");


	const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");

	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		setPagination((prevPagination: IPagination) => {
			if (prevPagination.currentPage !== value) {
				return {
					...prevPagination,
					currentPage: value
				};
			}
			return prevPagination;
		});
	};


	const handlePurchaseLead = (leadsId: string) => {
		handleSetLeads(leadsId);
		handleOpenModal();
	};

	const handleAddToCart = async (leadId: string) => {
		try {
			const payload = {
				customer_object_id: user?._id,
				lead_object_id: leadId,
			};

			const response = await api.customers.createCustomerCart(payload);
			if (response) {
				const tempUser = { ...user, no_of_items_in_cart: (user?.no_of_items_in_cart || 0) + 1 }
				setUser(tempUser as any);
				toast.success("Successfully added to cart");
				navigate("/customer/exclusive-leads-cart");
			}
		} catch (error: any) {
			alert(error.message || "Failed to add lead to cart.");
		}
	};
	const handleRemoveFromCart = async (leadId: string) => {
		try {
			const params: any = {
				lead_object_id: leadId,
			};

			const response = await api.customers.deleteLeadsFromCart(params);
			if (response) {
				const tempUser = { ...user, no_of_items_in_cart: (user?.no_of_items_in_cart || 0) - 1 }
				setUser(tempUser as any);
				toast.error("Successfully deleted from cart")
			}
		} catch (error: any) {
			alert(error.message || "Failed to add lead to cart.");
		}
	};


	console.log("===>user", user);

	// const handleApplyFilter = (newFilters: any) => {
	// 	console.log("======> parent filter before update", newFilters); // Log before state update
	// 	setFilters(newFilters);
	// 	setPagination((prev) => ({ ...prev, currentPage: 1 })); // Reset to page 1 when filters change
	// };

	const handleFilterChange = (e: any) => {
		const { name, value } = e.target;
		setFilters((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	const handlePriceRangeChange = (minPrice: number, maxPrice: number) => {
		setFilters((prev) => ({
			...prev,
			minPrice,
			maxPrice,
		}));
	};

	useEffect(() => {
		setDashboardHeader("Exclusive Leads");
	}, [setDashboardHeader]);

	useEffect(() => {
		if (item) {
			handlePurchaseLead(item);
		}
	}, [item]);

	return (
		<div>
			<FilterSidebar
				filters={filters}
				setSortOrder={setSortOrder}
				handleFilterChange={handleFilterChange}
				handlePriceRangeChange={handlePriceRangeChange}
			/>
			{leads.map((lead) => (
				<ProjectCardVertical
					key={lead._id}
					lead={lead}
					mode={"MARKETPLACE"}
					handlePurchaseLead={handlePurchaseLead}
					handleAddToCart={() => handleAddToCart(lead._id)}
					handleRemoveFromCart={handleRemoveFromCart}
				/>
			))}
			<BasicPagination
				currentPage={pagination.currentPage}
				pageCount={pagination.pageCount}
				handlePageChange={handlePageChange}
			/>
			<CommonModal open={modalOpen} handleClose={handleCloseModal}>
				{selectedLead ? (
					<CofirmPurchase lead_object_id={selectedLead} key={selectedLead} onClose={handleCloseModal} />
				) : (
					<div>Something went wrong</div>
				)}
			</CommonModal>
		</div>
	);
};

export default Marketplace;
