import { LOGIN_REDIRECTION } from "../../../../../config/config";

const Purchase = ({ loading, handleConfirm }: { handleConfirm: (e: any) => void; loading: boolean }) => {
	return (
		<div className="bg-slate-50 p-5">
			<h2 className="text-2xl font-bold text-center text-gray-800">Confirm Purchase</h2>
			<p className="mt-4 text-center text-gray-600"> you want to purchase this lead?</p>
			<form className="mt-8 space-y-4" onSubmit={handleConfirm}>
				<div className="mb-3 flex items-center">
					<input
						id="terms"
						name="terms"
						type="checkbox"
						className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
						required
					/>
					<label htmlFor="terms" className="ml-2 block text-sm text-gray-900">
						I agree to the{" "}
						<a href={`${LOGIN_REDIRECTION}refund`} className="text-indigo-600 hover:underline" target="_blank" rel="noopener noreferrer">
							leads refund policy
						</a>
					</label>
				</div>
				<div className="flex justify-center mt-8">
					<button
						className={`px-6 py-3 text-lg font-semibold text-white bg-green-500 rounded-lg shadow-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75 ${loading ? "opacity-50 cursor-not-allowed" : ""}`}
						disabled={loading}
					>
						{loading ? "Processing..." : "Yes, Confirm"}
					</button>
				</div>
			</form>
		</div>
	);
};

export default Purchase;
