import React, { useState } from 'react'
import { api } from '../../api';
import { Bounce, toast } from 'react-toastify';

const useGetOtp = (type: string = "registration") => {
	const [loading, setLoading] = useState<boolean>(false);
	const [otp, setOtp] = useState<string>("");
	const [userId, setUserId] = useState<string>("");
	const handleSendOtp = async (email: string) => {
		try {
			setLoading(true);
			const payload = {
				email: email,
				type
			};
			const response = await api.auth.getOtp(payload);
			setOtp(response.result);
			setUserId(response.userId);
		} catch (error: any) {
			console.error("Error signup registration", error);
			const {
				response: { data }
			} = error;
			toast(data.message, {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: false,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
				transition: Bounce
			});
			throw new Error(data.message);
		} finally {
			setLoading(false);
		}
	};
	return { handleSendOtp, loading, otp, userId }
}

export default useGetOtp