import React from "react";
import CommonModal from "../../../../../../../shared/commonModal/CommonModal";

const ConfirmRemove = ({ modalOpen, handleCloseModal, handleDeleteLead }: any) => {
	return (
		<CommonModal handleClose={handleCloseModal} open={modalOpen}>
			<div className="p-4 text-center bg-white rounded">
				<h2 className="text-xl font-semibold mb-4">Confirm lead removal?</h2>
				<p className="mb-4 text-gray-600">
					Please confirm if you want to proceed with removing this lead. This action cannot be undone.
				</p>
				<div className="flex justify-center space-x-4">
					<button
						className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
						onClick={handleDeleteLead}
					>
						Yes
					</button>
					<button
						className="px-4 py-2 bg-gray-300 text-black rounded hover:bg-gray-400"
						onClick={handleCloseModal}
					>
						No
					</button>
				</div>
			</div>
		</CommonModal>
	);
};

export default ConfirmRemove;
