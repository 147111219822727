import { ColDef } from "ag-grid-community";

export const LeadsDataColDefs: ColDef[] = [
	{
		field: "lead_name",
		headerName: "Name"
	},
	{
		field: "lead_email",
		headerName: "Email"
	},
	{
		field: "address",
		headerName: "Address"
	},
	{
		field: "city",
		headerName: "City"
	},
	{
		field: "lead_phone",
		headerName: "Phone No."
	},

	{
		field: "bidding_price",
		headerName: "Bidding Price"
	},
	{
		field: "lead_type",
		headerName: "Status"
	}
];
