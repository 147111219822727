import React from "react";
import GoogleMap from "../../../../shared/googleMap/GoogleMap";

const MapView = ({ address }: { address: string }) => {
	return (
		<div>
			<GoogleMap address={address} height={500} />
		</div>
	);
};

export default MapView;
