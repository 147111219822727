import React, { useState } from "react";
import { Box, Toolbar } from "@mui/material";
import { ToastContainer } from "react-toastify";
import sizeConfigs from "../../../config/sizeConfigs";
import Topbar from "../topbar/Topbar";
import SuperAdminSidebar from "../../shared/sidebar/SuperAdminSidebar";
import { Outlet } from "react-router-dom";

const SuperAdminMainLayout = () => {
	const [sidebarOpen, setSidebarOpen] = useState(true);
	const sidebarWidth = sizeConfigs.sidebar.width;

	const toggleSidebar = () => {
		setSidebarOpen(!sidebarOpen);
	};

	return (
		<div style={{ display: "flex" }} className="main-ui">
			<ToastContainer
				position="top-center"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick={false}
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="colored"
				className={"mt-20"}
			/>
			<Topbar toggleSidebar={toggleSidebar} sidebarOpen={sidebarOpen} />
			<Box
				component="nav"
				sx={{
					width: sidebarOpen ? sidebarWidth : 0,
					flexShrink: 0,
					transition: "width 0.2s ease-in-out"
				}}
			>
				<SuperAdminSidebar toggleSidebar={toggleSidebar} opens={sidebarOpen} />
			</Box>
			<Box
				component="main"
				sx={{
					flexGrow: 1,
					p: 3,
					width: sidebarOpen ? `calc(100% - ${sidebarWidth}px)` : "100%",
					minHeight: "100vh",
					transition: "width 0.2s ease-in-out"
				}}
			>
				<Toolbar />
				<Outlet />
			</Box>
		</div>
	);
};

export default SuperAdminMainLayout;
