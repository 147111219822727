import React, { useCallback, useContext, useRef } from "react";
import { api } from "../../api";
import CustomerContext from "../../../contexts/customerContext/customerContext";
import useQuerySearch from "../querySearch/useQuerySearch";

const useUpdateBalanceByAdmin = () => {
	const hasUpdated = useRef<boolean>(false);
	const customerId = useQuerySearch("customerId");
	const updateWalletBalance = useCallback(
		async (amount: number) => {
			try {
				const payload = {
					customer_id: customerId,
					amount
				};
				const response = await api.wallet.updateBalanceByAdmin(payload);
				if (response) {
					alert("Wallet updated successfully !!");
					window.location.reload();
				}
				console.log("=======>wallet response", response);
				hasUpdated.current = true; // Set the ref to true
			} catch (error) {
				console.error("Error updating wallet:", error);
			} finally {
				localStorage.removeItem("@payment");
			}
		},
		[customerId]
	);
	return {
		updateWalletBalance
	};
};

export default useUpdateBalanceByAdmin;
