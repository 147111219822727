import React, { useContext, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import "./debitCardUi.css";
import useCreateSubscription from "../../../utils/hooks/createSubscription/useCreateSubscription";
import { ISubScriptionModalProps } from "../../../@types/props/subscriptionModalProps.types";
import useOpenConfetti from "../../../utils/hooks/openConfetti/useOpenConfetti";
import usePurchasePremiumLeads from "../../../utils/hooks/purchasePremiumLeads/usePurchasePremiumLeads";
import useQuerySearch from "../../../utils/hooks/querySearch/useQuerySearch";
import CustomerContext from "../../../contexts/customerContext/customerContext";
import { ClientBrandLogo } from "../clientBrandLogo/ClientBrandLogo";
import useGetDiscountPrice from "../../../utils/hooks/useGetDiscountPrice/useGetDiscountPrice";

const ChangeCardModal: React.FC<ISubScriptionModalProps> = ({ open, handleClose, plan, paymentMethod }) => {
	const { user, setUser } = useContext(CustomerContext);
	const stripe = useStripe();
	const elements = useElements();
	const { handleCreateSubscription } = useCreateSubscription();
	const [loading, setLoading] = useState<boolean>(false);
	const { handleExplosion } = useOpenConfetti();
	const { buyLeadHandler } = usePurchasePremiumLeads();
	const { getDiscountPrice, discountPrice, couponCode, handleChangeCoupon, hasAppliedCoupon, isLoading, hasError } = useGetDiscountPrice();
	const item = useQuerySearch("item");
	const [hasChnageCard, setHasChnageCard] = useState<boolean>(false);

	const handleClickApply = async () => {
		console.log("===>clicked", couponCode);
		if (couponCode) {
			await getDiscountPrice(plan.priceId);
		}
		else {
			alert("Please enter a coupon code");
		}
	}

	const handlePay = async () => {
		try {
			setLoading(true);
			if (user?.stripeCustomerId && user?.payment_method_id) {
				const response = await handleCreateSubscription(
					plan._id || "",
					plan.plan_occurance,
					user.payment_method_id,
					couponCode
				);
				if (response) {
					setUser(response.customer);
					handleExplosion();
					if (item) {
						await buyLeadHandler(item);
					}
				}
			} else {
				if (!stripe || !elements) {
					// Stripe.js has not yet loaded
					return;
				}

				// Create a Payment Method
				const cardElement = elements.getElement(CardElement);
				if (!cardElement) {
					console.error("Card element not found");
					return;
				}
				const { error, paymentMethod } = await stripe.createPaymentMethod({
					type: "card",
					card: cardElement
				});

				if (error) {
					console.error("[Error]", error);
				} else {
					console.log("[PaymentMethod]", paymentMethod);

					// Send the PaymentMethod ID to your server
					const response = await handleCreateSubscription(
						plan._id || "",
						plan.plan_occurance,
						paymentMethod?.id || "",
						couponCode
					);
					if (response) {
						if (item) {
							await buyLeadHandler(item);
						}
						handleExplosion();
					}
				}
			}
		} catch (err) {
			alert("Something went wrong");
		} finally {
			setLoading(false);
			handleClose();
		}
	};

	const handleChangeCard = () => {
		setHasChnageCard(!hasChnageCard);
	}
	return (
		<div>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					<ClientBrandLogo />
					<h2>Be Our Customer!!</h2>
				</DialogTitle>
				<DialogContent>

					<div className="w-full space-y-4">
						<div className="mb-4">
							<label className="block text-sm font-medium text-gray-700">Billing Name</label>
							<input
								type="text"
								className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
								placeholder="John Doe"
								defaultValue={user?.first_name + " " + user?.last_name}
							/>
						</div>
						<div className="mb-4">
							<label className="block text-sm font-medium text-gray-700">Billing Address</label>
							<input
								type="text"
								className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
								placeholder="123 Main St"
								defaultValue={user?.address_line_1}
							/>
						</div>
						<div className="mb-4">
							<label className="block text-sm font-medium text-gray-700">Discount Coupon</label>
							<div className="flex items-center space-x-2">
								<input
									type="text"
									value={couponCode}
									onChange={handleChangeCoupon}
									className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
									placeholder="DISCOUNT2023"
								/>

								<button onClick={handleClickApply}
									// disabled={hasAppliedCoupon}
									className={`text-white ${hasAppliedCoupon ? 'bg-green-500 hover:bg-green-600 focus:ring-green-300' : 'bg-blue-700 hover:bg-blue-800 focus:ring-blue-300'} focus:outline-none font-medium rounded-lg text-sm px-5 py-2 text-center inline-flex items-center`}>
									{
										isLoading ? "Loading..." : hasAppliedCoupon ? "Applied" : "Apply"
									}
								</button>
							</div>
							{
								hasError ? <p className="text-red-500 text-sm">Invalid coupon code</p> : null
							}
						</div>
						{
							paymentMethod && !hasChnageCard ? <>
								<div>
									<label className="block text-sm font-medium text-gray-700">Card Number</label>
									<input
										type="text"
										className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
										placeholder="XXXX - XXXX - XXXX - XXXX"
										value={`XXXX - XXXX - XXXX -${paymentMethod.card.last4}`}
									/>
								</div>
								<div className="mb-4 flex flex-row gap-2">
									<div>
										<label htmlFor="expiry">Expiry</label>
										<input
											type="text"
											id="expiry"
											className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
											placeholder="MM/YY"
											value={`${paymentMethod.card.exp_month}/${paymentMethod.card.exp_year}`}
										/>
									</div>
									<div>
										<label htmlFor="cvc">CVC</label>
										<input
											type="text"
											id="cvc"
											className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
											placeholder="CVC"
											value={"XXX"}
										/>
									</div>
								</div>
							</> :
								<CardElement className="card-element" />
						}
					</div>
				</DialogContent>
				<DialogActions>
					<div className="w-full flex flex-col space-y-4">
						<div className="w-full flex justify-between items-center gap-2 md:gap-10 flex-wrap">
							<div className="flex flex-col ps-4">
								<div className="flex items-center space-x-2">
									<span className="text-sm text-gray-800">Total</span>
									<span className="text-sm text-gray-800">
										{discountPrice !== null ? (
											<>
												<span className="line-through">${plan.price}</span> ${discountPrice}
											</>
										) : (
											<span>${plan.price}</span>
										)}
									</span>
								</div>
								<div className="flex items-center space-x-2">
									<span className="text-sm text-gray-500">Renews On</span>
									<span className="text-sm text-gray-500">
										{plan.plan_occurance === "MONTHLY" ? new Date(new Date().setMonth(new Date().getMonth() + 1)).toLocaleDateString() : new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toLocaleDateString()}
									</span>
								</div>
							</div>
							<button type="button" disabled={!stripe} onClick={handlePay} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center ">
								{
									loading ? "Loading..." : "Pay"
								}
								<svg className="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
									<path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
								</svg>
							</button>
						</div>
						<div className="w-full">
							{
								paymentMethod ?
									hasChnageCard ?
										<div>
											<button type="button" onClick={handleChangeCard} className="focus:outline-none text-black bg-white border border-gray-300 w-full hover:bg-gray-200 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2">Go to Previously Added Card</button>
										</div> :
										<div>
											<button type="button" onClick={handleChangeCard} className="focus:outline-none text-white bg-red-70 w-full hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 bg-red-600 ">Change Card & Proceed</button>
										</div> : null
							}
						</div>
					</div>
				</DialogActions>
			</Dialog>
		</div >
	);
};

export default ChangeCardModal;
