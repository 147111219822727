import { useCallback, useEffect, useMemo, useState } from "react";
import { ILeads } from "../../../@types/interface/leadDetails.interface";
import { api } from "../../api";
import { IUseGetLeadsProps } from "../../../@types/props/useGetLeads.props";

const useGetExclusiveLeads = ({
  pagination,
  setPagination,
  accept_full_data,
  filter,
}: IUseGetLeadsProps) => {
  const [leads, setLeads] = useState<ILeads[]>([]);

  // Memoize the filter to prevent unnecessary re-renders
  const memoizedFilter = useMemo(
    () => ({
      city: filter.city,
      leadStatus: filter.leadStatus,
      property_type: filter.property_type,
      minPrice: filter.minPrice,
      maxPrice: filter.maxPrice,
      state: filter.state,
    }),
    [
      filter.city,
      filter.leadStatus,
      filter.property_type,
      filter.minPrice,
      filter.maxPrice,
      filter.state,
    ]
  );

  const getLeadList = useCallback(async () => {
    try {
      const filterToSearch: any = {
        page: pagination?.currentPage,
      };

      if (memoizedFilter.city) filterToSearch.city = memoizedFilter.city;
      if (memoizedFilter.leadStatus)
        filterToSearch.leadStatus = memoizedFilter.leadStatus;
      if (
        memoizedFilter.property_type &&
        memoizedFilter.property_type !== "All"
      )
        filterToSearch.property_type = memoizedFilter.property_type;

      if (
        memoizedFilter.minPrice !== undefined &&
        memoizedFilter.maxPrice !== undefined
      ) {
        filterToSearch.bidding_price = {
          $gte: memoizedFilter.minPrice,
          $lte: memoizedFilter.maxPrice,
        };
      }

      if (memoizedFilter.state && memoizedFilter.state !== "All") {
        filterToSearch.state = memoizedFilter.state;
      }

      if (!accept_full_data && Object.keys(memoizedFilter).length === 0) return;

      const response = await api.leads.getMarketPlaceLeads(filterToSearch);

      if (response) {
        setLeads(response.result);
        if (setPagination) {
          setPagination(prev => ({
            ...prev,
            pageCount: response.pagination.pageCount,
          }));
        }
      }
    } catch (error) {
      console.error("Error while fetching data:", error);
    }
  }, [
    pagination?.currentPage,
    memoizedFilter,
    accept_full_data,
    setPagination,
  ]);

  // Trigger API call on page change or filter change
  useEffect(() => {
    getLeadList();
  }, [getLeadList]);

  return leads;
};

export default useGetExclusiveLeads;
