import React, { useCallback, useState } from "react";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import {
	Button,
	FilledTextFieldProps,
	FormControl,
	InputLabel,
	MenuItem,
	OutlinedTextFieldProps,
	Paper,
	Select,
	SelectChangeEvent,
	StandardTextFieldProps,
	TextField,
	TextFieldVariants
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs, { Dayjs } from "dayjs";
import { JSX } from "react/jsx-runtime";
import { CustomSwitch } from "../../../../shared/switch/CustomSwitch";
import { ILeads } from "../../../../../@types/interface/leadDetails.interface";
import { api } from "../../../../../utils/api";
import { IExclusiveLeads } from "../../../../../@types/interface/exclusiveLeads.interface";
import { IGeneralLeads } from "../../../../../@types/interface/generalLeads.interface";

const AddGeneralLeads = () => {
	const navigate = useNavigate();
	const [leadDetails, setLeadDetails] = useState<IGeneralLeads>();
	const [checked, setChecked] = useState(false);
	const [billable, setBillable] = useState(false);
	const [selectedDate, setSelectedDate] = useState(null);

	const handleDateChange = (date: any) => {
		console.log("date", date);
		setSelectedDate(date);
	};

	const handleChange = (event: any) => {
		const { name, value } = event.target;
		setLeadDetails((prevDetails: any) => ({
			...prevDetails,
			[name]: value
		}));
	};

	const handleStatusChange = (event: SelectChangeEvent) => {
		setLeadStatus(event.target.value as string);
	};

	const [leadStatus, setLeadStatus] = useState("");

	const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setChecked(event.target.checked);
	};

	const handleBillable = (event: React.ChangeEvent<HTMLInputElement>) => {
		setBillable(event.target.checked);
	};

	const handleSubmit = async (event: { preventDefault: () => void }) => {
		event.preventDefault();

		try {
			if (!leadDetails?.lead_name) {
				alert("lead name is required");
			}
			const response = await api.leads.manualUploadGeneralLeads(leadDetails);
			if (response) {
				navigate("/super-admin/leads/general-leads");
				alert("General Lead created successfully");
			}
			if (!response) {
				throw new Error(`API request failed with status ${response.status}`);
			}

			setLeadDetails(leadDetails);
		} catch (error) {
			console.log("Error while adding");
			alert("failed to create leads");
		}
	};

	return (
		<form onSubmit={handleSubmit}>
			<div>
				<div className="button-container">
					<Button variant="contained" className="button-violet" endIcon={<SendOutlinedIcon />}>
						Submit
					</Button>
				</div>
				<h3>Add General Lead Details</h3>
				<Paper className="form-container" variant="outlined" sx={{ marginTop: "20px" }}>
					<div className="flex-input-field">
						<label>Name:</label>
						<TextField
							type="text"
							variant="outlined"
							className="inputbox"
							onChange={handleChange}
							name="lead_name"
							required
						/>
					</div>
					<div className="flex-input-field">
						<label>Email:</label>
						<TextField
							type="email"
							variant="outlined"
							className="inputbox"
							onChange={handleChange}
							name="lead_email"
						/>
					</div>
					<div className="flex-input-field">
						<label>Phone Number:</label>
						<TextField variant="outlined" className="inputbox" onChange={handleChange} name="lead_phone" />
					</div>

					<div className="flex-input-field">
						<label>Address:</label>
						<TextField
							type="text"
							variant="outlined"
							className="inputbox"
							onChange={handleChange}
							name="address_line_1"
						/>
					</div>
					<div className="flex-input-field">
						<label>City:</label>
						<TextField
							type="text"
							variant="outlined"
							className="inputbox"
							onChange={handleChange}
							name="city"
						/>
					</div>
					<div className="flex-input-field">
						<label>Lead Status:</label>
						{/* <div className="switch-button-container">
							<CustomSwitch
								inputProps={{ "aria-label": "controlled" }}
								checked={checked}
								onChange={handleSwitchChange}
								name="lead_status"
							/>
						</div> */}
						<FormControl sx={{ width: "500px" }}>
							<InputLabel id="demo-simple-select-label">Select Status</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={leadStatus}
								label="Select Status"
								name="lead_status"
								onChange={handleStatusChange}
							>
								<MenuItem value={"ACTIVE"}>Active</MenuItem>
								<MenuItem value={"NEW"}>New</MenuItem>
								<MenuItem value={"PENDING"}>Pending</MenuItem>
								<MenuItem value={"CONTACTED"}>Contacted</MenuItem>
							</Select>
						</FormControl>
					</div>
					<div className="flex-input-field">
						<label>Bidding Price (USD):</label>

						<TextField
							type="number"
							variant="outlined"
							className="inputbox"
							onChange={handleChange}
							name="bidding_price"
							required
						/>
					</div>

					<div className="flex-input-field">
						<label>Billable:</label>
						<div className="switch-button-container">
							<CustomSwitch
								inputProps={{ "aria-label": "controlled" }}
								checked={billable}
								onChange={handleBillable}
								name="billable"
								required
							/>
						</div>
					</div>

					<div className="flex-input-field">
						<label>Date:</label>
						<div className="switch-button-container">
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DemoContainer components={["DatePicker"]}>
									<DatePicker
										label="Choose Date"
										value={selectedDate}
										name="date"
										onChange={handleDateChange}
									/>
								</DemoContainer>
							</LocalizationProvider>
						</div>
					</div>
				</Paper>

				<div className="button-container">
					<Button
						variant="contained"
						type="submit"
						className="button-violet"
						endIcon={<SendOutlinedIcon />}
						onClick={handleSubmit}
					>
						Submit
					</Button>
				</div>
			</div>
		</form>
	);
};

export default AddGeneralLeads;
