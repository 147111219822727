import React, { useRef, useState } from 'react'
import CommonModal from '../../../../shared/commonModal/CommonModal'
import Otp from '../../../../shared/otpModal/Otp'
import AlertModal from '../../../../shared/alertModal/AlertModal'
import { LoginOtpModalProps } from '../../../../../@types/props/LoginOtpModalProps'
import { Bounce, toast, ToastContainer } from 'react-toastify'

const LoginOtp = ({ expectedOtp, handleClose, open, handleShowResetPassword }: LoginOtpModalProps) => {
	const [otp, setOtp] = useState(new Array(4).fill(""));
	const inputsRef = useRef<Array<HTMLInputElement | null>>([]);

	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
		if (e.key === "Backspace" && !otp[index]) {
			if (index > 0) {
				inputsRef.current[index - 1]?.focus();
			}
		}
	};

	const handleOtpChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
		const value = e.target.value;
		if (isNaN(Number(value)) || value.length > 1) return;
		const newOtp = [...otp];
		newOtp[index] = value;
		setOtp(newOtp);

		if (index < 3 && value !== "") {
			inputsRef.current[index + 1]?.focus();
		}
	};

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		const enteredOtp = otp.join("");
		if (enteredOtp === expectedOtp) {
			handleClose();
		} else {
			// handleClose();
		}
	};

	const handleCorrectOtp = async () => {
		try {
			const final_otp = otp[0] + otp[1] + otp[2] + otp[3];
			if (String(final_otp) === String(expectedOtp)) {
				handleShowResetPassword();
			}
		} catch (error) {
			toast("Incorrect Otp", {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: false,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
				transition: Bounce
			});
		}
	};
	return (
		<div className="flex flex-row justify-between">
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick={false}
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="colored"
				className={"mt-20"}
			/>
			<CommonModal handleClose={handleClose} open={open}>
				<Otp
					handleOtpChange={handleOtpChange}
					otp={otp}
					handleSubmit={handleSubmit}
					handleSignup={handleCorrectOtp}
					inputsRef={inputsRef}
					handleKeyDown={handleKeyDown}
				/>
			</CommonModal>
		</div>
	)
}

export default LoginOtp