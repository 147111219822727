import { useContext } from "react";
import CustomerContext from "../../../contexts/customerContext/customerContext";
import AdminContext from "../../../contexts/adminContext/adminContext";
import { LOGIN_REDIRECTION } from "../../../config/config";

const useAuth = () => {
  let token = localStorage.getItem("@token");
  let adminId = localStorage.getItem("@adminId");
  const { setUser } = useContext(CustomerContext);
  const { setAdmin } = useContext(AdminContext);

  const handleLogout = () => {
    localStorage.clear();
    setUser(null);
    setAdmin(null);
    token = null;
    adminId = null;
    window.location.reload();
  };

  return { token, adminId, handleLogout };
};

export default useAuth;
