import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { IInfluencer } from "../../../../../@types/interface/influencer.interface";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export const ReferralCellRenderer = ({ data }: { data: IInfluencer }) => {
	const host = window.location.host;
	const copyUrl = () => {
		navigator.clipboard.writeText(`https://${host}/sign-up?referralCode=${data.coupon_code}`);
		alert("Copied!");
	};

	return (
		<p className="flex gap-2 items-center cursor-pointer" >
			<ContentCopyIcon onClick={copyUrl} />
			https://{host}/sign-up?referralCode={data.coupon_code}

		</p>
	);
};
