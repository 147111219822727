import React, { ChangeEvent, useContext, useEffect, useState } from "react";

import { Box, Card, Paper, Switch, Theme, createStyles, makeStyles } from "@mui/material";
import { BarChart, DashboardCard, DonutChart, LineChart, Map, PieChart } from "../../shared";
import ViewStreamIcon from "@mui/icons-material/ViewStream";
import LandscapeIcon from "@mui/icons-material/Landscape";
import PeopleIcon from "@mui/icons-material/People";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { red } from "@mui/material/colors";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import ScheduleOutlinedIcon from "@mui/icons-material/ScheduleOutlined";
import "./dashboard.css";
import UIContext from "../../../contexts/uiContext/UIContext";

const Dashboard = () => {
	const [switchValue, setSwitchValue] = useState<boolean>(false);
	const { setDashboardHeader } = useContext(UIContext);
	const handleSwitchChange = (event: ChangeEvent<HTMLInputElement>) => {
		setSwitchValue(event.target.checked);
	};

	const lead = [
		{
			id: "Website",
			label: "Website",
			value: 215,
			color: "hsl(272, 70%, 50%)"
		},
		{
			id: "Channel Partner",
			label: "Channel Partner",
			value: 497,
			color: "hsl(157, 70%, 50%)"
		},
		{
			id: "Others",
			label: "Others",
			value: 342,
			color: "hsl(52, 70%, 50%)"
		}
	];
	const leadsData = [
		{
			id: "Leads Ingest",
			label: "Ingested",
			value: 215,
			color: "hsl(272, 70%, 50%)"
		},
		{
			id: "Leads Accept",
			label: "Accepted",
			value: 497,
			color: "hsl(157, 70%, 50%)"
		},
		{
			id: "Leads Reject",
			label: "Rejected",
			value: 342,
			color: "hsl(52, 70%, 50%)"
		},
		{
			id: "Duplicate Leads",
			label: "Duplicated",
			value: 373,
			color: "hsl(198, 70%, 50%)"
		},
		{
			id: "Leads Return",
			label: "Returned",
			value: 534,
			color: "hsl(222, 70%, 50%)"
		}
	];
	const lineChart = [
		{
			id: "leads",
			color: "hsl(293, 70%, 50%)",
			data: [
				{
					x: "Day 1",
					y: 23
				},
				{
					x: "Day 2",
					y: 170
				},
				{
					x: "Day 3",
					y: 86
				},
				{
					x: "Day 4",
					y: 228
				},
				{
					x: "Day 5",
					y: 46
				},
				{
					x: "Day 6",
					y: 112
				},
				{
					x: "Day 7",
					y: 32
				}
			]
		}
	];

	const barChart = [
		{
			day: "Day 1",
			value: 190,
			color: "hsl(326, 70%, 50%)"
		},
		{
			day: "Day 2",
			value: 141,
			color: "hsl(132, 70%, 50%)"
		},
		{
			day: "Day 3",
			value: 56,
			color: "hsl(227, 70%, 50%)"
		},
		{
			day: "Day 4",
			value: 191,
			color: "hsl(286, 70%, 50%)"
		},
		{
			day: "Day 5",
			value: 163,
			color: "hsl(227, 70%, 50%)"
		},
		{
			day: "Day 6",
			value: 120,
			color: "blue"
		},
		{
			day: "Day 7",
			value: 4,
			color: "red"
		}
	];

	useEffect(() => {
		setDashboardHeader("Dashboard");
	}, [setDashboardHeader]);
	return (
		<div className="home-page">
			<div className="cards-section">
				<Paper className="revenue-card">
					<h2 style={{ color: "#B961F8", textAlign: "center" }}>$100</h2>
					<div className="text">
						<p>Revenue Earned</p>
						<span>
							<AttachMoneyOutlinedIcon />
						</span>
					</div>
				</Paper>
				<Paper className="leads-card">
					<h2 style={{ color: "#0BC34F", textAlign: "center" }}>900</h2>
					<div className="text">
						<p>Total Leads</p>
						<span>
							<CheckOutlinedIcon />
						</span>
					</div>
				</Paper>
				<Paper className="customer-card">
					<h2 style={{ color: "#0F9BBE", textAlign: "center" }}>2K+</h2>
					<div className="text">
						<p>Total Customers</p>
						<span>
							<PermIdentityOutlinedIcon />
						</span>
					</div>
				</Paper>
				<Paper className="request-card">
					<h2 style={{ color: "#F98F1E", textAlign: "center" }}>300</h2>
					<div className="text">
						<p>Pending Request</p>
						<span>
							<ScheduleOutlinedIcon />
						</span>
					</div>
				</Paper>

			</div>
			<div className="mid-section">
				<div className="bigchart-container">
					<div className="top-section">
						<h2 style={{ textAlign: "center" }}>
							{/* {switchValue ? "Leads Generation" : "Total Leads from Builder"} */}
							Lead Statistics
						</h2>

					</div>
					<div className="bottom-section">
						{/* {switchValue ? (
              <PieChart pieChartData={lead} />
            ) : (
              <PieChart pieChartData={builder} />
            )} */}
						<PieChart pieChartData={leadsData} />

					</div>
				</div>
				<div className="two-charts-container">

					<div className="bottom-chart">
						<div className="top-section">
							<h2>Analytics</h2>
						</div>
						<div className="bottom-section">
							<BarChart barChartData={barChart} />
						</div>
					</div>
				</div>
			</div>
			{/* <div className="map-section">
        <div className="top-section">
          <h2>Customer Location</h2>
        </div>
        <div className="bottom-section">
          <Map />
        </div>
      </div> */}
		</div>
	);
};

export default Dashboard;
