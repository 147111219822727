import React from 'react'
import { IApplyCoupon } from '../../../../../@types/props/applyCoupon.props'

const ApplyCoupon: React.FC<IApplyCoupon> = ({ coupon, handleApplyCoupon, handleCouponChange, hasAppliedCoupon, hasError, isLoading }) => {
	return (
		<div>
			<div className="space-y-4 rounded-lg border border-gray-200 bg-white p-4 shadow-sm sm:p-6">
				<form onSubmit={handleApplyCoupon}>
					<div>
						<label htmlFor="voucher" className="mb-2 block text-sm font-medium text-gray-900 "> Do you have a voucher or gift card? </label>
						<input value={coupon} onChange={handleCouponChange} type="text" id="coupon" name='coupon' className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-primary-500 focus:ring-primary-500" placeholder="Enter Coupon Code Here" required />
					</div>
					{hasError && <p className="text-red-500 text-sm">Invalid Coupon Code</p>}
					{hasAppliedCoupon && <p className="text-green-500 text-sm">Coupon Applied Successfully !</p>}
					<button type="submit" className="flex mt-4 w-full items-center justify-center rounded-lg bg-blue-700 px-5 py-2.5 text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300">
						{isLoading ? "Applying Coupon..." : "Apply Coupon"}
					</button>
				</form>
			</div>
		</div>
	)
}

export default ApplyCoupon