import { ListItemButton, ListItemIcon } from "@mui/material";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import colorConfigs from "../../../../config/colorConfigs";
import { RootState } from "../../../../redux/store";
import { RouteType } from "../../../../routes/config";

type Props = {
	item: RouteType;
};

const SidebarItem = ({ item }: Props) => {
	const { appState } = useSelector((state: RootState) => state.appState);
	const location = useLocation();

	const isOnCustomerPage = location.pathname.startsWith("/customer");

	return item.sidebarProps && item.path ? (
		<ListItemButton
			component={Link}
			to={item.path}
			sx={{
				"&: hover": {
					backgroundColor: colorConfigs.customerSidebar.hoverBg
				},
				backgroundColor:
					appState === item.state && isOnCustomerPage ? colorConfigs.customerSidebar.activeBg : "unset",
				paddingY: "10px",
				borderRadius: "0px 20px 20px 0px",
				marginBottom: "15px"
			}}
		>
			<ListItemIcon
				sx={{
					color: colorConfigs.customerSidebar.color
				}}
			>
				{item.sidebarProps.icon && item.sidebarProps.icon}
			</ListItemIcon>
			{item.sidebarProps.displayText}
		</ListItemButton>
	) : null;
};

export default SidebarItem;
