import { useContext, useState } from "react";
import CustomerContext from "../../../contexts/customerContext/customerContext";
import { useNavigate } from "react-router-dom";
import { api } from "../../api";
import useOpenConfetti from "../openConfetti/useOpenConfetti";

const usePurchaseCartLead = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const { user } = useContext(CustomerContext);
	const navigate = useNavigate();
	const { handleExplosion } = useOpenConfetti();
	const handleConfirmPurchaseFromCart = async (coupon_code?: string) => {
		try {
			if (!user) {
				alert("Please Login to Continue");
				return;
			}
			setLoading(true);
			const payload = {
				coupon_code
			}
			const response = await api.customers.purchaseLeadFromBucket(payload);
			if (response) {
				handleExplosion();
				setTimeout(() => {
					navigate("/customer/purchased-leads/exclusive-leads");
				}, 1000);
			} else {
				alert("Something went wrong");
			}
		} catch (error) {
			alert("failed to create lead bucket");
		} finally {
			setLoading(false);
		}
	};
	return { loading, handleConfirmPurchaseFromCart };
};

export default usePurchaseCartLead;
