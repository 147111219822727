import { useContext, useState } from "react";
import { IPlan } from "../../../../@types/interface/plan.interface";
import CustomerContext from "../../../../contexts/customerContext/customerContext";
import SubscriptionCardWrapper from "../../../shared/subscriptionCardWrapper/SubscriptionCardWrapper";
import { handleOpenMail } from "../../../../utils/commonFunctions/handleOpenMail";

const PlanCard = ({ plan, isYearly, paymentMethod }: { plan: IPlan; isYearly: boolean, paymentMethod: any[] }) => {
	const [open, setOpen] = useState<boolean>(false);
	const { user, setUser } = useContext(CustomerContext);

	const handleToogleDialog = () => {
		setOpen((prev) => !prev);
	};
	const handleCloseDialog = () => {
		setOpen(false);
	};

	const handleActivatePlan = async () => {
		handleToogleDialog();
	};

	return (
		<div
			className='relative flex flex-col mx-auto max-w-sm text-gray-900 rounded-2xl bg-white
				 p-6 xl:py-9 xl:px-12 transition-all duration-500 overflow-hidden border border-gray-300 w-full'
		>
			{user?.purchase_planid === plan._id ? (
				<div
					className="absolute bg-red-500 text-center text-white font-bold text-xs px-2 py-1 top-3 right-3 rotate-[45deg] shadow-md"
					style={{
						width: "55%", // Increase width to extend beyond the corner
						transform: "rotate(45deg) translate(30%, -60%)" // Adjust positioning to align
					}}
				>
					Subscribed
				</div>
			) : null}
			{plan.is_recommended && (
				<div className="absolute top-0 right-0 bg-gradient-to-r from-indigo-600 to-violet-600 text-white text-xs font-bold px-2 py-1 rounded-bl-lg">
					RECOMMENDED
				</div>
			)}
			<h4 className="font-manrope text-center text-2xl font-bold mb-3">{plan.plan_name}</h4>
			<p className="text-gray-500 text-lg">{plan.plan_details.length > 40 ? `${plan.plan_details.substring(0, 40)}...` : plan.plan_details}</p>
			<div className="flex items-center justify-center my-5">
				<p className="font-manrope mr-2 text-center text-4xl font-semibold text-black">{plan.price ? `$${plan.price}` : "Lets talk !"}</p>
				{plan.price > 0 ? <span className="text-xl text-gray-500">/ {isYearly ? "year" : "month"}</span> : null}
			</div>
			<ul className="mb-6 space-y-4 text-left text-lg text-gray-500">
				{plan.benefits.map((benefit: any) => (
					<li key={benefit.id} className="flex items-center space-x-2">
						<svg
							className="flex-shrink-0 w-5 h-5 text-green-400"
							viewBox="0 0 30 30"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M10 14.7875L13.0959 17.8834C13.3399 18.1274 13.7353 18.1275 13.9794 17.8838L20.625 11.25M15 27.5C8.09644 27.5 2.5 21.9036 2.5 15C2.5 8.09644 8.09644 2.5 15 2.5C21.9036 2.5 27.5 8.09644 27.5 15C27.5 21.9036 21.9036 27.5 15 27.5Z"
								stroke="currentColor"
								strokeWidth="1.6"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
						<span className="no-wrap text-black">{benefit.benifit}</span>
					</li>
				))}
			</ul>
			{plan.price !== 0 ? (
				<button
					className="py-2 w-full px-5 bg-blue-600 cursor-pointer shadow-sm rounded-md transition-all duration-500 text-base text-white font-semibold text-center block mx-auto hover:bg-indigo-700"
					onClick={handleActivatePlan}
					disabled={user?.purchase_planid === plan._id}
				>
					Activate
				</button>
			) : (
				<div onClick={handleOpenMail} className="py-2 w-full px-5 bg-blue-600 cursor-pointer shadow-sm rounded-md transition-all duration-500 text-base text-white font-semibold text-center block mx-auto hover:bg-indigo-700">
					Contact Us
				</div>
			)}
			{
				<SubscriptionCardWrapper
					open={open}
					handleClose={handleToogleDialog}
					onClick={handleCloseDialog}
					plan={plan}
					paymentMethod={paymentMethod}
				/>
			}
		</div >
	);
};

export default PlanCard;
