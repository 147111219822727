import { useCallback, useContext, useEffect, useState } from "react";
import { api } from "../../api";
import CustomerContext from "../../../contexts/customerContext/customerContext";

const useVerifyToken = () => {
  const { setUser } = useContext(CustomerContext);
  const [verified, setVerified] = useState<boolean>(false);
  const [hasFetched, setHasFetched] = useState<boolean>(false);

  const verifyUserLoggedin = useCallback(async () => {
    try {
      const response = await api.auth.verifyUser();
      if (response) {
        setUser(response);
        setVerified(true);
      }
    } catch (error) {
      console.log(error);
      localStorage.removeItem("token");
    } finally {
      setHasFetched(true);
    }
  }, []);

  useEffect(() => {
    verifyUserLoggedin();
  }, [verifyUserLoggedin]);

  return { verified, hasFetched };
};

export default useVerifyToken;
