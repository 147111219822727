import { useCallback, useEffect, useMemo, useState } from "react";
import { ILeads } from "../../../@types/interface/leadDetails.interface";
import { api } from "../../api";
import { IUseGetCartLeadsProps } from "../../../@types/props/useGetCartLeads.props";

const useGetCustomerCartLeads = ({
  customerId,
  pagination,
  setPagination,
  sortField = "updatedAt",
}: IUseGetCartLeadsProps) => {
  const [leads, setLeads] = useState<ILeads[]>([]);

  const getCustomerCartLeads = useCallback(async () => {
    try {
      if (!customerId) return;

      const params: any = {
        customer_object_id: customerId,
        page: pagination?.currentPage || 1,
        sortField,
      };

      const response = await api.customers.getCustomerCartLeads(params);

      if (response) {
        console.log("API Response:", response); // 👉 Add this to check API data
        setLeads(response.result || []);
        if (setPagination) {
          setPagination((prev: any) => ({
            ...prev,
            pageCount: response.pagination.pageCount,
          }));
        }
      }
    } catch (error) {
      console.error("Error fetching customer cart leads:", error);
    }
  }, [customerId, pagination?.currentPage, sortField, setPagination]);

  useEffect(() => {
    getCustomerCartLeads();
  }, [getCustomerCartLeads]);

  return leads;
};

export default useGetCustomerCartLeads;
