import { Autocomplete, Button, Paper, TextField } from "@mui/material";
import React, { useState } from "react";

import { ICustomer } from "../../../../../@types/interface/Customer.interface";
import { State } from "../../../../shared/state/State";
import useCustomerDetails from "../../../../../utils/hooks/customerDetails/useCustomerDetails";
import useQuerySearch from "../../../../../utils/hooks/querySearch/useQuerySearch";

const CustomerData = () => {
	const [isReadOnly, setIsReadOnly] = useState(true);
	const customerId = useQuerySearch("customerId");
	const { customerDetails } = useCustomerDetails(customerId || "");

	return (
		<>
			<div className="edit-btn-container" onClick={() => setIsReadOnly(!isReadOnly)}>
				<Button className="button-violet">{isReadOnly ? "Edit" : "Save"}</Button>
			</div>
			<h3>Customer Details</h3>
			<div>
				<Paper>
					<div className="flex-input-field">
						<label>Name:</label>
						<TextField
							type="text"
							sx={{
								"& .MuiOutlinedInput-root": {
									"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
										borderColor: "#7c00fe"
									},

									"& .MuiOutlinedInput-notchedOutline": {
										borderWidth: "1px"
									}
								}
							}}
							className="inputbox"
							variant="outlined"
							value={customerDetails?.first_name}
							inputProps={{ readOnly: isReadOnly }}
						/>
					</div>
					<div className="flex-input-field">
						<label>Email:</label>
						<TextField
							type="email"
							sx={{
								"& .MuiOutlinedInput-root": {
									"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
										borderColor: "#7c00fe"
									},

									"& .MuiOutlinedInput-notchedOutline": {
										borderWidth: "1px"
									}
								}
							}}
							className="inputbox"
							variant="outlined"
							value={customerDetails?.email}
							InputProps={{ readOnly: isReadOnly }}
						/>
					</div>
					<div className="flex-input-field">
						<label>Phone:</label>
						<TextField
							className="inputbox"
							sx={{
								"& .MuiOutlinedInput-root": {
									"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
										borderColor: "#7c00fe"
									},

									"& .MuiOutlinedInput-notchedOutline": {
										borderWidth: "1px"
									}
								}
							}}
							variant="outlined"
							value={customerDetails?.phone_number}
							inputProps={{ readOnly: isReadOnly }}
						/>
					</div>

					<div className="flex-input-field">
						<label>Address:</label>
						<TextField
							type="email"
							className="inputbox"
							variant="outlined"
							sx={{
								"& .MuiOutlinedInput-root": {
									"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
										borderColor: "#7c00fe"
									},

									"& .MuiOutlinedInput-notchedOutline": {
										borderWidth: "1px"
									}
								}
							}}
							value={customerDetails?.address_line_1}
							inputProps={{ readOnly: isReadOnly }}
						/>
					</div>
					<div className="flex-input-field">
						<label>City:</label>
						<TextField
							type=""
							sx={{
								"& .MuiOutlinedInput-root": {
									"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
										borderColor: "#7c00fe"
									},

									"& .MuiOutlinedInput-notchedOutline": {
										borderWidth: "1px"
									}
								}
							}}
							className="inputbox"
							variant="outlined"
							value={customerDetails?.city}
							inputProps={{ readOnly: isReadOnly }}
						/>
					</div>
					<div className="flex-input-field">
						<label>State:</label>
						<Autocomplete
							disablePortal
							options={State}
							sx={{
								"& .MuiOutlinedInput-root": {
									"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
										borderColor: "#7c00fe"
									},

									"& .MuiOutlinedInput-notchedOutline": {
										borderWidth: "1px"
									}
								}
							}}
							className="inputbox"
							renderInput={(params: any) => <TextField {...params} label="State" />}
							value={customerDetails?.state}
							readOnly={isReadOnly}
							freeSolo
						/>
					</div>
					{/* <div className="flex-input-field">
						<label>Wallet Balance (USD):</label>
						<TextField type="number" className="inputbox" variant="outlined" defaultValue={100} />
					</div>
					<div className="flex-input-field">
						<label>Status:</label>
						<div className="switch-button-container">
							<CustomSwitch
								checked={checked}
								onChange={handleSwitchChange}
								inputProps={{ "aria-label": "controlled" }}
							/>
						</div>
					</div> */}
				</Paper>
			</div>
		</>
	);
};

export default CustomerData;
