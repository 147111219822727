import React from "react";
import { AnnouncementCardProps } from "../../../@types/props/announcementCard.props";

const AnnouncementCard: React.FC<AnnouncementCardProps> = ({
	title,
	description,
	mediaUrl,
	mediaType,
}) => {


	return (
		<div className="w-full bg-gray-100 p-4 rounded-lg shadow-md px-8 py-4 ">
			<h1 className="text-3xl font-bold mb-2 mt-4">{title}</h1>
			<p className="text-gray-700 mb-4">{description}</p>

			<div className=" p-2">
				{/* Image Display */}
				{mediaUrl && mediaType === "image" && (
					<div className="w-full h-96  relative rounded-lg">
						<img
							src={mediaUrl}
							alt="Banner"
							className="absolute top-0 left-0 w-full h-full object-cover rounded-lg border border-gray-400"
						/>
					</div>
				)}

				{/* Video Display */}
				{mediaUrl && mediaType === "video" && (
					<div className="w-full mb-4">
						<video controls className="w-full h-96 rounded-lg border border-gray-400">
							<source src={mediaUrl} type="video/mp4" />
							Your browser does not support the video tag.
						</video>
					</div>
				)}
			</div>
		</div>
	);
};

export default AnnouncementCard;
