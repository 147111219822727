import { Button, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { IExclusiveLeads } from "../../../../../../../@types/interface/exclusiveLeads.interface";
import useDeletePremiumLeadsBucket from "../../../../../../../utils/hooks/deletePremiumLeadsBucket/useDeletePremiumLeadsBucket";
import { ILeads } from "../../../../../../../@types/interface/leadDetails.interface";
import useDeleteExclusiveLeadsBucket from "../../../../../../../utils/hooks/deleteExclusiveLeadsBucket/useDeleteExclusiveLeadsBucket";

const ExclusiveLeadsRemoveCellRenderer = ({ data }: { data: ILeads }) => {
	const { deleteExclusiveLeadsBucket } = useDeleteExclusiveLeadsBucket();
	return (
		<div>
			<IconButton>
				<Button
					variant="outlined"
					className="purple-outlined-button"
					onClick={() => deleteExclusiveLeadsBucket(data._id)}
				>
					Remove lead
				</Button>
			</IconButton>
		</div>
	);
};

export default ExclusiveLeadsRemoveCellRenderer;
