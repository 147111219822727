import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../utils/hooks/auth/useAuth";

const INACTIVITY_LIMIT = 30 * 60 * 1000; // 30 minutes

const AutoLogout = () => {
	const { handleLogout } = useAuth();
	const timerRef = useRef<NodeJS.Timeout | null>(null);

	const resetTimer = () => {
		if (timerRef.current) {
			clearTimeout(timerRef.current);
		}
		timerRef.current = setTimeout(() => {
			handleLogout();
		}, INACTIVITY_LIMIT);
	};

	useEffect(() => {
		const events = ["mousemove", "keydown", "click", "scroll", "touchstart"];

		const handleActivity = () => resetTimer();

		events.forEach((event) => window.addEventListener(event, handleActivity));

		resetTimer(); // Start the timer on mount

		return () => {
			events.forEach((event) => window.removeEventListener(event, handleActivity));
			if (timerRef.current) clearTimeout(timerRef.current);
		};
	}, []);

	return null; // This component doesn't render anything
};

export default AutoLogout;
