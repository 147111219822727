import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useCallback, useContext, useEffect, useState } from "react";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";

import { FilterModel } from "ag-grid-community";
import { formatFilters } from "../../../../utils/commonFunctions/formatApiFilters";
import UIContext from "../../../../contexts/uiContext/UIContext";
import BasicPagination from "../../../shared/basicPagination/BasicPagination";
import { IPagination } from "../../../../@types/interface/pagination";
import { api } from "../../../../utils/api";
import DataGrid from "../../../shared/dataGrid/DataGrid";
import { IExclusiveLeads } from "../../../../@types/interface/exclusiveLeads.interface";
import GridOnIcon from "@mui/icons-material/GridOn";
import { EXCLUSIVE_LEADS_SHEET_URL } from "../../../../config/config";
import { PremiumLeadColDefs } from "./exclusiveLeadsColDefs/premiumLeadsColDefs";
import { lime } from "@mui/material/colors";

const PremiumLeads = () => {
	const navigate = useNavigate();
	const { setDashboardHeader } = useContext(UIContext);
	const [loading, setLoading] = useState<boolean>(false);
	const [rowData, setRowData] = useState<IExclusiveLeads[]>([]);
	const [filters, setFilters] = useState([]);
	const [pagination, setPagination] = useState<IPagination>({
		currentPage: 1,
		pageCount: 1
	});

	const handleFilterChange = (filterModel: FilterModel) => {
		setFilters((prevFilters: any) => {
			const sanitizedFilters = { ...prevFilters };
			Object.keys(sanitizedFilters).forEach((key: any) => {
				if (!filterModel[key]) {
					delete sanitizedFilters[key];
				}
			});
			const updatedFilters = { ...sanitizedFilters, ...filterModel };
			console.log("Updated Filters-->", updatedFilters);
			return updatedFilters;
		});
	};

	const handlePageChange = useCallback((event: React.ChangeEvent<unknown>, value: number) => {
		setPagination((prevPagination: IPagination) => ({
			...prevPagination,
			currentPage: value
		}));
	}, []);

	const handleLeadBulkUploadClick = useCallback(() => {
		const pathname = "/super-admin/premium-leads-bulk-upload";
		navigate(pathname);
	}, [navigate]);

	const handleLeadUpload = () => {
		navigate("/super-admin/add-premium-leads");
	};

	const getExclusiveLeadList = useCallback(async () => {
		try {
			const formattedFilter = formatFilters(filters);
			console.log("Formatted filters-->", formattedFilter);
			setLoading(true);

			const filter = {
				...formattedFilter,
				page: pagination.currentPage,
				limit: 30
			};
			const response = await api.leads.getExclusiveLeads(filter);
			if (response) {
				setRowData(response.result);
				setPagination(response.pagination);
			}
		} catch (error) {
			console.error("Error while fetching data:", error);
		} finally {
			setLoading(false);
		}
	}, [pagination.currentPage, filters]);

	const handleOpenGoogleSheet = () => {
		window.open(EXCLUSIVE_LEADS_SHEET_URL, "_blank");
	};

	useEffect(() => {
		setDashboardHeader("All Premium Leads");
	}, [setDashboardHeader]);

	useEffect(() => {
		getExclusiveLeadList();
	}, [getExclusiveLeadList]);

	return (
		<div className="builders-container">
			<div className="add-btn">
				<Button
					component="label"
					className="white-btn"
					variant="outlined"
					endIcon={<GridOnIcon />}
					onClick={handleOpenGoogleSheet}
				>
					Open Google Sheet
				</Button>
				<Button
					className="button-dark-blue"
					endIcon={<AddCircleOutlineOutlinedIcon />}
					onClick={handleLeadUpload}
				>
					Add Manual Lead
				</Button>
				<Button
					component="label"
					// role={undefined}
					variant="contained"
					tabIndex={-1}
					className="button-violet"
					endIcon={<CloudUploadOutlinedIcon />}
					onClick={handleLeadBulkUploadClick}
				>
					Lead Bulk Upload
				</Button>
			</div>
			<DataGrid colDefs={PremiumLeadColDefs} rowData={rowData} key={0} onFilterChange={handleFilterChange} />

			<BasicPagination
				currentPage={pagination.currentPage}
				pageCount={pagination.pageCount}
				handlePageChange={handlePageChange}
			/>
			{/* <BasicPagination /> */}
		</div>
	);
};

export default PremiumLeads;
