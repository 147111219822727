import { ColDef } from "ag-grid-community";
import NameCellRenderer from "../../components/pages/allCustomers/nameCellRenderer/NameCellRenderer";
import CustomerCellRenderer from "../../components/pages/allCustomers/customerCellRenderer/CustomerCellRenderer";

export const CustomerColDefs: ColDef[] = [
  {
    field: "first_name",
    headerName: "Name",
    cellRenderer: NameCellRenderer,
    suppressSizeToFit: true,
    flex: 1,
    floatingFilter: true,
    filter: "agTextColumnFilter",
  },
  {
    field: "email",
    headerName: "Email",
    suppressSizeToFit: true,
    flex: 1,
    floatingFilter: true,
    filter: "agTextColumnFilter",
  },
  {
    field: "is_subscription",
    headerName: "Subscribed",
    suppressSizeToFit: true,
    flex: 1,
  },
  {
    field: "premium_leads_limit",
    headerName: "Premium Leads Limit",
    suppressSizeToFit: true,
    flex: 1,
    editable: true,
  },

  { field: "action", headerName: "Action", cellRenderer: CustomerCellRenderer },
];
