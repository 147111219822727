import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

// import { accountConflict } from "../../../assets/images";

const AlertModal = ({ open, handleClose, message, heading }: any) => {
	return (
		<Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title">
			<DialogContent>
				<div className="flex  space-x-4">
					<img src={""} alt="Alert" className="w-72 h-72" />
					<div className="">
						<div className="w-64 text-3xl text-gray-700 pt-5 font-semibold">
							<p>{heading}</p>
						</div>
						<div className="mt-4 w-60 pr-5 text-sm">{message}</div>
						<div
							onClick={handleClose}
							className=" text-center bg-orange-400 px-5 py-2 mt-5 w-32 text-white rounded-sm"
						>
							Retry
						</div>
					</div>
				</div>
			</DialogContent>
			{/* <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions> */}
		</Dialog>
	);
};

export default AlertModal;
