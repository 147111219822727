import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ICustomer } from "../../../../@types/interface/Customer.interface";

const CustomerCellRenderer = ({ data }: { data: ICustomer }) => {
	const navigate = useNavigate();

	const handleRouteToCustomerdetails = () => {
		navigate(`/super-admin/customer-details?customerId=${data._id}`);
	};
	return (
		<div>
			<Button variant="outlined" className="purple-outlined-button" onClick={handleRouteToCustomerdetails}>
				See Details
			</Button>
		</div>
	);
};

export default CustomerCellRenderer;
