import { AdminAction } from "../../@types/contexts/adminContext/adminAction.types";
import { Store } from "../../@types/contexts/adminContext/store.types";
import actions from "./actions";

const reducer = (state: Store, action: AdminAction) => {
	switch (action.type) {
		case actions.SET_ADMIN: {
			console.log("====>set admin details");
			if (action.payload.admin) {
				localStorage.setItem("@adminId", action.payload.admin._id);
			}
			return {
				...state,
				isLoggedIn: true,
				admin: action.payload.admin
			};
		}
		default:
			throw new Error("Unexpected action: Admin Context");
	}
};

export default reducer;
