import React, { useContext, useEffect, useState } from "react";
import useQuerySearch from "../../../../utils/hooks/querySearch/useQuerySearch";
import UIContext from "../../../../contexts/uiContext/UIContext";
import useCustomerDetails from "../../../../utils/hooks/customerDetails/useCustomerDetails";
import SelectLeadsForm from "../selectLeadsForm/SelectLeadsForm";
import DataGrid from "../../../shared/dataGrid/DataGrid";
import { LeadsDataColDefs } from "../../allCustomers/customerDetails/customerLeadsData/leadsdataColDefs/LeadsDataColDefs";
import { ILeads } from "../../../../@types/interface/leadDetails.interface";
import { IExclusiveLeads } from "../../../../@types/interface/exclusiveLeads.interface";
import useGetExclusiveLeadsAdmin from "../../../../utils/hooks/getExclusiveLeadsAdmin/useGetExclusiveLeadsAdmin";
import useAssignExclusiveLead from "../../../../utils/hooks/assignExclusiveLead/useAssignExclusiveLead";

const AssignExclusiveLeads = () => {
	const { setDashboardHeader } = useContext(UIContext);
	const customerId = useQuerySearch("customerId");
	const [selectedLeads, setSelectedLeads] = useState<(IExclusiveLeads | ILeads)[]>([]);
	const [searchBy, setSearchBy] = useState<"name" | "zip">("name");
	const [filter, setFilter] = useState<Partial<ILeads>>({});
	const { customerDetails } = useCustomerDetails(customerId || "");
	const leadDetails = useGetExclusiveLeadsAdmin({
		filter,
		accept_full_data: false
	});
	const { handleAsssignExclusiveLead } = useAssignExclusiveLead();

	const handleAssignButtonClick = async () => {
		if (!customerId || customerId === "") return;
		const lead_id_list = selectedLeads.map((lead) => lead._id);
		const response = await handleAsssignExclusiveLead(customerId, lead_id_list);
		if (response) {
			setSelectedLeads([]);
		}
	};

	const handleSearchBy = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value as "name" | "zip";
		setSearchBy(value);
	};

	const handleChange = (value: string) => {
		if (value === "") {
			setFilter({});
		} else {
			setFilter(Object.assign({}, filter, { lead_name: { $regex: value, $options: "i" } }));
		}
	};

	const handleSelectLeads = (value: (IExclusiveLeads | ILeads)[]) => {
		setSelectedLeads(value);
	};

	useEffect(() => {
		setDashboardHeader("Assign Exclusive Leads");
	}, [setDashboardHeader]);
	return (
		<div>
			<h2 className="font-semibold texgt-4xl">Search Lead</h2>
			<SelectLeadsForm
				searchBy={searchBy}
				handleSearchBy={handleSearchBy}
				customerDetails={customerDetails}
				leads={leadDetails}
				handleChange={handleChange}
				handleSelectLeads={handleSelectLeads}
			/>
			<div className="mt-6">
				<h2 className="font-semibold texgt-4xl">Selected Lead Details</h2>
				<DataGrid rowData={selectedLeads} colDefs={LeadsDataColDefs} />
			</div>
			<div className="mt-6 flex justify-end">
				<button
					className="bg-blue-500 text-white font-bold py-2 px-4 rounded"
					onClick={handleAssignButtonClick}
				>
					Add Selected Leads
				</button>
			</div>
		</div>
	);
};

export default AssignExclusiveLeads;
