import { api } from "../../api";
import useQuerySearch from "../querySearch/useQuerySearch";

const useDeletePremiumLeadsBucket = () => {
	const customerId = useQuerySearch("customerId");

	const deletePremiumLeadsBucket = async (lead_id: string) => {
		const response = await api.leads.deletePremiumLeadBucket(customerId || "", lead_id);
		if (response) {
			alert("Lead Successfully Deleted !!");
			window.location.reload();
		}
	};
	return { deletePremiumLeadsBucket };
};

export default useDeletePremiumLeadsBucket;
