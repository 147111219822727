
import CustomerContext from '../../../contexts/customerContext/customerContext';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { useContext } from 'react';
import useUpdateCustomerPhoto from '../../../utils/hooks/useUpdateCustomerPhoto/useUpdateCustomerPhoto';
import { CircularProgress } from '@mui/material';

const CustomerAvatar = () => {
	const { user } = useContext(CustomerContext);
	const { handleChangeCustomerPhoto, loading } = useUpdateCustomerPhoto();
	const stringAvatar = (name: string) => {
		const initials = name.split(' ').map(n => n[0]).join('');
		return initials;
	}

	return (
		<div className="flex flex-col items-center">
			{user?.photo ? (
				<div className='relative h-24 w-24 rounded-full mb-5 group'>
					<img
						src={user.photo}
						alt={`${user.first_name} ${user.last_name}`}
						className="h-24 w-24 object-cover rounded-full cursor-pointer mb-5 shadow-xl shadow-indigo-300"
					/>
					{
						loading && (
							<div className="absolute inset-0 flex justify-center items-center bg-black bg-opacity-50 rounded-full opacity-100">
								<CircularProgress />
							</div>
						)
					}
					<div className="absolute inset-0 flex justify-center items-center bg-black bg-opacity-50 rounded-full opacity-0 group-hover:opacity-100 transition-opacity">
						<CameraAltIcon className="text-white" />
					</div>
					<input type="file" onChange={handleChangeCustomerPhoto} className="absolute inset-0 opacity-0 cursor-pointer" />
				</div>
			) : (
				<div className="relative h-20 w-20 rounded-full bg-orange-400 flex flex-col justify-center items-center text-white text-2xl cursor-pointer mb-5 group">
					<p>
						{stringAvatar(`${user?.first_name} ${user?.last_name}` || "John Hardley")}
					</p>
					<div className="absolute inset-0 flex justify-center items-center bg-black bg-opacity-50 rounded-full opacity-0 group-hover:opacity-100 transition-opacity">
						<CameraAltIcon className="text-white" />
					</div>
					<input type="file" onChange={handleChangeCustomerPhoto} className="absolute inset-0 opacity-0 cursor-pointer" />
				</div>
			)}
		</div>
	)
}

export default CustomerAvatar
