import { useCallback, useEffect, useState } from "react";
import { ILeads } from "../../../@types/interface/leadDetails.interface";
import { formatFilters } from "../../commonFunctions/formatApiFilters";
import { IPagination } from "../../../@types/interface/pagination";
import { api } from "../../api";

const useGetFavLeads = (
  filters: any,
  pagination: IPagination,
  setPagination: React.Dispatch<React.SetStateAction<IPagination>>,
  userId: string
) => {
  const [rowData, setRowData] = useState<ILeads[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getLeadList = useCallback(async () => {
    try {
      const formattedFilter = formatFilters(filters);
      console.log("Formatted filters-->", formattedFilter);
      setLoading(true);
      if (!userId) {
        return;
      }

      const filter = {
        ...formattedFilter,
        page: pagination.currentPage,
        customer_object_id: userId,
      };
      const response = await api.leads.getFavLeads(filter);
      if (response) {
        setRowData(response.result);
        setPagination(response.pagination);
      }
    } catch (error) {
      console.error("Error while fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, [pagination.currentPage, filters, userId]);

  useEffect(() => {
    getLeadList();
  }, [getLeadList]);

  return { rowData, loading };
};

export default useGetFavLeads;
