import React, { useState } from "react";
import DataGrid from "../../../../shared/dataGrid/DataGrid";
import { TransactionColDefs } from "./transactionColDefs/TransactionColDefs";
import { ITransaction } from "../../../../../@types/interface/transaction.interface";

const TransactionDetails = ({ transactionDetails }: { transactionDetails: ITransaction[] }) => {
	return (
		<div style={{ marginTop: "30px" }}>
			<h3>Transaction History</h3>
			<DataGrid rowData={transactionDetails} colDefs={TransactionColDefs} />
		</div>
	);
};

export default TransactionDetails;
