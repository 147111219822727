import confetti from "canvas-confetti";
const useOpenConfetti = () => {
	const handleExplosion = () => {
		confetti({
			particleCount: 150,
			spread: 60
		});
	};
	return { handleExplosion };
};

export default useOpenConfetti;
