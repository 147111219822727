import { Box, Tab, Tabs } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import a11yProps from "../../../../../constants/tabPanelProps/ayProps";
import TabPanel from "../../../../shared/tabPanel/TabPanel";
import CustomerLeadsDetails from "../../customerLeadsDetails/CustomerLeadsDetails";
import MapView from "../mapView/MapView";
import { api } from "../../../../../utils/api";
import { ILeads } from "../../../../../@types/interface/leadDetails.interface";

const CustomerLeadsInfo = () => {
	const [value, setValue] = useState<number>(0);
	// const [isEditable, setIsEditable] = useState<boolean>(false);

	const [checked, setChecked] = useState<boolean>(false);
	const [isReadOnly, setIsReadOnly] = useState<boolean>(true);
	const queryParams = new URLSearchParams(window.location.search);
	const leadObjectId = queryParams.get("lid");
	const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);

	const [leadDetails, setLeadDetails] = useState<Partial<ILeads>>({
		lead_name: "",
		lead_email: "",
		lead_phone: "",
		address_line_1: "",
		city: "",
		lead_type: "",
		bidding_price: 0
	});

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	const getLeadById = useCallback(async () => {
		try {
			const filter = {
				leadObjectId: leadObjectId
			};
			const result = await api.leads.getLeadDetails(filter);
			console.log("lead details", result);
			setLeadDetails(result);
		} catch (error) {}
	}, [leadObjectId]);

	useEffect(() => {
		getLeadById();
	}, [getLeadById]);
	return (
		<div>
			<Box sx={{ width: "100%" }}>
				<Box>
					<Tabs
						value={value}
						aria-label="basic tabs example"
						sx={{ fontSize: "20px" }}
						TabIndicatorProps={{
							style: {
								backgroundColor: "#7c00fe"
							}
						}}
						onChange={handleChange}
						className="glb-tab-panel"
					>
						<Tab label="Lead Details" {...a11yProps(0)} className="tab-label" />
						<Tab label="Map View" {...a11yProps(1)} className="tab-label" />
					</Tabs>
				</Box>
				<TabPanel value={value} index={0}>
					<CustomerLeadsDetails leadDetails={leadDetails} />
				</TabPanel>
				<TabPanel value={value} index={1}>
					<MapView address={leadDetails.city || "Texas"} />
				</TabPanel>
			</Box>
		</div>
	);
};

export default CustomerLeadsInfo;
