import React, { ChangeEvent, useCallback, useContext, useEffect, useRef, useState } from "react";
import UIContext from "../../../../contexts/uiContext/UIContext";
import { AdvancedMarker, Map, MapCameraChangedEvent, Pin, useMap } from "@vis.gl/react-google-maps";
import { Marker, MarkerClusterer } from "@googlemaps/markerclusterer";
import "./customerDashboard.css";
import { api } from "../../../../utils/api";
import Tooltip from "@mui/material/Tooltip";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import CustomerContext from "../../../../contexts/customerContext/customerContext";
import { useNavigate } from "react-router-dom";
import { STATE_LIST } from "../../../../constants/stateList/StateList";
import usePurchasePremiumLeads from "../../../../utils/hooks/purchasePremiumLeads/usePurchasePremiumLeads";
import useQuerySearch from "../../../../utils/hooks/querySearch/useQuerySearch";
import useFavPremiumLeads from "../../../../utils/hooks/favPremiumLeads/useFavPremiumLeads";

import { toast } from "react-toastify";
import { Poi } from "../../../../@types/types/billing/mapLeads.type";
import PremiumLeadsDialog from "./premiumLeadsDialog/PremiumLeadsDialog";
import { IPremiumLeadsCount } from "../../../../@types/interface/premiumLeadsCount.interface";
import LeadsCount from "./leadsCount/LeadsCount";
import LeadsFilter from "./leadsFilter/LeadsFilter";
import dayjs from "dayjs";
import LeadsCard from "./leadsCard/LeadsCard";

const CustomerDashboard = ({ type }: { type: "ALL" | "LATEST" }) => {
	const map = useMap();
	const mapRef = useRef<google.maps.Map | null>(null);
	const markerClusterRef = useRef<MarkerClusterer | null>(null);
	const { setDashboardHeader } = useContext(UIContext);
	const { user } = useContext(CustomerContext);
	const [dashboardData, setDashboardData] = useState<any[]>([]);
	const [count, setCount] = useState<IPremiumLeadsCount>({ totalLeads: 0, todayLeads: 0 });
	const [selectedLead, setSelectedLead] = useState<Poi | null>(null);
	const [open, setOpen] = useState(false);
	const [visibleLeads, setVisibleLeads] = useState<Poi[]>([]);
	const [location, setLocation] = useState<{ lat: number; lng: number } | null>({ lat: 40, lng: -100 });
	const item = useQuerySearch("item");
	const { buyLeadHandler } = usePurchasePremiumLeads();

	const [zoom, setZoom] = useState<number>(4);
	const [markers, setMarkers] = useState<{ [key: string]: Marker }>({});
	const clusterer = useRef<MarkerClusterer | null>(null);

	const setMarkerRef = (marker: Marker | null, key: string) => {
		if (marker && markers[key]) return;
		if (!marker && !markers[key]) return;

		setMarkers((prev) => {
			if (marker) {
				return { ...prev, [key]: marker };
			} else {
				const newMarkers = { ...prev };
				delete newMarkers[key];
				return newMarkers;
			}
		});
	};

	const handleOpen = (lead: Poi) => {
		if (lead.customer_already_purchased) return;
		setSelectedLead(lead);
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
		setSelectedLead(null);
	};

	const [leadType, setLeadType] = useState("");

	const [dealType, setDealType] = useState("");

	const [leadState, setLeadState] = useState<string>("");

	const handleLeadTypeChange = (event: any) => {
		setLeadType(event.target.value);
	};

	const handleDealTypeChange = (event: any) => {
		setDealType(event.target.value);
	};

	const handleLeadStateChange = (event: any) => {
		setLeadState(event.target.value);
	};

	const removeFilter = (filterType: any) => {
		if (filterType === "leadType") {
			setLeadType("");
		} else if (filterType === "dealType") {
			setDealType("");
		} else if (filterType === "leadState") {
			setLeadState("");
		}
	};
	const { handleFavouriteLead } = useFavPremiumLeads();

	const handleClick = async (id: any) => {
		const is_fav = false;

		try {
			const response = await handleFavouriteLead(id, is_fav);

			toast.success("Lead successfully added to favourites!", {
				position: "top-center",
				autoClose: 3000, // Close after 3 seconds
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true
			});
		} catch (error) {
			toast.error("Error adding lead to favorites. Please try again.");
		}
	};
	const getDashboardData = useCallback(async () => {
		try {
			if (!user) return;
			const filter: any = {
				customer_object_id: user?._id,
				type
			};

			if (leadType) {
				filter.lead_type = leadType;
			}

			if (dealType) {
				filter.property_type = dealType;
			}

			if (leadState) {
				filter.state = leadState;
			}

			console.log("===>filter", filter);
			const res = await api.dashboard.getDashboardData(filter);
			setDashboardData(res?.result);
			setVisibleLeads(res?.result);
			setCount(res?.count);
		} catch (error) {
			console.log(error);
		}
	}, [leadType, dealType, leadState, user]);

	const handleBuyLead = async () => {
		if (selectedLead) {
			await buyLeadHandler(selectedLead?._id);
			setOpen(false);
		} else {
			alert("Select a lead");
		}
	};

	const handleDragStart = () => {
		setLocation(null);
	};

	const handleSetLocation = (lead: Poi) => {
		const coordinates = {
			lat: lead.coordinates.lat,
			lng: lead.coordinates.lng
		};
		setVisibleLeads([lead]);
		setLocation(coordinates);
		setZoom(10);
	};

	useEffect(() => {
		setDashboardHeader("Premium Leads");
	}, [setDashboardHeader]);

	useEffect(() => {
		getDashboardData();
	}, [getDashboardData]);

	useEffect(() => {
		if (mapRef.current && dashboardData.length > 0) {
			// Clear existing markers if any
			markerClusterRef.current?.clearMarkers();

			// Create markers for all POIs
			const markers = dashboardData.map((poi: Poi) => {
				return new google.maps.Marker({
					position: poi.coordinates,
					map: mapRef.current,
					icon: {
						url: "https://maps.google.com/mapfiles/kml/shapes/homegardenbusiness.png",
						scaledSize: new google.maps.Size(40, 40),
					},
					title: poi.city, // Optional: Adding a title to each marker
				});
			});

			// Initialize MarkerClusterer and add markers to it
			markerClusterRef.current = new MarkerClusterer({
				map: mapRef.current,
				markers: markers,
				renderer: {
					render: ({ count, position }) =>
						new google.maps.Marker({
							position,
							label: {
								text: String(count),
								color: "white",
								fontSize: "14px",
								fontWeight: "bold",
							},
							icon: {
								url: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m1.png",
								scaledSize: new google.maps.Size(50, 50),
							},
						}),
				},
			});
		}
	}, [dashboardData]); // Re-run the effect whenever dashboardData changes


	useEffect(() => {
		if (item) {
			const lead = dashboardData.find((lead: Poi) => lead._id === item);
			console.log("lead", lead);
			if (lead) {
				handleOpen(lead);
			}
		}
	}, [item, dashboardData]);

	useEffect(() => {
		if (!map || clusterer.current) return;
		clusterer.current = new MarkerClusterer({ map });
	}, [map]);

	useEffect(() => {
		clusterer.current?.clearMarkers();
		clusterer.current?.addMarkers(Object.values(markers));
	}, [markers]);

	return (
		<>
			<Box className="flex h-full flex-col lg:flex-row">
				<div className="sm:flex-1 w-full h-80 sm:h-auto bg-red-400">
					<Map
						defaultZoom={4}
						zoom={zoom}
						onZoomChanged={(ev) => setZoom(ev.detail.zoom)}
						defaultCenter={{ lat: 40, lng: -100 }}
						center={location}
						onDragstart={handleDragStart}
						onCameraChanged={(ev: MapCameraChangedEvent) => {
							const bounds = map?.getBounds();
							if (bounds) {
								console.log("Camera changed:", ev.detail.center, "Zoom:", ev.detail.zoom);
								const tempVisibleLeads = dashboardData.filter((poi: Poi) =>
									bounds.contains(new google.maps.LatLng(poi.coordinates.lat, poi.coordinates.lng))
								);
								setVisibleLeads(tempVisibleLeads);
							}
						}}
						mapId="MAPID"

					>
						{dashboardData.length > 0 &&
							dashboardData.map((poi: Poi) => (
								<AdvancedMarker
									key={poi._id}
									position={poi.coordinates}
									onClick={() => handleSetLocation(poi)}
									ref={(marker) => setMarkerRef(marker, poi._id)}
									style={{
										zIndex: 2
									}}
								>
									<Tooltip
										placement="top"
										arrow
										title={
											<Box
												sx={{
													position: "absolute",
													top: "-140px",
													left: "-80px",
													padding: "10px",
													height: "150px",
													width: "180px",
													backgroundColor: "white",
													borderRadius: "8px",
													boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
													color: "#333",
													textAlign: "left",
													display: "grid",
													alignItems: "center",
												}}
											>
												<Typography
													className="deal-type-container"
													variant="body2"
												>
													CITY : <b> {poi.city}</b>
												</Typography>
												<Typography variant="body2">
													LEAD : {poi.lead_type}
												</Typography>
												<Typography variant="body2">
													DEAL TYPE : {poi.deal_type}
												</Typography>
												<Typography variant="body2">
													ZIP : <b>{poi.zip}</b>
												</Typography>
											</Box>
										}
									>
										<div
											style={{
												fontSize: "30px",
												position: "absolute",
												pointerEvents: "auto",
											}}
										>
											🏡
										</div>
									</Tooltip>
								</AdvancedMarker>
							))}
					</Map>
				</div>
				<div className="flex-1 p-4">
					<LeadsCount count={count} />
					<LeadsFilter
						leadState={leadState}
						leadType={leadType}
						dealType={dealType}
						handleDealTypeChange={handleDealTypeChange}
						handleLeadStateChange={handleLeadStateChange}
						handleLeadTypeChange={handleLeadTypeChange}
					/>
					<h2 className="section-title">Leads Available For Purchase</h2>
					<Box className="leads-cards-wrapper h-full sm:max-h-[500px]">
						<div className="flex flex-wrap gap-2 mt-2 mb-3">
							{leadType && (
								<div className="flex items-center bg-gray-200 text-gray-800 text-sm font-medium py-1 px-3 rounded-full">
									{leadType}
									<span
										className="ml-2 cursor-pointer text-gray-600 hover:text-gray-800"
										onClick={() => removeFilter("leadType")}
									>
										×
									</span>
								</div>
							)}
							{dealType && (
								<div className="flex items-center bg-gray-200 text-gray-800 text-sm font-medium py-1 px-3 rounded-full">
									{dealType}
									<span
										className="ml-2 cursor-pointer text-gray-600 hover:text-gray-800"
										onClick={() => removeFilter("dealType")}
									>
										×
									</span>
								</div>
							)}
							{leadState && (
								<div className="flex items-center bg-gray-200 text-gray-800 text-sm font-medium py-1 px-3 rounded-full">
									{leadState}
									<span
										className="ml-2 cursor-pointer text-gray-600 hover:text-gray-800"
										onClick={() => removeFilter("leadState")}
									>
										×
									</span>
								</div>
							)}
						</div>
						{visibleLeads.length <= 0 && (
							<>
								<img
									src="https://cdn-icons-png.freepik.com/512/13543/13543330.png?ga=GA1.1.1598467923.1731688846"
									className="h-32 w-32 mx-auto"
								/>
								<div className="text-gray-800 font-semibold text-center">No Leads Found</div>
							</>
						)}
						<Box className="leads-cards grid-cols-1 lg:grid-cols-2 gap-5 grid">
							{visibleLeads.length > 0 &&
								visibleLeads.map((lead: Poi) => (
									<LeadsCard key={lead._id} lead={lead} handleClick={handleClick} handleOpen={handleOpen} handleSetLocation={handleSetLocation} />
								))}
						</Box>
					</Box>
				</div>
				{selectedLead && (
					<PremiumLeadsDialog
						open={open}
						handleClose={handleClose}
						selectedLead={selectedLead}
						handleBuyLead={handleBuyLead}
					/>
				)}
			</Box>
		</>
	);
};

export default CustomerDashboard;
