import { useState } from "react";

const useSelectLeads = () => {
	const [selectedLead, setSelectedLead] = useState<string | null>(null);
	const handleSetLeads = (leadId: string) => {
		setSelectedLead(leadId);
	};
	return { selectedLead, handleSetLeads };
};

export default useSelectLeads;
