import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import useQuerySearch from "../../../../utils/hooks/querySearch/useQuerySearch";

const SignUpFooterLink = () => {
	const type = useQuerySearch("type");
	const item = useQuerySearch("item");
	const getRoutes = useCallback(() => {
		if (type && item) {
			return "/sign-up?type=" + type + "&item=" + item;
		}
		return "/sign-up";
	}, [type, item]);
	return (
		<div className="">
			Not yet registered? &nbsp;
			<span className="link-container">
				<Link className="underline text-blue-500" to={getRoutes()}>
					Sign up
				</Link>
			</span>
		</div>
	);
};

export default SignUpFooterLink;
