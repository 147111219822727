import { url, port } from "../../config/config";

const downloadFile = (file: any) => {
	const a: HTMLAnchorElement = document.createElement("a");
	a.href = file.originalname
		? window.URL.createObjectURL(new Blob([`${url}:${port}/api/v1/show-file/${file.filename}`]))
		: URL.createObjectURL(file);
	a.download = file.originalname ? file.originalname : file.name;
	document.body.appendChild(a);
	a.click();
	document.body.removeChild(a);
};

export default downloadFile;
