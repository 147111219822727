const assets = {
  images: {
    logo: require("./images/company-logo.png"),
    company: require("./images/Scriptures.png"),
  },
};

export default assets;

export { default as leadBulkUploadTemplateFile } from "./bulkUpload/Lead_Bulk_Upload_Template.xlsx";
export { default as PremiumLeadsTemplate } from "./bulkUpload/Premium Leads Sheet.xlsx";
export { default as newleeyBrandLogo } from "../assets/brandLogo/BrandLogo";

// Client Brand Logo
export { default as newleeyBlueBrandLogo } from "./clientBrandLogo/newleey_blue_logo.png";

//Auth Images
export { default as loginPageImage } from "./auth/login_page_image.png";
export { default as forgetPasswordUsernameImage } from "./auth/forget_password_username_image.png";
export { default as password_reset } from "./auth/password_reset.png";
export { default as registrationPageImageA } from "./auth/registration_page_image_A.png";
export { default as registrationPageImageB } from "./auth/registration_page_image_B.png";
export { default as registrationSuccessImage } from "./auth/registration_success.png";

// export { default as forgetPasswordUsernameImage } from "./forget_password_username_image.png";
// export { default as password_reset } from "./password_reset.png";
// export { default as registrationPageImageA } from "./registration_page_image_A.png";
// export { default as registrationPageImageB } from "./registration_page_image_B.png";
// export { default as registrationSuccessImage } from "./registration_success.png";

// Notification
export { default as notificationSound } from "./notification/notification_sound.mp3";
export { default as search } from "./images/search_black_24dp.svg";
export { default as login } from "./images/Login-pana.svg";

// Pay Per Lead Portal (Non Subscription base member portal)

// Subscription based Member Portal

// https://newleey.com/
