import { Switch } from "@mui/material";
import styled from "styled-components";

export const CustomSwitch = styled(Switch)(({ theme }) => ({
	"& .MuiSwitch-switchBase.Mui-checked": {
		color: "#7c00fe", // Custom color for the switch thumb
		"&:hover": {
			backgroundColor: "rgba(76, 175, 80, 0.08)"
		}
	},
	"& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
		backgroundColor: "#b066ff" // Custom color for the switch track
	}
}));
