import React, { useState } from 'react'

const useShowpassword = () => {
	const [showPassword, setShowPassword] = useState<boolean>(false);

	const handleShowPassword = () => {
		setShowPassword(!showPassword);
	};

	return { showPassword, handleShowPassword }
}

export default useShowpassword