export const LEAD_TYPE = [
	{
		id: 1,
		label: "Remaining Leads",
		content: "0",
		field: "remaining_leads",
		className:
			"w-[150px]  md:w-[250px] mb-4 h-36 bg-sky-50 border-b-4 border-indigo-400 text-indigo-700 rounded-lg drop-shadow-lg flex flex-col gap-4 items-center justify-center hover:bg-indigo-300 transition-transform transform hover:scale-105 hover:shadow-lg"
	},
	{
		id: 2,
		label: "Exclusive Leads",
		content: "0",
		field: "totalPurchasedExclusiveLeads",
		className:
			"w-[150px]  md:w-[250px] mb-4 h-36 bg-sky-50 border-b-4 border-indigo-400 text-indigo-700 rounded-lg drop-shadow-lg flex flex-col gap-4 items-center justify-center hover:bg-indigo-300 transition-transform transform hover:scale-105 hover:shadow-lg"
	},
	{
		id: 3,
		label: "Premium Leads",
		content: "0",
		field: "totalPurchasedPremiumLeads",
		className:
			"w-[150px]  md:w-[250px] mb-4 h-36 bg-sky-50 border-b-4 border-indigo-400 text-indigo-700 rounded-lg drop-shadow-lg flex flex-col gap-4 items-center justify-center hover:bg-indigo-300 transition-transform transform hover:scale-105 hover:shadow-lg"
	},
	{
		id: 4,
		label: "Favorite Leads",
		content: "0",
		field: "totalFavoriteLeads",
		className:
			"w-[150px]  md:w-[250px] mb-4 h-36 bg-sky-50 border-b-4 border-indigo-400 text-indigo-700 rounded-lg drop-shadow-lg flex flex-col gap-4 items-center justify-center hover:bg-indigo-300 transition-transform transform hover:scale-105 hover:shadow-lg"
	}
];
