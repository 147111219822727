import { RouteType } from "./config";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import Person4OutlinedIcon from "@mui/icons-material/Person4Outlined";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import SignalCellularAltOutlinedIcon from "@mui/icons-material/SignalCellularAltOutlined";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import ConnectedTvOutlinedIcon from "@mui/icons-material/ConnectedTvOutlined";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import MapIcon from "@mui/icons-material/Map";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import AllCustomersPage from "../components/pages/allCustomers/AllCustomersPage";
import LocalGroceryStoreOutlinedIcon from "@mui/icons-material/LocalGroceryStoreOutlined";
import WorkspacePremiumOutlinedIcon from "@mui/icons-material/WorkspacePremiumOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import Builders from "../components/pages/leads/Leads";
import {
	Book,
	Construction,
	People,
	PermIdentity,
	ScatterPlotSharp,
	Settings,
	SettingsAccessibility,
	SettingsAccessibilityRounded
} from "@mui/icons-material";

import QuizIcon from "@mui/icons-material/Quiz";
import MainLayout from "../components/layout/MainLayout";
import Dashboard from "../components/pages/dashboard/Dashboard";

import SummarizeIcon from "@mui/icons-material/Summarize";
import Groups3Icon from "@mui/icons-material/Groups3";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import AssignmentIcon from "@mui/icons-material/Assignment";
import LeadForm from "../components/pages/leads/addLeadDetails/AddLeadDetails";

import EditLeads from "../components/pages/leads/editLeads/EditLeads";
import CustomerDetails from "../components/pages/allCustomers/customerDetails/CustomerDetails";
import LeadDetails from "../components/pages/leads/leadDetails/LeadDetails";
import Profile from "../components/pages/superAdminProfile/SuperAdminProfile";
import Revenue from "../components/pages/revenue/Revenue";
import AddLeads from "../components/pages/leads/addLeads/AddLeads";
import { userDynamicRoutes } from "../constants/userDynamicRoutes/UserDynamicRoutes";
import Leads from "../components/pages/leads/Leads";
import SuperAdminProfile from "../components/pages/superAdminProfile/SuperAdminProfile";
import ExclusiveLeads from "../components/pages/leads/premiumLeads/PremiumLeads";
import DashboardIndex from "../components/pages/leads/DashboardIndex";
import MarketplaceLeads from "../components/pages/leads/marketplaceLeads/MarketplaceLeads";
import GeneralLeads from "../components/pages/leads/generalLeads/GeneralLeads";
import AddExclusiveLeads from "../components/pages/leads/premiumLeads/addLeads/AddPreiumLeads";
import AddGeneralLeads from "../components/pages/leads/generalLeads/addGeneralLeads/AddGeneralLeads";
import Plans from "../components/pages/plans/Plans";
import AddPlans from "../components/pages/addPlans/AddPlans";
import ExclusiveLeadDetails from "../components/pages/leads/premiumLeads/premiumLeadCellRenderer/premiumLeadDetails/ExclusiveLeadDetails";
import AssignLeads from "../components/pages/assignLeads/AssignLeads";
import EditPlans from "../components/pages/editPlan/EditPlan";
import Influencers from "../components/pages/influencers/Influencers";
import AddInfluencer from "../components/pages/influencers/addInfluencers/AddInfluencer";
import PremiumLeads from "../components/pages/leads/premiumLeads/PremiumLeads";
import Announcement from "../components/pages/announcement/Announcement";
import AddAnnouncement from "../components/pages/announcement/addAnnouncement/AddAnnouncement";
import CampaignIcon from '@mui/icons-material/Campaign';

const queryParams = new URLSearchParams(window.location.search);
const leadId = queryParams.get("lid");

const superAdminRoutes: RouteType[] = [
	{
		path: "/super-admin/dashboard",
		element: <Dashboard />,
		state: "dashboard",
		sidebarProps: {
			displayText: "Dashboard",
			icon: <DashboardOutlinedIcon />
		}
	},
	{
		path: "/super-admin/leads",
		element: <Leads />,
		state: "leads",
		sidebarProps: {
			displayText: "Leads",
			icon: <GroupsOutlinedIcon />
		},
		child: [
			{
				index: true,
				element: <DashboardIndex />,
				state: "leads.index"
			},
			{
				path: "/super-admin/leads/exclusive-leads",
				element: <MarketplaceLeads />,
				state: "exclusiveLeads",
				sidebarProps: {
					displayText: "Exclusive Leads",
					icon: <LocalGroceryStoreOutlinedIcon />
				}
			},

			{
				path: "/super-admin/leads/premium-leads",
				element: <PremiumLeads />,
				state: "premiumLeads",
				sidebarProps: {
					displayText: "Premium Leads",
					icon: <WorkspacePremiumOutlinedIcon />
				}
			},
			// {
			// 	path: "/super-admin/leads/general-leads",
			// 	element: <GeneralLeads />,
			// 	state: "generalLeads",
			// 	sidebarProps: {
			// 		displayText: "General Leads",
			// 		icon: <PersonOutlinedIcon />
			// 	}
			// }
		]
	},

	{
		path: "/super-admin/add-marketplace-leads",
		element: <AddLeads />,
		state: "marketplaceLeads"
	},
	{
		path: "/super-admin/add-plans",
		element: <AddPlans />,
		state: "addplans"
	},
	{
		path: "/super-admin/edit-plan",
		element: <EditPlans />,
		state: "editplans"
	},
	{
		path: "/super-admin/add-premium-leads",
		element: <AddExclusiveLeads />,
		state: "exclusiveLeads"
	},
	{
		path: "/super-admin/add-general-leads",
		element: <AddGeneralLeads />,
		state: "generalLeads"
	},
	{
		// path: `${userDynamicRoutes.super_admin}/leads/lead-details`,
		path: "/super-admin/marketplace-lead-details",
		element: <LeadDetails />,
		state: "leads"
	},
	{
		// path: `${userDynamicRoutes.super_admin}/leads/lead-details`,
		path: "/super-admin/premium-lead-details",
		element: <ExclusiveLeadDetails />,
		state: "exclusiveLeads"
	},
	{
		path: "/super-admin/profile",
		element: <SuperAdminProfile />,
		state: "superAdminProfile"
	},
	{
		path: "/super-admin/all-customers",
		element: <AllCustomersPage />,
		state: "allcustomers",
		sidebarProps: {
			displayText: "Customers",
			icon: <Person4OutlinedIcon />
		}
	},
	{
		path: "/super-admin/influencers",
		element: <Influencers />,
		state: "influencers",
		sidebarProps: {
			displayText: "Influencers",
			icon: <Groups3Icon />
		}
	},
	{
		path: "/super-admin/announcements",
		element: <Announcement />,
		state: "announcement",
		sidebarProps: {
			displayText: "Announcements",
			icon: <CampaignIcon />
		}
	},
	{
		path: "/super-admin/add-announcement",
		element: <AddAnnouncement />,
		state: "announcement"
	},
	{
		path: "/super-admin/add-influencers",
		element: <AddInfluencer />,
		state: "addInfluencers"
	},
	{
		path: "/super-admin/customer-details",
		element: <CustomerDetails />,
		state: "customers"
	},
	{
		path: "/super-admin/assign-premium-leads",
		element: <AssignLeads lead_type="PREMIUM" key="exclusive_leads_assign" />,
		state: "assignPremiumLeads"
	},
	{
		path: "/super-admin/assign-exclusive-leads",
		element: <AssignLeads lead_type="EXCLUSIVE" key="premium_leads_assign" />,
		state: "assignExclusiveLeads"
	},
	{
		path: "/super-admin/revenue-earned",
		element: <Revenue />,
		state: "plots",
		sidebarProps: {
			displayText: "Revenue Earned",
			icon: <AttachMoneyOutlinedIcon />
		}
	},

	{
		path: "/super-admin/plans",
		element: <Plans />,
		state: "plans",
		sidebarProps: {
			displayText: "plans",
			icon: <DashboardOutlinedIcon />
		}
	},
	{
		path: "/super-admin/request-and-approval",
		// element: <PlotsPage />,
		state: "request",
		sidebarProps: {
			displayText: "Request & Approval",
			icon: <PublishedWithChangesIcon />
		}
	},

	{
		path: "/super-admin/reports",
		// element: <PlotsPage />,
		state: "reports",
		sidebarProps: {
			displayText: "Reports",
			icon: <AssignmentIcon />
		}
	}
];

export default superAdminRoutes;
