import useCancelSubscription from "../../../../../utils/hooks/cancelSubscription/useCancelSubscription"

const ConfirmCancel = ({ handleToogleConfirmCancelModal }: { handleToogleConfirmCancelModal: () => void }) => {
	const { handleCancelSubscription, loading } = useCancelSubscription();

	const handleCancelButtonClick = async () => {
		await handleCancelSubscription();
		handleToogleConfirmCancelModal();
	}
	return (
		<div className="w-full bg-white p-4 rounded-lg">
			<h2 className="text-2xl font-semibold mb-3 bg-white">
				Are your <span className="text-blue-700">Sure?</span>
			</h2>
			<div className="flex gap-2 items-center ">
				<p className="mb-4 py-2 text-gray-600 rounded text-base">
					Please don't cancel your subscription, we will truly miss you! If you cancel now, you will not receive any new leads next month. Stay with us and continue enjoying the amazing benefits of your subscription. Your journey with us means a lot!
				</p>
			</div>

			<div className="button-flex mt-2">
				<button
					onClick={handleCancelButtonClick}
					className="text-white bg-gradient-to-r from-red-500 via-red-600 to-red-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
				>
					{
						loading ? "Cancelling..." : "Cancel Subscription"
					}
				</button>
				<button
					onClick={handleToogleConfirmCancelModal}
					className="text-black bg-gray-200 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
				>
					keep Subscription
				</button>
			</div>
		</div >
	)
}

export default ConfirmCancel