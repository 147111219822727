import { useNavigate } from "react-router-dom";
import { api } from "../../api";

const useAssignPremiumLead = () => {
	const navigate = useNavigate();
	const handleAsssignPremiumLead = async (customer_object_id: string, lead_object_list: string[]) => {
		const payload = {
			customer_object_id,
			lead_object_list
		};
		const response = await api.customers.createMultiplePremiumLeadBucket(payload);
		if (response) {
			navigate(-1);
			alert("Leads assigned successfully");
		}
		return response;
	};
	return { handleAsssignPremiumLead };
};

export default useAssignPremiumLead;
