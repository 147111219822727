import React, { useCallback, useContext, useEffect, useState } from "react";
import { FilterModel } from "ag-grid-community";
import BasicPagination from "../../../../shared/basicPagination/BasicPagination";
import { IPagination } from "../../../../../@types/interface/pagination";
import UIContext from "../../../../../contexts/uiContext/UIContext";
import { CustomerLeadsColDefs } from "../../customerLeads/customerLeadsColDefs/customerLeadsColDefs";
import DataGrid from "../../../../shared/dataGrid/DataGrid";
import CustomerContext from "../../../../../contexts/customerContext/customerContext";
import DownloadButton from "../../../../shared/downloadButton/DownloadButton";
import useGetPurchasedExclusiveLeads from "../../../../../utils/hooks/getPurchasedExclusiveLeads/useGetPurchasedExclusiveLeads";
import { api } from "../../../../../utils/api";

const PurchasedExclusiveLeads = () => {
	const { setDashboardHeader } = useContext(UIContext);
	const { user } = useContext(CustomerContext);
	const [filters, setFilters] = useState([]);
	const [pagination, setPagination] = useState<IPagination>({
		currentPage: 1,
		pageCount: 1
	});

	const { rowData } = useGetPurchasedExclusiveLeads(filters, pagination, setPagination, user?._id || "");

	const handleFilterChange = (filterModel: FilterModel) => {
		setFilters((prevFilters: any) => {
			const sanitizedFilters = { ...prevFilters };
			Object.keys(sanitizedFilters).forEach((key: any) => {
				if (!filterModel[key]) {
					delete sanitizedFilters[key];
				}
			});
			const updatedFilters = { ...sanitizedFilters, ...filterModel };
			console.log("Updated Filters-->", updatedFilters);
			return updatedFilters;
		});
	};

	const handlePageChange = useCallback((event: React.ChangeEvent<unknown>, value: number) => {
		setPagination((prevPagination: IPagination) => ({
			...prevPagination,
			currentPage: value
		}));
	}, []);

	const downloadData = async () => {
		const response = await api.leads.getCustomerLeads({ customer_object_id: user?._id });
		return response;
	};

	useEffect(() => {
		setDashboardHeader("Purchased Exclusive Leads");
	}, [setDashboardHeader]);
	return (
		<div>
			<div className="add-btn">
				<DownloadButton apiCall={downloadData} fileName="Exclusive Leads" key={"customerMarketPlaceLeads"} />
			</div>
			<div className="leads-data-container-grid">
				<DataGrid rowData={rowData} colDefs={CustomerLeadsColDefs} onFilterChange={handleFilterChange} />
				<BasicPagination
					currentPage={pagination.currentPage}
					pageCount={pagination.pageCount}
					handlePageChange={handlePageChange}
				/>
			</div>
			{/* leads details grid starts here */}
		</div>
	);
};

export default PurchasedExclusiveLeads;
