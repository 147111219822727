import { useState, useEffect } from "react";

const TimerCountDown = () => {
	const [timeLeft, setTimeLeft] = useState(15 * 60);

	useEffect(() => {
		if (timeLeft > 0) {
			const timer = setInterval(() => {
				setTimeLeft((prevTime) => prevTime - 1);
			}, 1000);

			return () => clearInterval(timer);
		}
	}, [timeLeft]);

	const formatTime = (time: number) => {
		const minutes = Math.floor(time / 60);
		const seconds = time % 60;
		return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
	};

	return (

		<span className="text-base font-semibold text-red-600"> {formatTime(timeLeft)} minutes</span>

	);
};

export default TimerCountDown;
