import { RouteType } from "./config";

import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";

import CustomerDashboard from "../components/pages/customerPortal/dashBoard/CustomerDashboard";
import CustomerBilling from "../components/pages/customerPortal/customerBilling/CustomerBilling";

import Marketplace from "../components/pages/customerPortal/marketplace/Marketplace";

import CustomerLeadsInfo from "../components/pages/customerPortal/customerLeads/customerLeadsData/CustomerLeadsData";
import PurchasedExclusiveLeads from "../components/pages/customerPortal/purchasedLeads/purchasedPremiumLeads/PurchasedPremiumLeads";
import PurchasedLeads from "../components/pages/customerPortal/purchasedLeads/purchasedLeads";
import DashboardIndex from "../components/pages/customerPortal/purchasedLeads/DashboardIndex";
import PurchasedMarketplaceLeads from "../components/pages/customerPortal/purchasedLeads/purchasedExclusiveLeads/PurchasedExclusiveLeads";
import PurchasedGeneralLeads from "../components/pages/customerPortal/purchasedLeads/purchasedGeneralLeads/PurchasedGeneralLeads";
import Pricing from "../components/pages/pricing/Pricing";
import BillingSuccess from "../components/pages/billingSuccess/BillingSuccess";
import PlanSuccess from "../components/pages/planPurchaseSuccess/PlanPurchaseSuccess";
import WalletIcon from "@mui/icons-material/Wallet";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import StarIcon from "@mui/icons-material/Star";
import FavPrimiumLeads from "../components/pages/favPrimiumLeads/FavPrimiumLeads";
import RecentPremiumLeads from "../components/pages/recentPrimiumLeads/RecentPrimiumLeads";
import RecentMarketplace from "../components/pages/customerPortal/recentmarketplace/RecentMarketplace";
import LatestLeads from "../components/pages/customerPortal/latestLeads/LatestLeads";
import SuperAdminProfile from "../components/pages/superAdminProfile/SuperAdminProfile";
import CustomerProfile from "../components/pages/customerPortal/customerProfile/CustomerProfile";
import ExclusiveLeadsCart from "../components/pages/customerPortal/exclusiveLeadsCart/ExclusiveLeadsCart";

const customerRoutes: RouteType[] = [
	// {
	// 	index: true,
	// 	element: <Dashboard />,
	// 	state: "home"
	// },
	{
		path: "/customer/premium-leads",
		element: <CustomerDashboard type="ALL" />,
		state: "premiumLeads",
		sidebarProps: {
			displayText: "Premium Marketplace",
			icon: <DashboardOutlinedIcon fontWeight={100} />
		}
	},
	{
		path: "/customer/exclusive-leads",
		element: <Marketplace />,
		state: "marketplace",
		sidebarProps: {
			displayText: "Exclusive Marketplace",
			icon: <AutoFixHighIcon />
		}
	},
	{
		path: "/customer/favorite-leads",
		element: <FavPrimiumLeads />,
		state: "Favorite",
		sidebarProps: {
			displayText: "Favorite Leads",
			icon: <StarIcon />
		}
	},
	{
		path: "/customer/latest-leads",
		element: <LatestLeads />,
		state: "latest-leads",
		sidebarProps: {
			displayText: "Latest Leads",
			icon: <AutoFixHighIcon />
		}
	},
	// {
	// 	path: "/customer/recent-exclusive-leads",
	// 	element: <RecentMarketplace />,
	// 	state: "recent-marketplace",
	// 	sidebarProps: {
	// 		displayText: "Recent Exclusive Leads",
	// 		icon: <AutoFixHighIcon />
	// 	}
	// },
	// {
	// 	path: "/customer/recent-premium-leads",
	// 	element: <RecentPremiumLeads />,
	// 	state: "Recent-premium",
	// 	sidebarProps: {
	// 		displayText: "Recent Premium Leads",
	// 		icon: <StarIcon />
	// 	}
	// },
	{
		path: "/customer/purchased-leads",
		element: <PurchasedLeads />,
		state: "purchasedLeads",
		sidebarProps: {
			displayText: "Purchased",
			icon: <AttachMoneyOutlinedIcon />
		},
		child: [
			{
				index: true,
				element: <DashboardIndex />,
				state: "purchasedLeads.index"
			},
			{
				path: "/customer/purchased-leads/exclusive-leads",
				element: <PurchasedMarketplaceLeads />,
				state: "purchasedExclusiveLeads",
				sidebarProps: {
					displayText: "Exclusive Leads",
					icon: <GroupsOutlinedIcon />
				}
			},
			{
				path: "/customer/purchased-leads/premium-leads",
				element: <PurchasedExclusiveLeads />,
				state: "purchasedPremiumLeads",
				sidebarProps: {
					displayText: "Premium Leads",
					icon: <GroupsOutlinedIcon />
				}
			},
			// {
			// 	path: "/customer/purchased-leads/general-leads",
			// 	element: <PurchasedGeneralLeads />,
			// 	state: "purchasedGeneralLeads",
			// 	sidebarProps: {
			// 		displayText: "General Leads",
			// 		icon: <GroupsOutlinedIcon />
			// 	}
			// }
		]
	},
	{
		path: "/customer/customer-leads",
		element: <CustomerLeadsInfo />,
		state: "leads"
	},
	{
		path: "/customer/exclusive-leads-cart",
		element: <ExclusiveLeadsCart />,
		state: "marketplace"
	},

	{
		path: "/customer/profile",
		element: <CustomerProfile />,
		state: "customerProfile"
	},
	{
		path: "/customer/subscription",
		element: <Pricing />,
		state: "subscription"
	},

	{
		path: "/customer/billing",
		element: <CustomerBilling />,
		state: "customerBilling",
		sidebarProps: {
			displayText: "Billing",
			icon: <WalletIcon />
		}
	},
	{
		path: "/customer/billing/success",
		element: <BillingSuccess />,
		state: "customerBillingsuccess"
	},
	{
		path: "/customer/subscription/success",
		element: <PlanSuccess />,
		state: "PlanSuccess"
	}
];

export default customerRoutes;
