import { AppBar, IconButton, Menu, Toolbar, Typography } from "@mui/material";
import { useContext, useState } from "react";
import UIContext from "../../../contexts/uiContext/UIContext";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import React from "react";
import AuthContext from "../../../contexts/customerContext/customerContext";
import { ROLES } from "../../../constants/roles/Roles";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import sizeConfigs from "../../../config/sizeConfigs";
import Chip from "@mui/material/Chip";
import DropdownMenu from "../../shared/dropdownMenu/DropdownMenu";
import { useTheme } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import CurrentPlan from "../../shared/planDetails/CurrentPlan";
import colorConfigs from "../../../config/colorConfigs";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import useGetCustomerCartLeads from "../../../utils/hooks/getCustomerCartLeads/getCustomerCartLeads";
import { IPagination } from "../../../@types/interface/pagination";
import CustomerContext from "../../../contexts/customerContext/customerContext";

interface TopbarProps {
	sidebarOpen: boolean;
	toggleSidebar: () => void; // Add a toggleSidebar prop of type function
}

const Topbar: React.FC<TopbarProps> = ({ sidebarOpen, toggleSidebar }) => {
	const { user } = useContext(CustomerContext);
	const navigate = useNavigate();
	const { dashboardHeader } = useContext(UIContext);

	const location = useLocation();
	const theme = useTheme();

	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const isOnSuperAdminPage = location.pathname.startsWith("/super-admin");
	const isOnAdminPage = location.pathname.startsWith("/admin");
	const isOnCustomerPage = location.pathname.startsWith("/customer");
	const [currentPlan, setCurrentPlan] = useState();
	const handleRouteToSuperAdminProfile = () => {
		navigate("/super-admin/profile");
	};

	const handlefetchCartLeads = () => {
		navigate("/customer/exclusive-leads-cart");
	};
	const handleRouteToAdminProfile = () => {
		console.log("Hello");
	};

	const handleRouteToCustomerProfile = () => {
		navigate("/customer/profile");
	};
	return (
		<AppBar
			position="fixed"
			elevation={3}
			sx={{
				width: sidebarOpen
					? `calc(100% - ${sizeConfigs.sidebar.width})`
					: "100%",
				ml: sidebarOpen ? sizeConfigs.sidebar.width : 0,
				backgroundColor: "#FFFFFF",
				color: colorConfigs.topbar.color,
			}}
		>
			<Toolbar
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						// gap: "10px",
						// padding: "10px"
					}}
				>
					<IconButton onClick={toggleSidebar}>
						{!sidebarOpen && <MenuOutlinedIcon />}
					</IconButton>
					<p
						className="text-md md:text-2xl font-bold"
						style={{ color: colorConfigs.topbar.color }}
					>
						{dashboardHeader}
					</p>
				</div>

				<div
					className="button-group"
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						gap: "15px",
					}}
				>
					{/* {!isMobile && (
						<Chip
							label={
								isOnSuperAdminPage ? (
									"Super Admin Portal"
								) : isOnAdminPage ? (
									"Admin Portal"
								) : isOnCustomerPage ? (
									// <CurrentPlan currentPlan={currentPlan} setCurrentPlan={setCurrentPlan} />
									<h1>hello</h1>
								) : null
							}
							style={{
								color: isOnSuperAdminPage ? "#7C00FE" : isOnAdminPage ? "#109bbd" : "white",
								fontFamily: "Arial, sans-serif",
								fontWeight: "bold",
								fontSize: "16px",
								backgroundColor: isOnSuperAdminPage ? "#f2e7fa" : isOnAdminPage ? "#d7ffe4" : "white"
							}}
						/>
					)} */}
					{isOnCustomerPage ? (
						<CurrentPlan
							currentPlan={currentPlan}
							setCurrentPlan={setCurrentPlan}
						/>
					) : null}

					{isOnSuperAdminPage ? (
						<DropdownMenu onClick={handleRouteToSuperAdminProfile} />
					) : isOnAdminPage ? (
						<DropdownMenu onClick={handleRouteToAdminProfile} />
					) : isOnCustomerPage ? (
						<div className="flex items-center justify-center gap-4">
							<div style={{ position: "relative" }}>
								<ShoppingCartIcon
									onClick={handlefetchCartLeads}
									style={{ fontSize: "28px" }} // Increased the size of the icon
								/>
								{(user?.no_of_items_in_cart ?? 0) > 0 && (
									<div
										style={{
											position: "absolute",
											top: -6,
											right: -6,
											backgroundColor: "red",
											color: "white",
											borderRadius: "50%",
											width: "18px",
											height: "18px",
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											fontSize: "12px", // Adjusted font size for better proportion
											fontWeight: "bold",
										}}
									>
										{user?.no_of_items_in_cart || 0}
									</div>
								)}
							</div>
							<DropdownMenu onClick={handleRouteToCustomerProfile} />
						</div>
					) : null}
				</div>
			</Toolbar>
		</AppBar>
	);
};

export default Topbar;
