import { Payload } from "../../../@types/api/api.types";
import { headers } from "../../../config/config";
import { MESSAGE } from "../../../constants/api/message";
import { request } from "../api";

const initialRoute = "auth";

const { patch } = request;
export const changePassword = async (payload: Payload) => {
  try {
    const endpoint = `${initialRoute}/change-password`;
    const response = await patch(endpoint, payload, {
      ...headers,
    });
    if (response) {
      const {
        data: { message },
      } = response;
      if (message === MESSAGE.post.succAuth) {
        const {
          data: { result, token },
        } = response;
        return { result, token };
      }
    }
    throw new Error();
  } catch (error: unknown) {
    console.log(error);
    throw error;
  }
};
