const Chips = ({ text, isRecommended, handleClick }: { text: string, isRecommended: boolean, handleClick: () => void }) => {
	return (
		<span onClick={handleClick} id="badge-dismiss-dark" className={`cursor-pointer relative inline-flex items-center px-3 py-1 me-2 text-xs font-medium ${isRecommended ? 'text-indigo-700 bg-indigo-50 border border-indigo-400' : 'text-black bg-gray-200 border border-gray-400'} rounded-lg`} title={isRecommended ? "Recommended" : ""}>
			{
				isRecommended ?
					<div className='absolute bottom-4 left-9 inline-flex'>
						<abbr title="Recommended">
							<svg className=" w-4 h-4 mr-1 text-indigo-700" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
								<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.286 3.97a1 1 0 00.95.69h4.18c.969 0 1.371 1.24.588 1.81l-3.388 2.46a1 1 0 00-.364 1.118l1.287 3.97c.3.921-.755 1.688-1.54 1.118l-3.388-2.46a1 1 0 00-1.175 0l-3.388 2.46c-.784.57-1.838-.197-1.54-1.118l1.287-3.97a1 1 0 00-.364-1.118L2.245 9.397c-.783-.57-.38-1.81.588-1.81h4.18a1 1 0 00.95-.69l1.286-3.97z" />
							</svg>
						</abbr>
					</div> : null
			}
			{text}
		</span>
	)
}

export default Chips