import { useContext } from "react";
import CustomerContext from "../../../../../contexts/customerContext/customerContext";
import { OrderSummaryProps } from "../../../../../@types/props/orderSummary.props";

const OrderSummary: React.FC<OrderSummaryProps> = ({
	totalAmount,
	tobePaid,
	couponPrice,
	handleCheckoutButtonClick,
}) => {
	const { user } = useContext(CustomerContext);
	return (
		<div className="mx-auto mt-6 max-w-4xl flex-1 space-y-6 lg:mt-0 lg:w-full">
			<div className="space-y-4 rounded-lg border border-gray-200 bg-white p-4 shadow-sm sm:p-6">
				<p className="text-xl font-semibold text-gray-900 ">Order summary</p>

				<div className="space-y-4">
					<div className="space-y-2">
						<dl className="flex items-center justify-between gap-4">
							<dt className="text-base font-normal text-gray-500 ">
								Original price
							</dt>
							<dd className="text-base font-medium text-gray-900 ">
								${totalAmount}
							</dd>
						</dl>

						<dl className="flex items-center justify-between gap-4">
							<dt className="text-base font-normal text-gray-500 ">Wallet</dt>
							<dd className="text-base font-medium text-green-600">
								-${user?.wallet_balance}
							</dd>
						</dl>

						<dl className="flex items-center justify-between gap-4">
							<dt className="text-base font-normal text-gray-500 ">
								Applied Coupon
							</dt>
							<dd className="text-base font-medium text-green-600 ">-${couponPrice || 0}</dd>
						</dl>
					</div>

					<dl className="flex items-center justify-between gap-4 border-t border-gray-200 pt-2 dark:border-gray-700">
						<dt className="text-base font-bold text-gray-900 ">Total</dt>
						<dd className="text-base font-bold text-gray-900 ">${tobePaid}</dd>
					</dl>
				</div>

				<button onClick={handleCheckoutButtonClick} className="flex w-full items-center justify-center rounded-lg bg-blue-700 px-5 py-2.5 text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-primary-300">
					Proceed to Checkout
				</button>

				<div className="flex items-center justify-center gap-2">
					<span className="text-sm font-normal text-gray-500 "> or </span>
					<a
						href="#"
						title=""
						className="inline-flex items-center gap-2 text-sm font-medium text-primary-700 underline hover:no-underline dark:text-primary-500"
					>
						Continue Shopping
						<svg
							className="h-5 w-5"
							aria-hidden="true"
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
						>
							<path
								stroke="currentColor"
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="2"
								d="M19 12H5m14 0-4 4m4-4-4-4"
							/>
						</svg>
					</a>
				</div>
			</div>
		</div>
	);
};

export default OrderSummary;
