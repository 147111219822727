import { googleMapsApiKey } from "../../../config/googleMaps.config";

const GoogleMap = ({ address, height }: { address: string; height: number }) => {
	const mapStyles = {
		height: `${height}px`,
		width: "100%",
		borderRadius: "10px"
	};

	return (
		<div>
			<iframe
				width="600"
				height="450"
				style={mapStyles}
				loading="lazy"
				allowFullScreen
				referrerPolicy="no-referrer-when-downgrade"
				src={`https://www.google.com/maps/embed/v1/place?key=${googleMapsApiKey}&q=${address}`}
			></iframe>
		</div>
	);
};

export default GoogleMap;
