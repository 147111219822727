import { useCallback, useEffect, useState } from "react";
import { ILeads } from "../../../@types/interface/leadDetails.interface";
import { api } from "../../api";
import { IUseGetLeadsProps } from "../../../@types/props/useGetLeads.props";

const useGetExclusiveLeadsAdmin = ({ pagination, setPagination, accept_full_data, filter }: IUseGetLeadsProps) => {
	const [leads, setLeads] = useState<ILeads[]>([]);
	if (!pagination) {
		pagination = {
			currentPage: 0,
			pageCount: 0
		};
	}
	const getLeadList = useCallback(async () => {
		try {
			const filterToSearch = {
				...filter,
				page: pagination ? pagination.currentPage : 0
			};
			if (!accept_full_data && Object.keys(filter).length === 0) return;
			const response = await api.leads.getLeads(filterToSearch);
			if (response) {
				setLeads(response.result);
				if (setPagination) {
					setPagination(response.pagination);
				}
			}
		} catch (error) {
			console.error("Error while fetching data:", error);
		}
	}, [pagination.currentPage, setPagination, filter]);

	useEffect(() => {
		getLeadList();
	}, [getLeadList]);

	return leads;
};

export default useGetExclusiveLeadsAdmin;
