import { CustomerAction } from "../../@types/contexts/customerContext/customerAction.types";
import { Store } from "../../@types/contexts/customerContext/store.types";
import actions from "./actions";

const reducer = (state: Store, action: CustomerAction) => {
  switch (action.type) {
    case actions.SET_CUSTOMER: {
      return {
        ...state,
        isLoggedIn: true,
        user: action.payload.user,
      };
    }
    default:
      throw new Error("Unexpected action: Auth Context");
  }
};

export default reducer;
