import React, { useContext, useEffect, useState } from "react";
import { api } from "../../../utils/api";
import useUpdateWallet from "../../../utils/hooks/updateWallet/useUpdateWallet";
import useOpenConfetti from "../../../utils/hooks/openConfetti/useOpenConfetti";
import { IWallet } from "../../../@types/interface/wallet.interface";
import usePurchaseExclusiveLeads from "../../../utils/hooks/purchaseExclusiveLeads/usePurchaseExclusiveLeads";
import useQuerySearch from "../../../utils/hooks/querySearch/useQuerySearch";
import CustomerContext from "../../../contexts/customerContext/customerContext";
import useGetPaymentMethod from "../../../utils/hooks/useGetPaymentMethod/useGetPaymentMethod";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { WalletModalProps } from "../../../@types/props/walletModal.props";
import { AddLeadsModalProps } from "../../../@types/props/addLeadsModal.props";


const AddLeadsModal: React.FC<AddLeadsModalProps> = ({
    onClose,
    no_of_leads
}) => {
    const { user, setUser } = useContext(CustomerContext);
    const [billingName, setBillingName] = useState(user?.first_name || "" + user?.last_name || "");
    const [billingAddress, setBillingAddress] = useState("");
    const [discountCoupon, setDiscountCoupon] = useState("");
    const [isProcessing, setIsProcessing] = useState(false);
    const [hasChangeCard, setHasChangeCard] = useState<boolean>(false);
    const { updateWallet } = useUpdateWallet();
    const { handleExplosion } = useOpenConfetti();
    const item = useQuerySearch('item');
    const { handleConfirm } = usePurchaseExclusiveLeads();
    const { paymentMethod } = useGetPaymentMethod();
    const stripe = useStripe();
    const elements = useElements();

    const handleChangeCard = () => {
        setHasChangeCard(!hasChangeCard);
    }
    const handlePayment = async () => {
        setIsProcessing(true);
        try {
            let response = null;
            if (!paymentMethod || hasChangeCard) {
                if (!stripe || !elements) {
                    console.error("Stripe.js has not yet loaded");
                    return;
                }
                const cardElement = elements.getElement(CardElement);
                if (!cardElement) {
                    console.error("Card element not found");
                    return;
                }
                const { error, paymentMethod } = await stripe.createPaymentMethod({
                    type: "card",
                    card: cardElement
                });
                response = await api.payment.createPayment({
                    customer_object_id: user?._id,
                    amount: no_of_leads * 10, // Parse the amount to a float
                    payment_method_id: paymentMethod?.id,
                    description: "Wallet deposit",
                });
            }
            else {
                response = await api.payment.createPayment({
                    customer_object_id: user?._id,
                    amount: no_of_leads * 10, // Parse the amount to a float
                    payment_method_id: paymentMethod?.id,
                    description: "Wallet deposit",
                });
            }

            if (response && user) {
                const payload = {
                    customerDetails: {
                        premium_leads_limit: user?.premium_leads_limit + no_of_leads,
                    },
                    customerId: user?._id
                }
                const latestUser = await api.leads.updatePremiumLeadsLimit(payload)

                if (latestUser) {
                    setUser(latestUser);
                    handleExplosion();
                }
                onClose();
            }
        } catch (error) {
            console.error("Error processing payment:", error);
            alert("Failed to process payment. Please try again.");
        }
        setIsProcessing(false);
    };

    return (
        <div className="bg-white rounded-lg shadow-lg w-full max-w-md p-6">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-bold text-blue-600">Purchase {no_of_leads} Leads</h2>
                <button
                    className="text-gray-400 hover:text-gray-600"
                    onClick={onClose}
                >
                    ✕
                </button>
            </div>
            <form>
                <div className="mb-4">
                    <label className="block text-sm font-medium mb-2">
                        Billing Name
                    </label>
                    <input
                        type="text"
                        className="w-full border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring focus:ring-blue-200"
                        value={billingName}
                        onChange={(e) => setBillingName(e.target.value)}
                        placeholder="Enter your name"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium mb-2">
                        Billing Address
                    </label>
                    <input
                        type="text"
                        className="w-full border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring focus:ring-blue-200"
                        value={billingAddress}
                        onChange={(e) => setBillingAddress(e.target.value)}
                        placeholder="Enter your address"
                    />
                </div>
                {/* <div className="mb-4">
                    <label className="block text-sm font-medium mb-2">
                        Discount Coupon
                    </label>
                    <div className="flex items-center space-x-2">
                        <input
                            type="text"
                            className="w-full border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring focus:ring-blue-200"
                            value={discountCoupon}
                            onChange={(e) => setDiscountCoupon(e.target.value)}
                            placeholder="Enter discount code"
                        />
                        <button className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2 text-center inline-flex items-center ">
                            Apply
                        </button>
                    </div>
                </div> */}

                {/* Conditional rendering of card details */}
                {paymentMethod && !hasChangeCard ? (
                    <>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">
                                Card Number
                            </label>
                            <input
                                type="text"
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                placeholder="XXXX - XXXX - XXXX - XXXX"
                                value={`XXXX - XXXX - XXXX -${paymentMethod.card.last4}`}
                                readOnly
                            />
                        </div>
                        <div className="mb-4 flex flex-row gap-2">
                            <div>
                                <label htmlFor="expiry" className="block text-sm font-medium">
                                    Expiry
                                </label>
                                <input
                                    type="text"
                                    id="expiry"
                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    placeholder="MM/YY"
                                    value={`${paymentMethod.card.exp_month}/${paymentMethod.card.exp_year}`}
                                    readOnly
                                />
                            </div>
                            <div>
                                <label htmlFor="cvc" className="block text-sm font-medium">
                                    CVC
                                </label>
                                <input
                                    type="text"
                                    id="cvc"
                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    placeholder="CVC"
                                    value={"XXX"}
                                    readOnly
                                />
                            </div>
                        </div>
                    </>) : <CardElement className="card-element" />
                }

                <div className="w-full">
                    {
                        paymentMethod ?
                            hasChangeCard ?
                                <div>
                                    <button type="button" onClick={handleChangeCard} className="focus:outline-none text-black bg-white border border-gray-300 w-full hover:bg-gray-200 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2">Go to Previously Added Card</button>
                                </div> :
                                <div>
                                    <button type="button" onClick={handleChangeCard} className="focus:outline-none text-white bg-red-70 w-full hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 bg-red-600 ">Change Card & Proceed</button>
                                </div> : null
                    }
                </div>

                <button
                    type="button"
                    onClick={handlePayment}
                    className="w-full bg-blue-700 text-white py-2 px-4 rounded-lg hover:bg-blue-800 focus:outline-none focus:ring focus:ring-red-200"
                    disabled={isProcessing}
                >
                    {isProcessing ? "Processing..." : `Pay Now $${no_of_leads * 10}`}
                </button>
            </form>
        </div>
    );
};

export default AddLeadsModal;
