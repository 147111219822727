import React, { useContext, useEffect, useState } from "react";
import UIContext from "../../../../contexts/uiContext/UIContext";
import CustomerContext from "../../../../contexts/customerContext/customerContext";
import useGetCustomerCartLeads from "../../../../utils/hooks/getCustomerCartLeads/getCustomerCartLeads";
import { IPagination } from "../../../../@types/interface/pagination";
import ProjectCardVertical from "../../../shared/projectCardV2/ProjectCardV2";
import useModalOpen from "../../../../utils/hooks/modalOpen/useModalOpen";
import useSelectLeads from "../../../../utils/hooks/selectLeadstId/useSelectLeads";
import useQuerySearch from "../../../../utils/hooks/querySearch/useQuerySearch";
import CommonModal from "../../../shared/commonModal/CommonModal";
import CofirmPurchase from "../../../shared/payButton/confirmPurchase/CofirmPurchase";
import BasicPagination from "../../../shared/basicPagination/BasicPagination";
import ConfirmCartPurchase from "./confirmCartPurchase/ConfirmCartPurchase";
import useGetCustomerCartAmmount from "../../../../utils/hooks/getCustomerCartAmmount/useGetCustomerCartAmmount";
import { Drawer, Grid } from "@mui/material";
import OrderSummary from "./orderSummary/OrderSummary";
import ApplyCoupon from "./applyCoupon/ApplyCoupon";
import useGetWalletDiscountPrice from "../../../../utils/hooks/useGetWalletDiscountPrice/useGetWalletDiscountPrice";
import { api } from "../../../../utils/api";
import { toast } from "react-toastify";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';

const ExclusiveLeadsCart = () => {
	const { setDashboardHeader } = useContext(UIContext);
	const { user, setUser } = useContext(CustomerContext);
	const [couponDrawerOpen, setCouponDrawerOpen] = useState(false);
	const customerId = user?._id || "";
	const [pagination, setPagination] = useState<IPagination>({
		currentPage: 1,
		pageCount: 1,
	});
	const { modalOpen, handleOpenModal, handleCloseModal } = useModalOpen();
	const {
		modalOpen: cartModalOpen,
		handleOpenModal: handleOpenCartModal,
		handleCloseModal: handleCloseCartModal,
	} = useModalOpen();
	const { selectedLead, handleSetLeads } = useSelectLeads();
	const leads = useGetCustomerCartLeads({
		pagination,
		setPagination,
		customerId: customerId,
	});
	const {
		getDiscountPrice,
		discountPrice,
		couponCode,
		handleChangeCoupon,
		hasAppliedCoupon,
		isLoading,
		hasError,
	} = useGetWalletDiscountPrice();
	const totalAmount = useGetCustomerCartAmmount();
	const handlePurchaseLead = (leadsId: string) => {
		handleSetLeads(leadsId);
		handleOpenModal();
	};
	const handlePageChange = (
		event: React.ChangeEvent<unknown>,
		value: number
	) => {
		setPagination((prevPagination: IPagination) => {
			if (prevPagination.currentPage !== value) {
				return {
					...prevPagination,
					currentPage: value,
				};
			}
			return prevPagination;
		});
	};
	const handleRemoveFromCart = async (leadId: string) => {
		try {
			const params: any = {
				lead_object_id: leadId,
			};

			const response = await api.customers.deleteLeadsFromCart(params);
			if (response) {
				const tempUser = {
					...user,
					no_of_items_in_cart: (user?.no_of_items_in_cart || 0) - 1,
				};
				setUser(tempUser as any);
				toast.success("Successfully deleted from cart");
			}
		} catch (error: any) {
			alert(error.message || "Failed to add lead to cart.");
		}
	};

	const handleCheckoutButtonClick = () => {
		handleOpenCartModal();
	};

	const [drawerOpen, setDrawerOpen] = useState(false);

	const handleApplyCoupon = (e: React.FormEvent) => {
		e.preventDefault();
		getDiscountPrice(totalAmount);
	};
	useEffect(() => {
		setDashboardHeader("Leads Cart");
	}, [setDashboardHeader]);
	return (
		<div className="w-full ">

			<Grid container spacing={2} className="mb-4">
				<Grid item xs={12} md={12} lg={9} xl={9}>
					<div>
						{leads.length === 0 ? (
							<p>No leads found</p>
						) : (
							leads.map(lead => (
								<ProjectCardVertical
									key={lead._id}
									lead={lead}
									mode="MARKETPLACE"
									handlePurchaseLead={handlePurchaseLead}
									handleRemoveFromCart={handleRemoveFromCart}
									isInCart={true}
								/>
							))
						)}
						<BasicPagination
							currentPage={pagination.currentPage}
							pageCount={pagination.pageCount}
							handlePageChange={handlePageChange}
						/>
					</div>
				</Grid>
				<Grid item xs={12} md={12} lg={3} xl={3} className="hidden md:block">
					<div className="flex flex-col gap-4">
						<OrderSummary
							totalAmount={totalAmount}
							tobePaid={Math.max(
								totalAmount -
								(discountPrice || 0) -
								(user?.wallet_balance || 0),
								0
							)}
							handleCheckoutButtonClick={handleCheckoutButtonClick}
							couponPrice={discountPrice ? totalAmount - discountPrice : 0}
						/>
						<ApplyCoupon
							coupon={couponCode}
							handleCouponChange={handleChangeCoupon}
							handleApplyCoupon={handleApplyCoupon}
							hasAppliedCoupon={hasAppliedCoupon}
							hasError={hasError}
							isLoading={isLoading}
						/>
					</div>
				</Grid>
			</Grid>
			<CommonModal open={modalOpen} handleClose={handleCloseModal}>
				{selectedLead ? (
					<CofirmPurchase
						lead_object_id={selectedLead}
						key={selectedLead}
						onClose={handleCloseModal}
					/>
				) : (
					<div>Something went wrong</div>
				)}
			</CommonModal>
			<CommonModal open={cartModalOpen} handleClose={handleCloseCartModal}>
				<ConfirmCartPurchase
					totalBalance={discountPrice || totalAmount}
					onClose={handleCloseCartModal}
					coupon_code={couponCode}
				/>
			</CommonModal>

			{/* Floating Button for Apply Coupon */}
			<div className="w-full fixed bottom-4 left-1/2 transform -translate-x-1/2 flex items-center justify-center md:hidden">
				<div className="relative group w-full max-w-xs">
					<button
						className="w-full bg-blue-600 px-4 py-2 gap-1 rounded-full shadow-lg flex items-center justify-center text-white"
						onClick={() => setCouponDrawerOpen(true)}
						aria-label="Apply Coupon"
					>
						Checkout
						<AddShoppingCartIcon sx={{ fontSize: '18px' }} />
					</button>
				</div>
				<Drawer
					anchor="bottom"
					open={couponDrawerOpen}
					onClose={() => setCouponDrawerOpen(false)}
					PaperProps={{
						sx: {
							borderTopLeftRadius: '20px',
							borderTopRightRadius: '20px',
						},
					}}
				>
					<div className="p-4 flex-col gap-4 space-y-3">
						<OrderSummary
							totalAmount={totalAmount}
							tobePaid={Math.max(
								totalAmount -
								(discountPrice || 0) -
								(user?.wallet_balance || 0),
								0
							)}
							handleCheckoutButtonClick={handleCheckoutButtonClick}
							couponPrice={discountPrice ? totalAmount - discountPrice : 0}
						/>
						<ApplyCoupon
							coupon={couponCode}
							handleCouponChange={handleChangeCoupon}
							handleApplyCoupon={handleApplyCoupon}
							hasAppliedCoupon={hasAppliedCoupon}
							hasError={hasError}
							isLoading={isLoading}
						/>
					</div>
				</Drawer>
			</div>
		</div >
	);
};

export default ExclusiveLeadsCart;
