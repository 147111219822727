import { ColDef } from "ag-grid-community";
import TransactionStatusRenderer from "../transactionStatusCell/TransactionStatusRenderer";
import TransactionDateCellRenderer from "../transactionDateCellrenderer/TransactionDateCellRenderer";

export const TransactionColDefs: ColDef[] = [
	{
		field: "status",
		headerName: "Status",
		suppressSizeToFit: true,
		cellRenderer: TransactionStatusRenderer,
		flex: 1
	},
	{ field: "amount", headerName: "Amount", flex: 1 },
	{ field: "createdAt", headerName: "Date & Time", cellRenderer: TransactionDateCellRenderer, flex: 1 },

	{ field: "transaction_type", headerName: "Payment Method", flex: 1 },
	{ field: "transaction_id", headerName: "Transaction Id", flex: 1 }
];
