import React from "react";
import { LeadCardProps } from "../../../@types/props/leadCard.props";

const LeadCard: React.FC<LeadCardProps> = ({ className, content, label }: any) => {
	return (
		<div className={className}>
			<p className="text-3xl font-bold">{content}</p>
			<label className="font-normal text-md">{label}</label>
		</div>
	);
};

export default LeadCard;
