import { Chip } from "@mui/material";
import React from "react";

const TransactionStatusRenderer = () => {
	return (
		<>
			<div className="transaction-status-container">
				<div className="paid-transaction">
					<label>Paid</label>
				</div>
			</div>
		</>
	);
};

export default TransactionStatusRenderer;
