import { headers } from "../../../config/config";
import { AUTHORIZATION } from "../../../constants/api/auth";
import { MESSAGE } from "../../../constants/api/message";
import { request } from "../api";

const { Authorization, Bearer } = AUTHORIZATION;

const { post } = request;

const initialRoute = "auth";

export const verifyUser = async () => {
  try {
    const endpoint = `${initialRoute}/verify-user`;
    const token = localStorage.getItem("@token");
    const response = await post(
      endpoint,
      {},
      {
        ...headers,
        [Authorization]: `${Bearer} ${token}`,
      }
    );
    if (response) {
      const {
        data: { message },
      } = response;
      if (message === MESSAGE.post.succAuth) {
        const {
          data: { result },
        } = response;
        return result;
      }
    }
    throw new Error();
  } catch (error: any) {
    console.log(error);
    throw error;
  }
};
