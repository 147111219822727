import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import { IExclusiveLeads } from "../../../@types/interface/exclusiveLeads.interface";
import { ILeads } from "../../../@types/interface/leadDetails.interface";

const PremiumLeadAutoComplete = ({
	leads,
	handleChange,
	handleSelectLeads
}: {
	leads: (IExclusiveLeads | ILeads)[];
	handleChange: (value: string) => void;
	handleSelectLeads: (value: (IExclusiveLeads | ILeads)[]) => void;
}) => {
	const handleInputChange = (event: React.SyntheticEvent, value: string) => {
		handleChange(value);
	};
	const handleSelectLead = (event: React.SyntheticEvent, value: (IExclusiveLeads | ILeads)[]) => {
		if (value.length > 0) {
			handleSelectLeads(value);
		} else {
			handleSelectLeads([]);
		}
	};
	return (
		<div>
			<Autocomplete
				disablePortal
				options={leads}
				getOptionLabel={(option) => option.lead_name} // Assuming 'name' is the property you want to display
				onInputChange={handleInputChange}
				onChange={handleSelectLead}
				multiple={true}
				className="inputbox"
				renderInput={(params) => <TextField {...params} label="Leads" />}
			/>
		</div>
	);
};

export default PremiumLeadAutoComplete;
