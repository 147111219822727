import React, { useState } from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	FormControl,
	InputLabel,
	OutlinedInput,
	InputAdornment,
	DialogActions,
	Button
} from "@mui/material";

interface DepositModalProps {
	open: boolean;
	handleClose: () => void;
	onConfirm: (amount: string) => void;
}

const AddBalanceModal: React.FC<DepositModalProps> = ({ open, handleClose, onConfirm }) => {
	const [amount, setAmount] = useState("");

	const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setAmount(event.target.value);
	};

	const handleConfirmClick = () => {
		onConfirm(amount); // Pass the amount to the parent handler
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">Add balance</DialogTitle>
			<DialogContent className="label-and-input-field">
				<label style={{ marginBottom: "10px" }}>Add Amount</label>
				<FormControl sx={{ width: "400px", m: 1 }}>
					<InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
					<OutlinedInput
						id="outlined-adornment-amount"
						startAdornment={<InputAdornment position="start">$</InputAdornment>}
						label="Amount"
						value={amount}
						onChange={handleAmountChange}
					/>
				</FormControl>
			</DialogContent>
			<DialogActions>
				<Button variant="contained" onClick={handleClose} className="cancel-button">
					Cancel
				</Button>
				<Button variant="contained" className="confirm-button" onClick={handleConfirmClick} autoFocus>
					Add
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default AddBalanceModal;
