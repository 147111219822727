import { useCallback, useReducer } from "react";
import { Store } from "../../@types/contexts/adminContext/store.types";
import { ContextProviderProps } from "../../@types/contexts/context.types";
import { IGroupOwner } from "../../@types/interface/groupOwner.interface";
import AdminContext from "./adminContext";
import actions from "./actions";
import reducer from "./reducer";

const initialState: Store = {
	isLoggedIn: false,
	admin: null
};

const AdminContextProvider = ({ children }: ContextProviderProps) => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const value = {
		admin: state.admin,
		isLoggedIn: state.isLoggedIn,
		setAdmin: useCallback(
			(admin: IGroupOwner | null) => {
				dispatch({ type: actions.SET_ADMIN, payload: { ...state, admin } });
			},
			[dispatch]
		)
	};
	return <AdminContext.Provider value={value}>{children}</AdminContext.Provider>;
};

export default AdminContextProvider;
