import React, { useCallback, useContext, useEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import CustomerContext from '../../../../../contexts/customerContext/customerContext';
import { ICustomer } from '../../../../../@types/interface/Customer.interface';
import useGetOtp from '../../../../../utils/hooks/useGetOtp/useGetOtp';
import useQuerySearch from '../../../../../utils/hooks/querySearch/useQuerySearch';
import { useNavigate } from 'react-router-dom';
import useShowpassword from '../../../../../utils/hooks/useShowPassword/useShowpassword';
import { newleeyBlueBrandLogo } from '../../../../../assets';
import { redirectToHome } from '../../../../../utils/commonFunctions/redirectToHome';
import { FormControl, IconButton } from '@mui/material';
import CustomerChnagePassword from '../../customerLogin/customerChnagepassword/CustomerChnagePassword';
import LoginOtp from '../../login/loginOtp/LoginOtp';
import { ROLES } from '../../../../../constants/roles/Roles';
import { UPLOAD_TYPE } from '../../../../../constants/uploadType/uploadType';
import { api } from '../../../../../utils/api';
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const GeneratePasswordLink = () => {
	const { setUser, user } = useContext(CustomerContext);
	const [loginCredentials, setLoginCredentials] = useState<ICustomer>();
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [isChangePassword, setIsChangepassword] = useState<boolean>(false);
	const navigate = useNavigate();
	const type = useQuerySearch("type");
	const item = useQuerySearch("item");
	const { handleSendOtp, otp, userId } = useGetOtp("password-change");

	const handleCredentialChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const { name, value } = event.target;
			setLoginCredentials(
				Object.assign({}, loginCredentials, { [name]: value })
			);
		},
		[loginCredentials]
	);

	const handleGetOtp = async () => {
		if (!loginCredentials?.email) {
			return;
		}
		await handleSendOtp(loginCredentials?.email);
		setIsModalOpen(true);
	};

	const handleCloseModal = () => {
		setIsModalOpen(false);
	};

	const handleResetPasswordShow = () => {
		console.log("===>reset password");
		setIsChangepassword(true);
		setIsModalOpen(false);
	};

	console.log("===>", isChangePassword);

	const redirectToPage = useCallback(() => {
		if (type && item && user) {
			navigate(`/customer/${type}-leads?item=${item}`);
		}
	}, [type, item]);

	useEffect(() => {
		redirectToPage();
	}, [redirectToPage]);

	useEffect(() => {
		setLoginCredentials(
			Object.assign({}, loginCredentials, { role: "CUSTOMER" })
		);
	}, []);

	useEffect(() => {
		const user = localStorage.getItem("@userId");
		if (user !== null) {
			navigate("/customer/exclusive-leads"); // Navigate to the desired route
		}
	}, []);
	return (
		<div className="font-[sans-serif] text-[#333] w-full flex justify-center items-cente bg-white">
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick={false}
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="colored"
				className={"mt-20"}
			/>
			<div className="min-h-screen flex flex-col items-center justify-center lg:p-6 p-4">
				<div className="w-full flex flex-col items-center justify-center">
					<div className="mt-2 w-full px-6 py-8 space-y-6 max-w-md max-md:mx-auto">
						{/* <div className="flex justify-center text-center w-full">
									<img className="h-auto align-center" src={logo} alt="logo" />
								</div> */}

						<div className="flex justify-center">
							<img
								src={newleeyBlueBrandLogo}
								alt="newleey-blue-brand-logo"
								className="w-1/2 cursor-pointer"
								onClick={redirectToHome}
							/>
						</div>

						<h3 className="text-3xl font-extrabold mb-12 text-center">
							{isChangePassword
								? "Change Password to Login"
								: "Reset Password"}
						</h3>
						{!isChangePassword ? (
							<form onSubmit={handleGetOtp} autoComplete="on" method="">
								<FormControl className="form-container" id="form-container">
									<div className="w-full mb-3">
										<label
											htmlFor="email-address-icon"
											className="block mb-1 text-sm font-medium text-gray-900"
										>
											Your Email
										</label>
										<div className="relative">
											<div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
												<svg
													className="w-4 h-4 text-gray-500 dark:text-gray-400"
													aria-hidden="true"
													xmlns="http://www.w3.org/2000/svg"
													fill="currentColor"
													viewBox="0 0 20 16"
												>
													<path d="m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z" />
													<path d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z" />
												</svg>
											</div>
											<input
												type="text"
												id="email-address-icon"
												name="email"
												className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 px-4 py-3.5  "
												placeholder="nelson@newleey.com"
												value={loginCredentials?.email}
												onChange={handleCredentialChange}
												required
											/>
										</div>
									</div>

									<div
										className="flex justify-center"
										id="login-button-container"
									>
										<button
											type="button"
											onClick={handleGetOtp}
											className="text-white w-80 rounded-full
											bg-gray-900 mt-3 
											 dark:focus:ring-blue-800 font-medium text-lg px-5 py-2 text-center me-2"
										>
											Find Account
										</button>
									</div>
								</FormControl>
							</form>
						) : (
							<CustomerChnagePassword
								customerId={userId}
								key={"customer_change_password"}
							/>
						)}
						<LoginOtp
							expectedOtp={otp}
							handleClose={handleCloseModal}
							handleShowResetPassword={handleResetPasswordShow}
							open={isModalOpen}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default GeneratePasswordLink