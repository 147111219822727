import { useNavigate } from "react-router-dom";
import { api } from "../../api";

const useAssignExclusiveLead = () => {
	const navigate = useNavigate();
	const handleAsssignExclusiveLead = async (customer_object_id: string, lead_object_list: string[]) => {
		const payload = {
			customer_object_id,
			lead_object_list
		};
		const response = await api.customers.createMultipleExclusiveLeadBucket(payload);
		if (response) {
			navigate(-1);
			alert("Leads assigned successfully");
		}
		return response;
	};
	return { handleAsssignExclusiveLead };
};

export default useAssignExclusiveLead;
