import React, { useContext, useEffect, useState } from "react";
import { Drawer, List, Stack, Toolbar, IconButton, Collapse } from "@mui/material";
import { Menu, ChevronLeft, ChevronRight } from "@mui/icons-material";
import colorConfigs from "../../../config/colorConfigs";
import sizeConfigs from "../../../config/sizeConfigs";
import SidebarItemCollapse from "./sidebarItemCollapse/SidebarItemCollapse";
import Topbar from "../../layout/topbar/Topbar";
import AuthContext from "../../../contexts/customerContext/customerContext";
import { ClientBrandLogo } from "../";
import customerRoutes from "../../../routes/customerRoutes";
import CustomerSidebarItem from "./customerSidebarItem/CustomerSidebarItem";
import BottomUserDetails from "./bottomUserDetails/BottomUserDetails";
import CustomerAvatar from "../customerAvatar/CustomerAvatar";

interface SideBarProps {
	toggleSidebar: () => void;
	opens: boolean;
}

const CustomerSidebar: React.FC<SideBarProps> = ({ toggleSidebar, opens }) => {
	const { user } = useContext(AuthContext);
	const [open, setOpen] = useState(false);
	// const [collapseOpen, setCollapseOpen] = useState(false);

	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth <= 768) {
				setOpen(false);
				toggleSidebar();
			} else {
				setOpen(true);
			}
		};
		handleResize();
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);
	const handleToggleSidebar = () => {
		setOpen(!open);
		toggleSidebar();
	};

	// const handleToggleCollapse = () => {
	//   setCollapseOpen(!collapseOpen);
	// };

	return (
		<>
			<Topbar sidebarOpen={open} toggleSidebar={handleToggleSidebar} />
			<Drawer
				variant="permanent"
				sx={{
					width: open ? sizeConfigs.sidebar.width : 0,
					flexShrink: 0,
					transition: "width 0.8s ease-in-out",
					"& .MuiDrawer-paper": {
						width: open ? sizeConfigs.sidebar.width : 0,
						boxSizing: "border-box",
						borderRight: "0px",
						backgroundColor: colorConfigs.customerSidebar.bg,
						paddingBottom: "20px",
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-between",
						color: colorConfigs.customerSidebar.color,
						overflowX: "hidden",
						boxShadow: "0 2px 8px 2px gray"
					}
				}}
			>
				<div>
					<Toolbar
						style={{
							display: "flex",
							justifyContent: "right",
							alignItems: "right"
						}}
					>
						{open && (
							<IconButton
								onClick={handleToggleSidebar}
								className="sidebar-toggle-btn"
								style={{ color: "white", backgroundColor: "black" }}
							>
								<ChevronLeft />
							</IconButton>
						)}
					</Toolbar>

					<div className="mb-8">
						<ClientBrandLogo />
					</div>

					<CustomerAvatar />
					<p style={{ textAlign: "center", fontSize: "20px", marginBottom: "15px" }}>
						{user?.first_name}&nbsp;{user?.last_name}
					</p>
					{/* Sidebar content */}
					<List disablePadding>
						{customerRoutes.map((route, index) =>
							route.sidebarProps ? (
								route.child ? (
									<SidebarItemCollapse item={route} key={index} />
								) : (
									<CustomerSidebarItem item={route} key={index} />
								)
							) : null
						)}
					</List>
				</div>

				<BottomUserDetails />
			</Drawer>
		</>
	); ``
};

export default CustomerSidebar;
