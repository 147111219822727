import { useCallback, useEffect, useState } from "react";
import { ILeads } from "../../../@types/interface/leadDetails.interface";
import { api } from "../../api";

const useExclusiveLeadDetails = (lead_id: string) => {
	const [lead, setLead] = useState<ILeads | null>(null);
	const getLeadDetails = useCallback(async () => {
		try {
			const filter = {
				leadObjectId: lead_id
			};
			const lead = await api.leads.getLeadDetails(filter);
			console.log("===>lead", lead);
			setLead(lead);
		} catch (error) {
			console.error("Failed to get lead details");
		}
	}, [lead_id]);

	useEffect(() => {
		getLeadDetails();
	}, [getLeadDetails]);
	return lead;
};

export default useExclusiveLeadDetails;
