import { useCallback, useReducer } from "react";
import CustomerContext from "./customerContext";
import { ContextProviderProps } from "../../@types/contexts/context.types";
import actions from "./actions";
import reducer from "./reducer";
import { Store } from "../../@types/contexts/customerContext/store.types";
import { ICustomer } from "../../@types/interface/Customer.interface";

const initialState: Store = {
	isLoggedIn: false,
	user: null
};

const CustomerContextProvider = ({ children }: ContextProviderProps) => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const value = {
		user: state.user,
		isLoggedIn: state.isLoggedIn,
		setUser: useCallback(
			(user: ICustomer | null) => {
				dispatch({ type: actions.SET_CUSTOMER, payload: { ...state, user } });
			},
			[dispatch]
		)
	};
	return <CustomerContext.Provider value={value}>{children}</CustomerContext.Provider>;
};

export default CustomerContextProvider;
