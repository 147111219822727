import { Box, Tab, Tabs } from "@mui/material";
import TabPanel from "../../../shared/tabPanel/TabPanel";
import RecentMarketplace from "../recentmarketplace/RecentMarketplace";
import RecentPremiumLeads from "../../recentPrimiumLeads/RecentPrimiumLeads";
import { useState } from "react";
import a11yProps from "../../../../constants/tabPanelProps/ayProps";
import CustomerDashboard from "../dashBoard/CustomerDashboard";

const LatestLeads = () => {
	const [value, setValue] = useState<number>(0);
	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	return (
		<Box sx={{ width: "100%" }}>
			<Box>
				<Tabs
					value={value}
					aria-label="basic tabs example"
					sx={{ fontSize: "20px" }}
					TabIndicatorProps={{
						style: {
							backgroundColor: "#7c00fe"
						}
					}}
					onChange={handleChange}
					className="glb-tab-panel"
				>
					<Tab label="Exclusive Leads" {...a11yProps(0)} className="tab-label" />
					<Tab label="Premium Leads" {...a11yProps(1)} className="tab-label" />
				</Tabs>
			</Box>
			<TabPanel value={value} index={0}>
				<RecentMarketplace key={"latest_exclusive_leads"} />
			</TabPanel>
			<div className="mt-4">
				{value === 1 && <CustomerDashboard type="LATEST" key={"latest_premium_leads"} />}
			</div>
		</Box>
	);
};

export default LatestLeads;
