import { useContext, useState } from "react"
import CustomerContext from "../../../contexts/customerContext/customerContext"
import { api } from "../../api";
import useQuerySearch from "../querySearch/useQuerySearch";
import { useNavigate } from "react-router-dom";

const useChangePassword = () => {
  const { setUser } = useContext(CustomerContext);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const type = useQuerySearch("type");
  const item = useQuerySearch("item");

  const handleChangePassword = async (customerId: string, password: string) => {
    try {
      setLoading(true);
      const result = await api.auth.changePassword({
        customerId,
        newPassword: password
      });
      if (result) {
        setUser(result.result);
        localStorage.setItem("@token", result.token);
        if (type && item) {
          navigate(`/customer/${type}-leads?item=${item}`);
        } else {
          navigate("/customer/premium-leads");
        }
      } else {
        throw new Error("Error while changing password");
      }
    } catch (error: unknown) {
      console.log(error);
      throw error;
    } finally {
      setLoading(false);
    }
  }
  return { handleChangePassword }
}

export default useChangePassword