import * as React from "react";
import { Pagination, IconButton, Stack } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./pagination.css";

interface BasicPaginationProps {
	currentPage: number;
	handlePageChange: (event: React.ChangeEvent<unknown>, value: number) => void;
	pageCount: number;
}

const BasicPagination = ({ currentPage, handlePageChange, pageCount }: BasicPaginationProps) => {


	return (
		<div style={{ display: "flex", justifyContent: "right", marginTop: "20px" }}>
			<Stack direction="row" alignItems="center" spacing={1}>
				{/* Previous Button */}
				<IconButton
					onClick={() => handlePageChange({} as React.ChangeEvent<unknown>, Math.max(1, currentPage - 1))}
					disabled={currentPage === 1}
				>
					<p className="text-base text-blue-600 font-bold">Prev</p>
				</IconButton>

				{/* Pagination */}
				<Pagination
					shape="rounded"
					count={pageCount}
					page={currentPage}
					className="custom-pagination"
					onChange={handlePageChange}
				/>

				{/* Next Button */}
				<IconButton
					onClick={() => handlePageChange({} as React.ChangeEvent<unknown>, Math.min(pageCount, currentPage + 1))}
					disabled={currentPage === pageCount}
				>
					<p className="text-base text-blue-600 font-bold">Next</p>
				</IconButton>
			</Stack>
		</div>

	);
};

export default BasicPagination;
