export const ADMIN_ENV: "PROD" | "DEV" | "LOCAL" = "PROD";

const PROD_API_URL = "https://d21j8qwpgt1or1.cloudfront.net";
const DEV_API_URL = "http://54.152.238.83:4000";
const LOCAL_API_URL = "http://localhost:4000";
export const port = "8989";

export const version = "v1";

export const url =
  String(ADMIN_ENV) === "PROD"
    ? PROD_API_URL
    : String(ADMIN_ENV) === "DEV"
    ? DEV_API_URL
    : String(ADMIN_ENV) === "LOCAL"
    ? LOCAL_API_URL
    : PROD_API_URL;

const STRIPE_PUBLIC_KEY_PROD =
  "pk_live_51OS1iGKsaTt0wxtkcNWzCBWub4BqYlO65W6q8M3BViQiicyJccEoZfMcpHKTwlng1WGrOxGuaps97Yqi1uGL9UOv00hxjMpgu3";

const STRIPE_PUBLIC_KEY_DEV =
  "pk_test_51OS1iGKsaTt0wxtkpremB47zkVr8KWjPzsgfXU535bxDarMWSZ4LIwtEmfSTXzq4xBlEv5Vfcm6qlwVJXyNFuTUu0021RGlgCS";

export const STRIPE_PUBLIC_KEY =
  String(ADMIN_ENV) === "PROD" ? STRIPE_PUBLIC_KEY_PROD : STRIPE_PUBLIC_KEY_DEV;

export const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

// Local, Devlopment and Production Admin Portal URL
const localHomePageUrl = "http://localhost:5173";
const devHomePageUrl = "https://d3ksohhs0j0ac3.cloudfront.net";
const prodHomePageUrl = "https://newleey.com";

export const MARKETPLACE_LEADS_SHEETS_URL =
  "https://docs.google.com/spreadsheets/d/1VyVBudy3jjC3yeUngQHswTdDOtjSMeit9CT8nZ5RpTU/edit?usp=sharing";

export const EXCLUSIVE_LEADS_SHEET_URL =
  "https://docs.google.com/spreadsheets/d/1cWmiIRi4JSp3_Sn5wQAk8lyKU89lzRIixyGUOh2pISI/edit?usp=sharing";

export const LOGIN_REDIRECTION =
  String(ADMIN_ENV) === "PROD"
    ? `${prodHomePageUrl}/`
    : String(ADMIN_ENV) === "DEV"
    ? `${devHomePageUrl}/`
    : String(ADMIN_ENV) === "LOCAL"
    ? `${localHomePageUrl}/`
    : ""; // Has to change for Dev, local and prod
