import { useCallback, useState } from "react";
import DataGrid from "../../../../../shared/dataGrid/DataGrid";
import useGetPurchasedPremiumLeads from "../../../../../../utils/hooks/getPurchasedPremiumLeads/useGetPurchasedPremiumLeads";
import { IPagination } from "../../../../../../@types/interface/pagination";
import useQuerySearch from "../../../../../../utils/hooks/querySearch/useQuerySearch";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { PremiumLeadsDataColDefs } from "./premiumLeadsDataColDefs/PremiumLeadsDataColDefs";
import BasicPagination from "../../../../../shared/basicPagination/BasicPagination";

const PremiumLeadDetails = () => {
	const navigate = useNavigate();
	const [filters, setFilters] = useState([]);
	const [pagination, setPagination] = useState<IPagination>({
		currentPage: 1,
		pageCount: 1
	});
	const customerId = useQuerySearch("customerId");
	const { rowData: premiumLeads } = useGetPurchasedPremiumLeads(filters, pagination, setPagination, customerId || "");

	const handlePageChange = useCallback((event: React.ChangeEvent<unknown>, value: number) => {
		setPagination((prevPagination: IPagination) => ({
			...prevPagination,
			currentPage: value
		}));
	}, []);
	const handleNavigateToAssignLeads = () => {
		navigate(`/super-admin/assign-premium-leads?customerId=${customerId}`);
	};
	return (
		<div>
			<div className="add-btn">
				<Button
					className="button-dark-blue"
					endIcon={<AssignmentIcon />}
					onClick={handleNavigateToAssignLeads}
					sx={{ padding: "8px" }}
				>
					Assign leads
				</Button>
			</div>
			<DataGrid rowData={premiumLeads} colDefs={PremiumLeadsDataColDefs} />
			<BasicPagination pageCount={pagination.pageCount} currentPage={pagination.currentPage} handlePageChange={handlePageChange} />
		</div>
	);
};

export default PremiumLeadDetails;
