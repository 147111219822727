import { Box } from "@mui/material";
import { STATE_LIST } from "../../../../../constants/stateList/StateList";
import { ILeadsFilterProps } from "../../../../../@types/props/leadFilter.props";

const LeadsFilter = ({
	leadState,
	leadType,
	dealType,
	handleDealTypeChange,
	handleLeadStateChange,
	handleLeadTypeChange
}: ILeadsFilterProps) => {
	return (
		<Box className="  bg-white shadow-md rounded-xl p-5">
			<Box className="flex flex-col gap-3 lg:flex-row justify-between">
				<select className="filter-dropdown px-2 py-3 w-full border border-blue-200" value={dealType} onChange={handleDealTypeChange}>
					<option value="">Select Deal Type</option>
					<option value="">All</option>
					<option value="Land">Land</option>
					<option value="Mobile Home">Mobile Home</option>
					<option value="Multi Family">Multi Family</option>
					<option value="Apartment">Apartment</option>
					<option value="Single Family">Single Family</option>
					<option value="Condo">Condo</option>
				</select>
				<select value={leadState} onChange={handleLeadStateChange} className="filter-dropdown px-2 py-3 w-full border border-blue-200">
					<option value="">Select State</option>
					<option value="">All</option>
					{STATE_LIST.map((state) => (
						<option key={state.value} value={state.label}>
							{state.label}
						</option>
					))}
				</select>
				{/* <select className="filter-dropdown px-2 py-3 " value={leadType} onChange={handleLeadTypeChange}>
					<option value="">Select Lead Type</option>
					<option value="">All</option>
					<option value="inbound">In Bound</option>
					<option value="outbound">Out Bound</option>
				</select> */}
			</Box>
		</Box>
	);
};

export default LeadsFilter;
