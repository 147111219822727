import { ColDef } from "ag-grid-community";
import DateCellRenderer from "../../../../shared/cellRenderer/DateCellRenderer";

export const CustomerLeadsColDefs: ColDef[] = [
  {
    field: "lead_name",
    headerName: "Full Name",
    filter: "agTextColumnFilter",
    floatingFilter: true,
  },
  {
    field: "lead_email",
    headerName: "Email",
    filter: "agTextColumnFilter",
    floatingFilter: true,
  },
  {
    field: "lead_phone",
    headerName: "Phone",
    filter: "agTextColumnFilter",
    floatingFilter: true,
  },
  {
    field: "address_line_1",
    headerName: "Property Address",
    filter: "agTextColumnFilter",
    floatingFilter: true,
  },
  {
    field: "city",
    headerName: "City",
    filter: "agTextColumnFilter",
    floatingFilter: true,
  },

  {
    field: "state",
    headerName: "State",
    filter: "agTextColumnFilter",
    floatingFilter: true,
  },
  {
    field: "ZIP",
    headerName: "Zip",
    filter: "agTextColumnFilter",
    floatingFilter: true,
  },
  {
    field: "property_type",
    headerName: "Property Type",
    filter: "agTextColumnFilter",
    floatingFilter: true,
  },
  {
    field: "lead_type",
    headerName: "Lead Type",
    filter: "agTextColumnFilter",
    floatingFilter: true,
  },
  {
    field: "property_condition",
    headerName: "What Is The Condition Of The Property?",
    filter: "agTextColumnFilter",
    floatingFilter: true,
  },

  {
    field: "selling_timeline",
    headerName: "Selling Timeline",
    filter: "agTextColumnFilter",
    floatingFilter: true,
  },
  {
    field: "listed",
    headerName: "Is The Property Listed With A Realtor?",
    filter: "agTextColumnFilter",
    floatingFilter: true,
  },
  {
    field: "bidding_price",
    headerName: "Bidding Price (USD)",
    filter: "agTextColumnFilter",
    floatingFilter: true,
  },
  {
    field: "reason",
    headerName: "Why Are You Looking To Sell Your Property?",
    filter: "agTextColumnFilter",
    floatingFilter: true,
  },
  {
    field: "createdAt",
    cellRenderer: DateCellRenderer,
    headerName: "Purchased/Fav Date",
  },
];
