import React from "react";
import { FaCalendar, FaEnvelope, FaClock } from "react-icons/fa";
import { FaHouse, FaLandmarkFlag, FaLocationDot, FaPerson } from "react-icons/fa6";
import GoogleMap from "../googleMap/GoogleMap";
import { IProjectCardProps } from "../../../@types/props/projectcard.props";
import { ClientBrandLogo } from "../clientBrandLogo/ClientBrandLogo";
import { truncateString } from "../../../utils/commonFunctions/truncateString";

const ProjectCardVertical: React.FC<IProjectCardProps> = ({ lead, mode, handlePurchaseLead, handleAddToCart, isInCart, handleRemoveFromCart }) => {
	const handlePurchaseLeadClick = () => {
		if (handlePurchaseLead) {
			handlePurchaseLead(lead._id || "");
		}
	};
	const handleAddToCartClick = () => {
		if (handleAddToCart) {
			handleAddToCart(lead._id || "");
		}
	};

	const handleRemoveFromCartClick = () => {
		if (handleRemoveFromCart) {
			handleRemoveFromCart(lead._id || ""); // Call the same function to remove from cart
		}
	};

	return (
		<div className="flex flex-col md:w-full mx-auto p-4 bg-gray-100 shadow-lg rounded-lg border mb-8 ">
			{/* Header Section */}
			<div className="block md:flex items-center justify-start border-b pb-4 px-4 bg-gray-100 gap-4 mb-4">
				<ClientBrandLogo />

				<div className="bg-gray-50 flex justify-start items-center px-4 py-2 md:text-right gap-4 mt-4">
					<p className="text-md font-medium">
						Phone:{" "}
						<span className="font-bold tru" style={{ filter: "blur(3px)" }}>
							{truncateString(lead.lead_phone)}
						</span>
					</p>
					<p className="text-md font-medium">
						Address:{" "}
						<span className="font-bold overflow-x-scroll" style={{ filter: "blur(3px)" }}>
							{truncateString(lead.address_line_1)}
						</span>
					</p>
				</div>
				<div></div>
				<div className="ml-auto mt-4">
					<span className="px-4 py-2 bg-gradient-to-r from-yellow-500 to-yellow-600 text-white font-semibold rounded-3xl shadow-md animate-pulse">
						EXCLUSIVE LEADS
					</span>
				</div>
			</div>

			{/* Main Content Section */}
			<div className="flex flex-col md:grid grid-cols-12 gap-6">
				{/* Left Section - Map */}
				<div className="md:col-span-4">
					<div className="w-full mx-auto rounded-lg overflow-hidden shadow-md">
						<GoogleMap height={250} address={`${lead.city || lead.state}`} />
					</div>
				</div>

				{/* Middle Section - Details */}
				<div className="px-2 md:col-span-4 flex flex-col space-y-2">
					<h3 className="text-xl font-bold">{lead.city}</h3>
					<div className="flex items-center gap-2 mb-2">
						<FaLocationDot size={18} color="gray" />
						<p className="text-sm text-gray-500">State: {lead.state}</p>
					</div>

					<div className="flex items-center  gap-2 mb-2">
						<FaCalendar size={18} color="gray" />
						<p className="text-sm text-gray-500">
							{lead.createdAt ? new Date(lead.createdAt).toLocaleDateString("en-US") : "N/A"}
						</p>
					</div>

					<div className="flex items-center  gap-2 mb-2">
						<FaEnvelope size={18} color="gray" />
						<p className="text-sm text-gray-500 ">{lead.lead_email}</p>
					</div>
					<div className="flex items-center  gap-2 mb-2">
						<FaClock size={18} color="gray" />
						<p className="text-sm text-gray-500 ">Seller Timeline: {lead.selling_timeline}</p>
					</div>
					<div className="flex justify-between items-center mt-4">
						{mode === "MARKETPLACE" ? (
							<div className="w-full flex items-center justify-center gap-4">
								<button
									className="w-full mt-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 font-bold"
									onClick={handlePurchaseLeadClick}
								>
									Buy Now
								</button>
								{isInCart === true ? (
									<button
										className="w-full flex items justify-center mt-2 px-4 py-2 bg-gray-800 border border-gray-200 text-gray-200 hover:bg-gray-900 rounded-lg"
										onClick={handleRemoveFromCartClick}
									>
										Remove
									</button>
								) : (
									<button
										className={`w-full mt-2 px-4 py-2 bg-gray-800 text-white rounded hover:bg-gray-600 ${lead.is_on_cart ? "opacity-90 cursor-not-allowed" : ""}`}
										onClick={handleAddToCartClick}
									>
										{
											lead.is_on_cart ? (
												<span className="text-gray-500">Already in cart</span>
											) : (
												<span className="text-white">Add to cart</span>
											)
										}
									</button>
								)}
							</div>
						) : (
							<div></div>
						)}
					</div>
				</div>

				{/* Right Section - Attributes */}
				<div className="px-2 md:col-span-4 text-sm space-y-2">
					<div>
						<p className="text-base text-gray-500">Price per lead</p>
						<span className="text-3xl font-bold text-black">${lead.bidding_price}</span>
					</div>
					<div className="flex flex-col items-start mb-4">
						<p className="flex gap-1 text-gray-500 text-sm">
							<FaPerson size={18} color="gray" />
							Occupied by
						</p>
						<p className="mx-6 text-black font-bold">{lead?.occupied}</p>
					</div>

					<div className="flex flex-col items-start mb-4">
						<p className="flex gap-1 text-gray-500">
							<FaLandmarkFlag size={18} color="gray" />
							Property owner
						</p>
						<p className="mx-6 text-black font-bold" style={{ filter: "blur(3px)" }}>
							{lead.lead_name}
						</p>
					</div>
					<div className="flex flex-col items-start mb-4">
						<p className="flex gap-1 text-gray-500">
							<FaHouse size={18} color="gray" />
							Property Type
						</p>
						<p className="mx-6 text-black font-bold">{lead?.property_type}</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProjectCardVertical;
