import { useContext } from "react";
import { api } from "../../api";
import CustomerContext from "../../../contexts/customerContext/customerContext";
import { BillingType } from "../../../@types/types/billing/billing.type";

const useCreateSubscription = () => {
  const { user, setUser } = useContext(CustomerContext);
  const handleCreateSubscription = async (
    plan_object_id: string,
    billing_type: BillingType,
    payment_method_id: string,
    couponId?: string
  ) => {
    try {
      const payload = {
        customer_object_id: user?._id,
        plan_object_id: plan_object_id,
        billing_type,
        payment_method_id,
        couponId,
      };
      const response = await api.payment.createSubscription(payload);
      if (response) {
        console.log("===>response", response);
        setUser(response.customer);
        return response;
      }
    } catch (error) {
      console.log(error);
    }
  };
  return { handleCreateSubscription };
};

export default useCreateSubscription;
