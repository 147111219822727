import { Paper, TextField } from "@mui/material";
import React, { useContext, useEffect } from "react";
import "./profile.css";
import UIContext from "../../../contexts/uiContext/UIContext";
import AdminContext from "../../../contexts/adminContext/adminContext";

const SuperAdminProfile = () => {
	const { setDashboardHeader } = useContext(UIContext);
	const { admin } = useContext(AdminContext);

	useEffect(() => {
		setDashboardHeader("Profile Settings");
	}, [setDashboardHeader]);
	return (
		<div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
			<Paper sx={{ width: "500px", padding: "20px" }}>
				<div className="value-with-labels">
					<label>Full Name:</label>

					<TextField
						variant="outlined"
						value={admin?.first_name + " " + admin?.last_name}
						sx={{ width: "300px" }}
						className="text-value"
					/>
				</div>

				<div className="value-with-labels">
					<label>Email Id:</label>

					<TextField variant="outlined" value={admin?.email} sx={{ width: "300px" }} className="text-value" />
				</div>
				<div className="value-with-labels">
					<label>Phone Number:</label>

					<TextField
						variant="outlined"
						value={admin?.phone_number}
						sx={{ width: "300px" }}
						className="text-value"
					/>
				</div>

				{/* <div className="edit-button-container">
					<Button variant="contained" className="button-violet" endIcon={<ModeEditOutlineIcon />}>
						Edit
					</Button>
					<Button endIcon={<CloseIcon />} className="violet-outlined">
						Cancel
					</Button>
				</div> */}
			</Paper>
		</div>
	);
};

export default SuperAdminProfile;
