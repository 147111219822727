import { replaceUnderScore } from "./replaceUnderScore";

export const formatHeader = (header: string): string => {
  switch (header) {
    case "lead_name":
      return "Full Name";
    case "lead_email":
      return "Email";
    case "lead_phone":
      return "Phone";
    case "address_line_1":
      return "Property Address";
    case "city":
      return "City";
    case "state":
      return "State";
    case "ZIP":
      return "Zip";
    case "property_type":
      return "Property Type";
    case "lead_type":
      return "Lead Type";
    case "property_condition":
      return "What Is The Condition Of The Property?";
    case "listed":
      return "Is The Property Listed With A Realtor?";
    case "selling_timeline":
      return "Selling Timeline";
    case "reason":
      return "Why Are You Looking To Sell Your Property?";
    default:
      return replaceUnderScore(header);
  }
};
