import { Button, FormControl, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import useGetAllPlans from "../../../../utils/hooks/getAllPlans/useGetAllPlans";
import { BillingType } from "../../../../@types/types/billing/billing.type";
import UIContext from "../../../../contexts/uiContext/UIContext";
import useCreateInfluencer from "../../../../utils/hooks/createInfluencer/useCreateInfluencer";
import { useNavigate } from "react-router-dom";

const AddInfluencer = () => {
	const navigate = useNavigate();
	const { setDashboardHeader } = useContext(UIContext);
	const [selectedPlan, setSelectedPlan] = useState('');
	const { plans } = useGetAllPlans();
	const { addInfluencer } = useCreateInfluencer();

	const [influencerData, setInfluencerData] = useState<any>({
		full_name: "",
		email: "",
		phone_number: "",
		instagram_handle: "",
		facebook_handle: "",
		percentOff: 0,
		duration: "",
		planIds: [],
		durationInMonths: 0,

		twitter_handle: "",
		code: "",
		referred_customers_no: 0,

	});


	const handleSubmit = async (event: { preventDefault: () => void; }) => {
		event.preventDefault();
		const response = await addInfluencer(influencerData);
		console.log("response", influencerData);
		if (response) {
			navigate("/super-admin/influencers");
			console.log("Influencer added successfully:", response);

		} else {
			console.log("Failed to add influencer");
		}
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) => {
		const { name, value } = e.target;
		setInfluencerData((prevData: any) => ({ ...prevData, [name]: value }));
	};

	useEffect(() => {
		setDashboardHeader("Add Influencer");

	}, [setDashboardHeader]);
	return (
		<div>
			<form onSubmit={handleSubmit}>
				<h1 className="font-bold text-xl mb-4">Add Influencer</h1>
				<Paper sx={{ padding: "20px" }}>
					<div className="flex-input-field">
						<label>Full name:</label>
						<TextField name="full_name" type="text" variant="outlined" placeholder="Enter full name" sx={{ width: "500px" }} value={influencerData.full_name}
							onChange={handleChange} />
					</div>
					<div className="flex-input-field">
						<label>Email address:</label>
						<TextField name="email" type="email" variant="outlined" placeholder="Enter email id" sx={{ width: "500px" }} value={influencerData.email} onChange={handleChange} />
					</div>
					<div className="flex-input-field">
						<label>Phone number:</label>
						<TextField name="phone_number" type="phone" variant="outlined" placeholder="Enter phone number" sx={{ width: "500px" }} value={influencerData.phone_number} onChange={handleChange} />
					</div>
					<div className="flex-input-field">
						<label>Instagram handle:</label>
						<TextField name="instagram_handle" type="text" variant="outlined" placeholder="Enter instagram handle" sx={{ width: "500px" }} value={influencerData.instagram_handle} onChange={handleChange} />
					</div>
					<div className="flex-input-field">
						<label>Facebook handle:</label>
						<TextField name="facebook_handle" type="text" variant="outlined" placeholder="Enter facebook handle" sx={{ width: "500px" }} value={influencerData.facebook_handle} onChange={handleChange} />
					</div>
					<div className="flex-input-field">
						<label>Twitter handle:</label>
						<TextField name="twitter_handle" type="text" variant="outlined" placeholder="Enter twitter handle" sx={{ width: "500px" }} value={influencerData.twitter_handle} onChange={handleChange} />
					</div>
					<div className="flex-input-field">
						<label>Discount offer:</label>
						<TextField name="percentOff" type="number" variant="outlined" placeholder="Enter discount offer price" sx={{ width: "500px" }} value={influencerData.percentOff} onChange={handleChange} />
					</div>
					<div className="flex-input-field">
						<label>Duration:</label>
						<FormControl>
							<InputLabel id="duration-select-label">Select Duration</InputLabel>
							<Select
								labelId="duration-select-label"
								name="duration"
								value={influencerData.duration}
								sx={{ width: "500px" }}
								onChange={handleChange}
							>
								<MenuItem value="once">Once</MenuItem>
								<MenuItem value="repeating">Repeating</MenuItem>
								<MenuItem value="forever">Forever</MenuItem>
							</Select>
						</FormControl>
					</div>
					<div className="flex-input-field">
						<label>Coupon code:</label>
						<TextField name="code" type="text" variant="outlined" placeholder="Enter coupon code" sx={{ width: "500px" }} value={influencerData.code} onChange={handleChange} />
					</div>
					<div className="flex-input-field">
						<label>Month duration:</label>
						<TextField name="durationInMonths" type="number" variant="outlined" placeholder="Enter duration month" sx={{ width: "500px" }} value={influencerData.durationInMonths} onChange={handleChange} />
					</div>
					<div className="flex-input-field">
						<label>Select plan id:</label>
						<FormControl>
							<InputLabel id="plan-select-label">Select a Plan</InputLabel>
							<Select
								labelId="plan-select-label"
								name="planIds"
								value={influencerData.planIds}
								sx={{ width: "500px" }}
								onChange={handleChange}
								multiple
							>
								{plans.map((plan) => (
									<MenuItem key={plan.priceId} value={plan.priceId}>
										{plan.plan_name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</div>
				</Paper>
				<Button onClick={handleSubmit} className="btn" variant="contained">Submit</Button>
			</form>

		</div>
	);
};

export default AddInfluencer;

// import React from 'react'

// const AddInfluencer = () => {
// 	return (
// 		<div>AddInfluencer</div>
// 	)
// }

// export default AddInfluencer