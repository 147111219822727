import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useCallback, useMemo, useRef } from "react";
import { DataGridProp } from "../../../@types/props/DataGrid.props";

const DataGrid = ({ rowData, colDefs, onFilterChange, handleCellValueChange }: DataGridProp) => {
	const gridApi = useRef<any>(null);
	const defaultColDef = useMemo(
		() => ({
			filterParams: {
				debounceMs: 500
			}
		}),
		[]
	);
	const handleCellValueChanged = useCallback((params: any) => {
		const { data, oldValue, newValue, colDef } = params;

		if (oldValue !== newValue) {
			const updateField = colDef.field;
			const payload = {
				customerDetails: {
					[updateField]: newValue
				},
				customerId: data._id
			};
			if (handleCellValueChange) {
				handleCellValueChange(payload);
			}
		}
	}, []);

	const onFilterChanged = useCallback(() => {
		if (gridApi.current) {
			const filterModel = gridApi.current.getFilterModel();
			if (onFilterChange) {
				onFilterChange(filterModel);
			}
		}
	}, [onFilterChange]);
	return (
		<div className="ag-theme-alpine" style={{ height: 600 }}>
			<AgGridReact
				onGridReady={(params: any) => {
					gridApi.current = params.api;
				}}
				enableCellTextSelection={true}
				animateRows={true}
				rowSelection="multiple"
				rowData={rowData}
				columnDefs={colDefs}
				defaultColDef={defaultColDef}
				onFilterChanged={onFilterChanged}
				onCellValueChanged={handleCellValueChanged}
			/>
		</div>
	);
};

export default DataGrid;
