import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { IPlan } from "../../../../@types/interface/plan.interface";

const ActionCellRenderer = ({ data }: { data: IPlan }) => {
	const navigate = useNavigate();
	const handleNavigateEdit = () => {
		navigate(`/super-admin/edit-plan?planId=${data._id}`);
	};
	return (
		<div>
			<IconButton onClick={handleNavigateEdit}>
				<EditIcon color="primary" />
			</IconButton>
			{/* <IconButton>
				<DeleteIcon color="error" />
			</IconButton> */}
		</div>
	);
};

export default ActionCellRenderer;
