/* eslint-disable arrow-parens */
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormControl, Button, InputAdornment, IconButton, MenuItem, Select } from "@mui/material";
// import { trackPromise } from "react-promise-tracker";
// other-shared-components / files
// import { MEMBER_PORTAL_REDIRECTION } from "../../../../config/config";
import { api } from "../../../../utils/api";
import { ROLES } from "../../../../constants/roles/Roles";
import SignUpFooterLink from "../authFooter/SignUpFooterLink";
import { Validation } from "../../../../@types/props/validation.types";

// images & icons
import { login, newleeyBlueBrandLogo } from "../../../../assets";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import "./login.scss";
import { ClientBrandLogo } from "../../../shared";
import CustomerContext from "../../../../contexts/customerContext/customerContext";
import AdminContext from "../../../../contexts/adminContext/adminContext";
import { ICustomer } from "../../../../@types/interface/Customer.interface";
import useQuerySearch from "../../../../utils/hooks/querySearch/useQuerySearch";
import ConfettiComponent from "../../../shared/confetti/CustomConfetti";
import useGetOtp from "../../../../utils/hooks/useGetOtp/useGetOtp";
import { UPLOAD_TYPE } from "../../../../constants/uploadType/uploadType";
import LoginOtp from "./loginOtp/LoginOtp";
import { toast } from "react-toastify";

const Login = () => {
	const { setUser, user } = useContext(CustomerContext);
	const { setAdmin } = useContext(AdminContext);
	const [loginCredentials, setLoginCredentials] = useState<ICustomer>();
	const [showPassword, setShowPassword] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [anchorElement, setAnchorElement] = useState<(EventTarget & HTMLButtonElement) | null>(null);
	const [validation, setValidation] = useState<Validation>({
		employee: {},
		reEnterSSN: "",
		paycheck: {},
		status: "invalid"
	});
	const navigate = useNavigate();
	const type = useQuerySearch("type");
	const item = useQuerySearch("item");
	const { handleSendOtp, loading, otp } = useGetOtp();

	const handleShowPassword = useCallback(() => {
		setShowPassword(!showPassword);
	}, [showPassword]);

	const handleCredentialChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const { name, value } = event.target;
			setLoginCredentials(Object.assign({}, loginCredentials, { [name]: value }));
		},
		[loginCredentials]
	);

	const handleCredentialRoleChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, name: string) => {
			setAnchorElement(null);
			const value = event.currentTarget.getAttribute("value");

			setLoginCredentials(Object.assign({}, loginCredentials, { [name]: value }));
		},
		[loginCredentials]
	);

	const handelChangeRole = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		const { name, value } = event.target;
		setLoginCredentials(Object.assign({}, loginCredentials, { [name]: value }));
	};

	const handleGetOtp = async (email: string) => {
		await handleSendOtp(email);
		setIsModalOpen(true);
	};

	const handleCloseModal = () => {
		setIsModalOpen(false);
	}

	const handleLogin = async (event: any) => {
		try {
			event.preventDefault();
			const { result: response, token } = await api.auth.login(JSON.stringify(loginCredentials));
			if (response.role === ROLES.customer) {
				console.log("===>response", response);
				if (response?.upload_type === UPLOAD_TYPE.migrated && !response?.has_changed_password) {
					handleGetOtp(response.email);
					return;
				}
				console.log("===?token", token);
				localStorage.setItem("@token", token);
				setUser(response);
				if (type && item) {
					navigate(`/customer/${type}-leads?item=${item}`);
				} else {
					navigate("/customer/premium-leads");
				}
			} else if (response.role === ROLES.super_admin) {
				setAdmin(response);
				navigate("/super-admin/dashboard");
			} else if (response.role === ROLES.admin) {
				setAdmin(response);
				navigate("/super-admin/dashboard");
			} else {
				toast.error("Login failed");
			}
		} catch (error) {
			console.error("Error login", error);
			alert("failed to log in");
		}
	};
	const redirectToPage = useCallback(() => {
		if (type && item && user) {
			navigate(`/customer/${type}-leads?item=${item}`);
		}
	}, [type, item]);

	useEffect(() => {
		redirectToPage();
	}, [redirectToPage]);

	return (
		<div className="font-[sans-serif] text-[#333] w-full bg-[#efeafb]">
			<div className="min-h-screen flex flex-col items-center justify-center lg:p-6 p-4">
				<div className="grid grid-cols-1 md:grid-cols-2 max-w-6xl w-full">
					<div className="hidden sm:flex flex-col justify-center align-center text-center pl-5">
						<h1 className="text-left font-bold w-full text-3xl mt-10">
							Login to <span className="text-[#3b38f6]">Your Profile!</span>
						</h1>
						<p className="mt-4 text-xs text-left">
							Sign up now to start your journey towards finding the perfect home. It’s fast, easy, and
							free! Whether you’re buying, selling, or just exploring, our platform offers all the tools
							and resources you need. Create your account today and take the first step towards your dream
							home with Find My House.
						</p>
						<img className="p-5" src={login} alt="" />
					</div>

					<div className="w-full flex flex-col items-center justify-center">
						<div className="mt-2 w-full bg-white shadow-lg rounded-xl px-6 py-8 space-y-6 max-w-md max-md:mx-auto">
							{/* <div className="flex justify-center text-center w-full">
									<img className="h-auto align-center" src={logo} alt="logo" />
								</div> */}

							<div className="">
								<ClientBrandLogo />
							</div>

							<h3 className="text-3xl text-[rgb(74 222 128)] font-extrabold mb-12 max-md:text-center">
								Welcome Back
							</h3>

							<form onSubmit={handleLogin} autoComplete="off" method="">
								<FormControl className="form-container" id="form-container">
									<div className="select-role-container" id="select-role-container">
										<div className="mb-3">
											<label
												htmlFor="email-address-icon"
												className="block mb-1 text-sm font-medium text-gray-900"
											>
												Login As
											</label>
											<form className="w-full">
												<label
													htmlFor="role"
													className="block text-sm font-medium text-gray-900 dark-text-white"
												></label>
												<select
													id="role"
													name="role"
													onChange={handelChangeRole}
													value={loginCredentials?.role}
													className="bg-gray-50 border py-4 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark-bg-gray-700 dark-border-gray-600 dark-placeholder-gray-400 dark-text-white dark-focus:ring-blue-500 dark-focus:border-blue-500"
												>
													<option selected>Select Role</option>
													<option value={ROLES.super_admin}>{ROLES.super_admin}</option>
													<option value={ROLES.admin}>{ROLES.admin}</option>
													<option value={ROLES.customer}>{ROLES.customer}</option>
												</select>
											</form>

											{validation?.role ? (
												<div className="details validation-text">
													<span className="select-validation-text">{validation?.role}</span>
												</div>
											) : null}
										</div>
									</div>
									<div className="w-full mb-3">
										<label
											htmlFor="email-address-icon"
											className="block mb-1 text-sm font-medium text-gray-900"
										>
											Your Email
										</label>
										<div className="relative">
											<div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
												<svg
													className="w-4 h-4 text-gray-500 dark:text-gray-400"
													aria-hidden="true"
													xmlns="http://www.w3.org/2000/svg"
													fill="currentColor"
													viewBox="0 0 20 16"
												>
													<path d="m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z" />
													<path d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z" />
												</svg>
											</div>
											<input
												type="text"
												id="email-address-icon"
												name="email"
												className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 px-4 py-3.5  "
												placeholder="nelson@newleey.com"
												value={loginCredentials?.email}
												onChange={handleCredentialChange}
												required
											/>
										</div>
									</div>
									<div className="mb-3">
										<label
											htmlFor="password"
											className="block mb-1 text-sm font-medium text-gray-900"
										>
											Your Password
										</label>
										<div className="relative">
											<input
												name="password"
												id="password"
												type={showPassword ? "text" : "password"}
												autoComplete="your password"
												className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 px-4 py-3.5  "
												placeholder="Password"
												value={loginCredentials?.password}
												onChange={handleCredentialChange}
												required
											/>
											<div className="absolute inset-y-0 end-0 flex items-center pe-3.5">
												<IconButton onClick={handleShowPassword}>
													{showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
												</IconButton>
											</div>
										</div>
									</div>
									<SignUpFooterLink />
									{/* <ForgetCredentialFooterLink /> */}
									<div className="flex justify-end" id="login-button-container">
										<button
											type="button"
											onClick={handleLogin}
											className="text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
										>
											Login
										</button>
									</div>
								</FormControl>
							</form>
							<LoginOtp expectedOtp={otp} handleClose={handleCloseModal} handleShowResetPassword={() => { console.log("first") }} open={isModalOpen} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Login;
