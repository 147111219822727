import { login } from "./auth/loginAdmin";
import { getOtp } from "./auth/otpAdmin";
import { addBanner } from "./banner/postBanner";
import {
  forwardEnqury,
  getBooking,
  getForwardEnquryList,
  updateEnquiryStatus,
} from "./booking/booking";
import { getBuilder } from "./builders/getBuilder";
import { getBuilderByID } from "./builders/getBuilderByID";
import { addBuilder } from "./builders/postBuilder";
import {
  createCustomerCart,
  deleteLeadsFromCart,
  getCustomer,
  getCustomerCartLeads,
  getCustomerDetails,
  getCustomerDetailsByEmail,
  getCustomerPaymentMethod,
  getCustomerSubscriptionDetails,
  updateCustomerPhoto,
} from "./customer/customer.api";
import { getEmployeeList } from "./employee/getEmployee";
import { getUsers } from "./user/getUser";
import {
  createAdmin,
  getAdminDetails,
} from "./permission/userManagement/createAdmin";
import { getBuilderList } from "./permission/userManagement/getBuilderList";
import { addPlot } from "./projects/addProject";
import { adminAssignProject } from "./projects/assignProject";
import {
  getAdminPlots,
  getAssignedProjectList,
  getProjectDetails,
} from "./projects/getAdminProjects";
import { getAllProjects, getPlots } from "./projects/getProjects";
import { updatePlotPostion } from "./projects/updatePlotPosition";
import { getProjectList } from "./projects/getProjectList";
import { addProjectFacility } from "./projects/addProjectFacility";
import { getProjectFacilities } from "./projects/getProjectFacilities";
import { deleteProjectFacility } from "./projects/deleteProjectFacility";
import { updateProject } from "./projects/updateProject";
import {
  getBookingDetails,
  getEnquiryDetails,
} from "./projects/getEnquiryDetails";
import { createFollowUp } from "./followup/createFollowup";
import { getFollowUp } from "./followup/getFollowUp";
import { getResellerEnquiry } from "./resellerEnquiry/getResellerEnquiry";
import { getAdminAndBuilder } from "./projects/getAdminAndProjects";
import { addBank } from "./bank/postBank";
import { getBankContacts } from "./bank/getBankContacts";
import { getUpcomingProjects } from "./upcomingProjects/getUpcomingProjects";
import { addUpcomingProject } from "./upcomingProjects/addUpcomingProject";
import {
  deleteExclusiveLead,
  deleteExclusiveLeadBucket,
  deletePremiumLeadBucket,
  deletePremiumLeads,
  getCustomerExclusiveLeads,
  getCustomerFavPremiumLeads,
  getCustomerLeads,
  getCustomerLeadsCount,
  getFavLeads,
  getLastTwoDaysExclusiveLeads,
  getLeadCount,
  getLeadDetails,
  getLeads,
  getMarketPlaceLeads,
  manualUploadLead,
  postCustomerExclusiveLeads,
  postCustomerFavPremiumLeads,
  uploadBulkLead,
} from "./leads/leads.api";
import { signupUser } from "./auth/signup";
import {
  createPaymentCheckout,
  updatewallet,
  createSubscription,
  cancelSubscription,
  createPayment,
  createCustomer,
  createSubscriptionPaymentCheckout,
  cancelOfferClaim,
  getDiscountPrice,
} from "./payment/payment.api";
import {
  getWalletDiscountPrice,
  getWalletTransaction,
  updateBalanceByAdmin,
} from "./wallet/wallet.api";
import {
  createLeadBucket,
  createMultipleExclusiveLeadBucket,
  getCustomerCartAmmount,
  purchaseLeadFromBucket,
} from "./leadBucket/leadBucket";
import {
  premiumLeadsBulkUpload,
  getExclusiveLeadDetails,
  getExclusiveLeads,
  getLastTwoDaysPremiumLeads,
  manualUploadExclusiveLeads,
} from "./exclusiveLeads/exclusiveLeads.api";
import {
  generalLeadsBulkUpload,
  getAllGeneralLeads,
  manualUploadGeneralLeads,
} from "./generalLeads/GeneralLeads.api";
import {
  createExclusiveLeadBucket,
  createMultiplePremiumLeadBucket,
} from "./exclusiveLeadBucket/exclusiveLeadBucket";
import { getDashboardData } from "./dashboard/getDashboardData";
import {
  createSubscriptionPlan,
  customerPurchasePlan,
  getAllSubscriptionPlan,
  getActivatedPlanById,
  updateSubscriptionPlan,
  getPlanById,
} from "./subscription/subscription";
import { createInfluencer, getInfluencers } from "./influencer/addInfluencer";
import { get } from "http";
import { updatePremiumLeadsLimit } from "./premiumLeads/premiumLeads";
import { changePassword } from "./auth/forgetPassword";
import { verifyUser } from "./auth/verifyToken";
import { Announcement } from "@mui/icons-material";
import {
  createAnnouncement,
  getAnnouncements,
} from "./announcement/announcement.api";

export const api = {
  customer: {
    getCustomer,
    getCustomerDetails,
    getCustomerDetailsByEmail,
    getCustomerPaymentMethod,
    getCustomerSubscriptionDetails,
    updateCustomerPhoto,
  },
  customers: {
    createLeadBucket,
    purchaseLeadFromBucket,
    createExclusiveLeadBucket,
    createMultiplePremiumLeadBucket,
    createMultipleExclusiveLeadBucket,
    createCustomerCart,
    getCustomerCartLeads,
    getCustomerCartAmmount,
    deleteLeadsFromCart,
  },
  builder: {
    getBuilder,
    addBuilder,
    getBuilderList,
    getBuilderByID,
  },
  dashboard: {
    getDashboardData,
  },
  admin: {
    createAdmin,
    adminAssignProject,
    getEmployeeList,
    getUsers,
    getAdminDetails,
  },
  auth: {
    login,
    getOtp,
    signupUser,
    changePassword,
    verifyUser,
  },
  payment: {
    createPaymentCheckout,
    createSubscriptionPaymentCheckout,
    updatewallet,
    createSubscription,
    createPayment,
    cancelSubscription,
    cancelOfferClaim,
    createCustomer,
    getDiscountPrice,
  },
  wallet: {
    getWalletTransaction,
    updateBalanceByAdmin,
    getWalletDiscountPrice,
  },
  permission: {
    getBuilderList: getBuilderList,
  },
  followUp: {
    createFollowUp,
    getFollowUp,
  },
  project: {
    addPlot,
    getPlots,
    getAdminPlots,
    getProjectDetails,
    getAllProjects,
    getAssignedProjectList,
    updatePlotPostion,
    getProjectList,
    addProjectFacility,
    getProjectFacilities,
    deleteProjectFacility,
    updateProject,
    getAdminAndBuilder,
  },
  booking: {
    getBooking,
    forwardEnqury,
    getForwardEnquryList,
    updateEnquiryStatus,
    getBookingDetails,
    getEnquiryDetails,
  },
  banner: {
    createBanner: addBanner,
  },
  bank: {
    addBank,
    getBankContacts,
  },
  resellerEnquiry: {
    getResellerEnquiry,
  },
  upcomingProjects: {
    getUpcomingProjects,
    addUpcomingProject,
  },
  leads: {
    uploadBulkLead,
    getLeads,
    getMarketPlaceLeads,
    premiumLeadsBulkUpload,
    manualUploadLead,
    getLeadDetails,
    getCustomerLeads,
    manualUploadExclusiveLeads,
    getExclusiveLeads,
    generalLeadsBulkUpload,
    manualUploadGeneralLeads,
    getAllGeneralLeads,
    getCustomerExclusiveLeads,
    postCustomerExclusiveLeads,
    getExclusiveLeadDetails,
    postCustomerFavPremiumLeads,
    getCustomerFavPremiumLeads,
    getCustomerLeadsCount,
    getFavLeads,
    getLeadCount,
    deletePremiumLeadBucket,
    deleteExclusiveLeadBucket,
    getLastTwoDaysExclusiveLeads,
    getLastTwoDaysPremiumLeads,
    updatePremiumLeadsLimit,
    deleteExclusiveLead,
    deletePremiumLeads,
  },
  plan: {
    createSubscriptionPlan,
    getAllSubscriptionPlan,
    customerPurchasePlan,
    getExclusiveLeadDetails,
    getActivatedPlanById,
    updateSubscriptionPlan,
    getPlanById,
  },
  influencer: {
    createInfluencer,
    getInfluencers,
  },
  announcement: {
    createAnnouncement,
    getAnnouncements,
  },
};
