import { request } from "../api";
import { MESSAGE } from "../../../constants/api/message";
import { Params, Payload } from "../../../@types/api/api.types";
import { headers } from "../../../config/config";
import { AUTHORIZATION } from "../../../constants/api/auth";

const { post, del } = request;

const { Authorization, Bearer } = AUTHORIZATION;

const initialRoute = "leads";

export const uploadBulkLead = async (payload: Payload) => {
  try {
    const endpoint = `${initialRoute}/bulk-upload`;
    const response = await post(endpoint, payload, {
      ...headers,
      "Content-Type": "multipart/form-data",
    });

    if (response?.status === 200) {
      const { message, result } = response.data;
      if (message === MESSAGE.post.succ) {
        return result; // Return the result
      }
    }
    throw new Error();
  } catch (error: any) {
    console.error("Error while login:", error);
    throw error;
  }
};

export const getLeads = async (params: Params) => {
  try {
    const endpoint = `${initialRoute}/get-leads`;
    const response = await request.get(
      endpoint,
      {
        ...headers,
      },
      params
    );

    if (response?.status === 200) {
      const { message, result, pagination } = response.data;
      if (message === MESSAGE.get.succ) {
        return { result, pagination }; // Return the result
      }
    }
    throw new Error();
  } catch (error: any) {
    console.error("Error while fetching leads:", error);
    throw error;
  }
};

export const getFavLeads = async (params: Params) => {
  try {
    const endpoint = `${initialRoute}/get-fav-premium-leads`;
    const response = await request.get(
      endpoint,
      {
        ...headers,
      },
      params
    );

    if (response?.status === 200) {
      const { message, result, pagination } = response.data;
      if (message === MESSAGE.get.succ) {
        return { result, pagination }; // Return the result
      }
    }
    throw new Error();
  } catch (error: any) {
    console.error("Error while fetching leads:", error);
    throw error;
  }
};

export const getLeadCount = async (params: Params) => {
  try {
    const endpoint = `${initialRoute}/get-leads-count`;
    const response = await request.get(
      endpoint,
      {
        ...headers,
      },
      params
    );

    if (response?.status === 200) {
      const { message, result } = response.data;
      if (message === MESSAGE.get.succ) {
        return result; // Return the result
      }
    }
    throw new Error();
  } catch (error: any) {
    console.error("Error while fetching leads:", error);
    throw error;
  }
};

export const getMarketPlaceLeads = async (params: Params) => {
  try {
    const endpoint = `${initialRoute}/get-marketplace-leads`;
    const token = localStorage.getItem("@token");
    const response = await request.get(
      endpoint,
      {
        ...headers,
        [Authorization]: `${Bearer} ${token}`,
      },
      params
    );

    if (response?.status === 200) {
      const { message, result, pagination } = response.data;
      if (message === MESSAGE.get.succ) {
        return { result, pagination }; // Return the result
      }
    }
    throw new Error();
  } catch (error: any) {
    console.error("Error while fetching leads:", error);
    throw error;
  }
};

export const getLastTwoDaysExclusiveLeads = async (params: Params) => {
  try {
    const endpoint = `${initialRoute}/get-new-exclusive-leads`;
    const response = await request.get(
      endpoint,
      {
        ...headers,
      },
      params
    );

    if (response?.status === 200) {
      const { message, result, pagination } = response.data;
      if (message === MESSAGE.get.succ) {
        return { result, pagination }; // Return the result
      }
    }
    throw new Error();
  } catch (error: any) {
    console.error("Error while fetching leads:", error);
    throw error;
  }
};

export const manualUploadLead = async (payload: Payload) => {
  try {
    const endpoint = `${initialRoute}/addmanual-lead`;
    const response = await post(endpoint, payload, {
      ...headers,
    });

    if (response) {
      const {
        data: { message },
      } = response;
      if (message === MESSAGE.post.succ) {
        const {
          data: { result },
        } = response;
        return result;
      }
    }
    throw new Error();
  } catch (error: any) {
    console.log(error);
    throw error;
  }
};

export const getLeadDetails = async (filter: Params) => {
  try {
    const endpoint = `${initialRoute}/get-lead-detail`;
    const response = await request.get(
      endpoint,
      {
        ...headers,
      },
      filter
    );

    if (response?.status === 200) {
      const { message, result } = response.data;
      if (message === MESSAGE.get.succ) {
        return result; // Return the result
      }
    }
    throw new Error();
  } catch (error: any) {
    console.error("Error while fetching leads:", error);
    throw error;
  }
};

export const getCustomerLeads = async (params: Params) => {
  try {
    const endpoint = `${initialRoute}/get-customer-leads`;
    const response = await request.get(
      endpoint,
      {
        ...headers,
      },
      params
    );

    if (response?.status === 200) {
      const { message, result, pagination } = response.data;
      if (message === MESSAGE.get.succ) {
        return { result, pagination }; // Return the result
      }
    }
    throw new Error();
  } catch (error: any) {
    console.error("Error while fetching leads:", error);
    throw error;
  }
};

export const getCustomerExclusiveLeads = async (params: Params) => {
  try {
    const endpoint = `${initialRoute}/get-customer-exclusive-leads`;
    const response = await request.get(
      endpoint,
      {
        ...headers,
      },
      params
    );

    if (response?.status === 200) {
      const { message, result, pagination } = response.data;
      if (message === MESSAGE.get.succ) {
        return { result, pagination }; // Return the result
      }
    }
    throw new Error();
  } catch (error: any) {
    console.error("Error while fetching leads:", error);
    throw error;
  }
};
export const postCustomerExclusiveLeads = async (payload: Payload) => {
  try {
    const endpoint = `${initialRoute}/create-customer-exclusive-leads`;
    const response = await post(endpoint, payload, {
      ...headers,
    });

    if (response) {
      const {
        data: { message },
      } = response;
      if (message === MESSAGE.post.succ) {
        const {
          data: { result },
        } = response;
        return result;
      }
    }
    throw new Error();
  } catch (error: any) {
    console.log(error);
    throw error;
  }
};

export const postCustomerFavPremiumLeads = async (payload: Payload) => {
  try {
    const endpoint = `${initialRoute}/create-fav-premium-leads`;
    const response = await post(endpoint, payload, {
      ...headers,
    });

    if (response) {
      const {
        data: { message },
      } = response;
      if (message === MESSAGE.post.succ) {
        const {
          data: { result },
        } = response;
        return result;
      }
    }
    throw new Error();
  } catch (error: any) {
    console.log(error);
    throw error;
  }
};

export const getCustomerFavPremiumLeads = async (params: Params) => {
  try {
    const endpoint = `${initialRoute}/get-fav-premium-leads`;
    const response = await request.get(
      endpoint,
      {
        ...headers,
      },
      params
    );

    if (response?.status === 200) {
      const { message, result, pagination } = response.data;
      if (message === MESSAGE.get.succ) {
        return { result, pagination }; // Return the result
      }
    }
    throw new Error();
  } catch (error: any) {
    console.error("Error while fetching leads:", error);
    throw error;
  }
};

export const getCustomerLeadsCount = async (params: Params) => {
  try {
    const endpoint = `${initialRoute}/get-leads-count`;
    const response = await request.get(
      endpoint,
      {
        ...headers,
      },
      params
    );

    if (response?.status === 200) {
      const { message, result, pagination } = response.data;
      if (message === MESSAGE.get.succ) {
        return { result, pagination }; // Return the result
      }
    }
    throw new Error();
  } catch (error: any) {
    console.error("Error while fetching leads:", error);
    throw error;
  }
};

export const deletePremiumLeadBucket = async (
  customer_object_id: string,
  premium_lead_object_id: string
) => {
  try {
    const endpoint = `${initialRoute}/delete-customer-premium-leads/${customer_object_id}/${premium_lead_object_id}`;
    const response = await del(endpoint, {
      ...headers,
    });

    if (response?.status === 200) {
      const { message, result } = response.data;

      if (message === MESSAGE.delete.succ) {
        return result; // Return the result
      } else {
        throw new Error("Delete failed");
      }
    }
  } catch (error: any) {
    console.error("Error while login:", error.message);
  }
};

export const deleteExclusiveLeadBucket = async (
  customer_object_id: string,
  lead_object_id: string
) => {
  try {
    const endpoint = `${initialRoute}/delete-customer-exclusive-leads/${customer_object_id}/${lead_object_id}`;
    const response = await del(endpoint, {
      ...headers,
    });

    if (response?.status === 200) {
      const { message, result } = response.data;

      if (message === MESSAGE.delete.succ) {
        return result; // Return the result
      } else {
        throw new Error("Delete failed");
      }
    }
  } catch (error: any) {
    console.error("Error while login:", error.message);
  }
};
export const deleteExclusiveLead = async (params: Params) => {
  try {
    if (!params.exclusive_lead_id) {
      throw new Error("Lead ID is required");
    }

    // Include the lead ID in the query string
    const endpoint = `${initialRoute}/delete-exclusive-leads?exclusive_lead_id=${params.exclusive_lead_id}`;

    const response = await del(endpoint, {
      ...headers,
    });

    if (response?.status === 200) {
      const { message, result } = response.data;

      if (message === MESSAGE.delete.succ) {
        return result; // Return the result
      } else {
        throw new Error("Delete failed");
      }
    }
  } catch (error: any) {
    console.error("Error while deleting lead:", error.message);
  }
};

export const deletePremiumLeads = async (params: Params) => {
  try {
    if (!params.premium_lead_object_id) {
      throw new Error("Lead ID is required");
    }

    // Include the lead ID in the query string
    const endpoint = `${initialRoute}/delete-premium-leads?premium_lead_object_id=${params.premium_lead_object_id}`;

    const response = await del(endpoint, {
      ...headers,
    });

    if (response?.status === 200) {
      const { message, result } = response.data;

      if (message === MESSAGE.delete.succ) {
        return result; // Return the result
      } else {
        throw new Error("Delete failed");
      }
    }
  } catch (error: any) {
    console.error("Error while deleting lead:", error.message);
  }
};
