import { newleeyBlueBrandLogo } from "../../../assets";
import "./clientBrandLogo.css";

export const ClientBrandLogo = (): JSX.Element => {
	return (
		<div className="brand-logo-container">
			<div className="client-brand-logo-container">
				<img src={newleeyBlueBrandLogo} alt="newleey-blue-brand-logo" />
			</div>
		</div>
	);
};
