import React, { useCallback, useEffect, useState } from "react";
import { Button, TextField, Paper, IconButton, Checkbox, FormControlLabel, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { api } from "../../../utils/api";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useNavigate } from "react-router-dom";
import { IPlan } from "../../../@types/interface/plan.interface";
import { BILLING_MODES } from "../../../constants/billingMode/BillingMode";
import { BillingType } from "../../../@types/types/billing/billing.type";

const AddPlans = () => {
	const navigate = useNavigate();

	const [planDetails, setPlanDetails] = useState<Partial<IPlan>>({
		price: 0,
		plan_name: "",
		plan_details: "",
		is_recommended: false,
		exclusive_leads_limit: 0,
		premium_leads_limit: 0,
		plan_occurance: BILLING_MODES.monthly as unknown as BillingType,
		sequence: 0,
		benefits: [{ benifit: "", is_active: false }]
	});

	// Handle input change for basic plan fields
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		setPlanDetails((prevDetails) => ({
			...prevDetails,
			[name]: value
		}));
	};

	// Handle input change for individual benefit
	const handleBenefitChange = (index: number, field: string, value: any) => {
		const updatedBenefits = [...(planDetails.benefits || [])];
		updatedBenefits[index] = { ...updatedBenefits[index], [field]: value };
		setPlanDetails((prevDetails) => ({
			...prevDetails,
			benefits: updatedBenefits
		}));
	};

	// Add a new benefit
	const addBenefit = () => {
		setPlanDetails((prevDetails) => ({
			...prevDetails,
			benefits: [...(prevDetails.benefits || []), { benifit: "", is_active: false }] // Fixed
		}));
	};

	// Remove an existing benefit
	const removeBenefit = (index: number) => {
		const updatedBenefits = (planDetails.benefits || []).filter((_, i) => i !== index); // Fixed
		setPlanDetails((prevDetails) => ({
			...prevDetails,
			benefits: updatedBenefits // Fixed
		}));
	};

	// Handle form submission
	const handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault();

		// Ensure required fields are filled
		if (!planDetails.plan_name || !planDetails.price || !planDetails.plan_details) {
			alert("Please fill out all required fields.");
			return;
		}

		// Log the payload (replace with API call)
		console.log("Payload Submitted:", planDetails);

		try {
			const response = await api.plan.createSubscriptionPlan(planDetails);
			if (response) {
				alert("Plan submitted successfully!");
				navigate("/super-admin/plans");
			}
		} catch (error) {
			console.error("Error submitting plan:", error);
			alert("Failed to submit plan.");
		}
	};

	const addBenifitAuto = useCallback(() => {
		setPlanDetails((prevDetails) => {
			const newBenefits = [...(prevDetails.benefits || [])];
			const { exclusive_leads_limit = 0, premium_leads_limit = 0 } = planDetails;
			if (exclusive_leads_limit > 0) {
				newBenefits.push({ benifit: `${exclusive_leads_limit} Exclusive Leads`, is_active: true });
			}
			if (premium_leads_limit > 0) {
				newBenefits.push({ benifit: `${premium_leads_limit} Premium Leads`, is_active: true });
			}
			return {
				...prevDetails,
				benefits: newBenefits
			};
		});
	}, [planDetails.exclusive_leads_limit, planDetails.premium_leads_limit]);

	console.log("==>planDetails", planDetails);

	return (
		<form onSubmit={handleSubmit}>
			<div>
				<Typography variant="h6" gutterBottom>
					Add Plan Details
				</Typography>
				<Paper className="form-container" variant="outlined" sx={{ marginTop: "20px", padding: "20px" }}>
					{/* Plan Name */}
					<div className="flex-input-field">
						<label>Plan Name:</label>
						<TextField
							type="text"
							variant="outlined"
							className="inputbox"
							onChange={handleChange}
							name="plan_name"
							value={planDetails.plan_name}
							required
						/>
					</div>

					{/* Plan Occurrence */}
					<div className="flex-input-field">
						<label>Plan Occurrence:</label>
						<TextField
							select
							variant="outlined"
							className="inputbox"
							onChange={handleChange}
							name="plan_occurance"
							value={planDetails.plan_occurance}
							SelectProps={{
								native: true
							}}
							required
						>
							{Object.keys(BILLING_MODES).map((key) => (
								<option key={key} value={BILLING_MODES[key as keyof typeof BILLING_MODES]}>
									{key.charAt(0).toUpperCase() + key.slice(1)}
								</option>
							))}
						</TextField>
					</div>

					{/* Price */}
					<div className="flex-input-field">
						<label>Price (USD):</label>
						<TextField
							type="number"
							variant="outlined"
							className="inputbox"
							onChange={handleChange}
							name="price"
							value={planDetails.price}
							required
						/>
					</div>

					<div className="flex-input-field">
						<label>Position to Show:</label>
						<TextField
							type="number"
							variant="outlined"
							className="inputbox"
							onChange={handleChange}
							name="sequence"
							value={planDetails.sequence}
							required
						/>
					</div>

					<div className="flex-input-field">
						<label>Premium Leads Limit:</label>
						<TextField
							type="number"
							variant="outlined"
							className="inputbox"
							onChange={handleChange}
							name="premium_leads_limit"
							value={planDetails.premium_leads_limit}
							required
						/>
					</div>
					<div className="flex-input-field">
						<label>Exclusive Leads Limit:</label>
						<TextField
							type="number"
							variant="outlined"
							className="inputbox"
							onChange={handleChange}
							name="exclusive_leads_limit"
							value={planDetails.exclusive_leads_limit}
							required
						/>
					</div>

					{/* Plan Details */}
					<div className="flex-input-field">
						<label>Plan Details:</label>
						<TextField
							type="text"
							variant="outlined"
							className="inputbox"
							onChange={handleChange}
							name="plan_details"
							value={planDetails.plan_details}
							multiline
							rows={4}
							required
						/>
					</div>

					{/* Benefits */}
					<div className="flex-input-field">
						<label>Benefits:</label>
						<div style={{ width: "46%" }}>
							{(planDetails.benefits || []).map((benefit, index) => (
								<div
									key={index}
									style={{
										display: "flex",
										alignItems: "center",
										marginBottom: "10px"
									}}
								>
									<TextField
										type="text"
										variant="outlined"
										value={benefit.benifit}
										onChange={(e) => handleBenefitChange(index, "benifit", e.target.value)}
										placeholder={`Benefit ${index + 1}`}
										style={{ flex: 1, marginRight: "10px" }}
										required
									/>
									<FormControlLabel
										control={
											<Checkbox
												checked={benefit.is_active}
												onChange={(e) =>
													handleBenefitChange(index, "is_active", e.target.checked)
												}
											/>
										}
										label="Active"
									/>
									<IconButton
										onClick={() => removeBenefit(index)}
										color="error"
										aria-label="remove benefit"
									>
										<DeleteForeverIcon />
									</IconButton>
								</div>
							))}
							<Button
								variant="contained"
								color="primary"
								onClick={addBenefit}
								startIcon={<AddIcon />}
								style={{ marginTop: "10px" }}
							>
								Add Benefit
							</Button>
						</div>
					</div>
				</Paper>

				{/* Submit Button */}
				<div className="button-container" style={{ marginTop: "20px" }}>
					<Button variant="contained" type="submit" className="button-violet">
						Submit
					</Button>
				</div>
			</div>
		</form>
	);
};

export default AddPlans;
