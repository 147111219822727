import { Box, Modal } from "@mui/material";
import { ICommonModalProps } from "../../../@types/props/commonModal.props copy";

const CommonModal = ({ children, handleClose, open, width, height }: ICommonModalProps) => {
	const style = {
		position: "absolute" as const,
		top: "50%",
		left: "50%",
		border: "none",
		transform: "translate(-50%, -50%)",
		width: width || "95%",
		maxWidth: 400,
		height: height || "auto",
	};
	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={style}>{children ? children : <div>Empty Modal</div>}</Box>
		</Modal>
	);
};

export default CommonModal;
