import { useState } from "react";

const useModalOpen = () => {
	const [modalOpen, setModalOpen] = useState<boolean>(false);
	const handleOpenModal = () => {
		setModalOpen(true);
	};
	const handleCloseModal = () => {
		setModalOpen(false);
	};
	return {
		modalOpen,
		handleOpenModal,
		handleCloseModal
	};
};

export default useModalOpen;
