import { useState, useEffect, useRef } from "react";
import { FiDollarSign } from "react-icons/fi";

const PriceRangeSlider = ({ onPriceRangeChange }: any) => {
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(500);
  const [draggedThumb, setDraggedThumb] = useState<"min" | "max" | null>(null);
  const sliderRef = useRef<HTMLDivElement>(null);

  const handleMinPriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (minPrice >= 0 && minPrice <= maxPrice) {
      setMinPrice(value ? parseInt(value, 10) : 0);
    }
  };

  const handleMaxPriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (maxPrice >= 0) {
      setMaxPrice(value ? parseInt(value, 10) : 0);
    }
  };

  useEffect(() => {
    const handleMouseMove = (e: any) => {
      if (draggedThumb !== null && sliderRef.current) {
        const rect = sliderRef.current.getBoundingClientRect();
        const percentage = Math.min(
          Math.max((e.clientX - rect.left) / rect.width, 0),
          1
        );
        const newPrice = Math.round(percentage * 500);

        if (draggedThumb === "min") {
          setMinPrice(Math.min(newPrice, maxPrice - 10));
        } else {
          setMaxPrice(Math.max(newPrice, minPrice + 10));
        }
      }
    };

    const handleMouseUp = () => {
      setDraggedThumb(null);
      // Call the onPriceRangeChange when mouse is released
      onPriceRangeChange(minPrice, maxPrice);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [draggedThumb, minPrice, maxPrice, onPriceRangeChange]);

  const handleKeyDown = (e: any, thumb: string) => {
    const step = 10;
    if (e.key === "ArrowLeft") {
      if (thumb === "min") {
        setMinPrice((prev) => Math.max(prev - step, 0));
      } else {
        setMaxPrice((prev) => Math.max(prev - step, minPrice + step));
      }
    } else if (e.key === "ArrowRight") {
      if (thumb === "min") {
        setMinPrice((prev) => Math.min(prev + step, maxPrice - step));
      } else {
        setMaxPrice((prev) => Math.min(prev + step, 500));
      }
    }
  };

  return (
    <div className="flex flex-col space-y-4">
      <div className="flex justify-between items-end md:items-center">
        <div className="w-20">
          <label htmlFor="minPrice" className="text-gray-900 text-sm font-medium">Min</label>
          <input type="text" id="minPrice" value={minPrice} onChange={handleMinPriceChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5" placeholder="Min Price" required />
        </div>
        <p>
          Bidding Price
        </p>
        <div className="w-20">
          <label htmlFor="maxPrice" className="text-gray-900 text-sm font-medium">Max</label>
          <input type="text" id="maxPrice" value={maxPrice} onChange={handleMaxPriceChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5" placeholder="Max Price" required />
        </div>
      </div>
      <div
        ref={sliderRef}
        className="relative w-full h-2 bg-gray-200 rounded-full"
        role="slider"
        aria-valuemin={0}
        aria-valuemax={500}
        aria-valuenow={minPrice}
        aria-label="Price range slider"
      >
        <div
          className="absolute h-full bg-blue-500 rounded-full"
          style={{
            left: `${(minPrice / 500) * 100}%`,
            right: `${100 - (maxPrice / 500) * 100}%`,
          }}
        ></div>
        <button
          className="absolute top-1/2 -mt-3 -ml-3 w-6 h-6 bg-white border-2 border-blue-500 rounded-full shadow-md focus:outline-none focus:ring-2 focus:ring-blue-400 hover:bg-blue-50 transition-colors duration-200"
          style={{ left: `${(minPrice / 500) * 100}%` }}
          onMouseDown={() => setDraggedThumb("min")}
          onKeyDown={(e) => handleKeyDown(e, "min")}
          aria-label={`Minimum price ${minPrice}`}
          tabIndex={0}
        ></button>
        <button
          className="absolute top-1/2 -mt-3 -ml-3 w-6 h-6 bg-white border-2 border-blue-500 rounded-full shadow-md focus:outline-none focus:ring-2 focus:ring-blue-400 hover:bg-blue-50 transition-colors duration-200"
          style={{ left: `${(maxPrice / 500) * 100}%` }}
          onMouseDown={() => setDraggedThumb("max")}
          onKeyDown={(e) => handleKeyDown(e, "max")}
          aria-label={`Maximum price ${maxPrice}`}
          tabIndex={0}
        ></button>
      </div>
    </div>
  );
};

export default PriceRangeSlider;
