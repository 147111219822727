import { Box } from "@mui/material";
import { IPremiumLeadsCount } from "../../../../../@types/interface/premiumLeadsCount.interface";

const LeadsCount = ({ count }: { count: IPremiumLeadsCount }) => {
	return (
		<Box className="leads-stats">
			<span>Total Leads: {count.totalLeads}</span>
			<span>Today Leads: {count.todayLeads}</span>
		</Box>
	);
};

export default LeadsCount;
