import React, { useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { STRIPE_PUBLIC_KEY } from "../../../config/config";
import AddLeadsModal from "../addLeadsModal/AddLeadsModal";
import { AddLeadsModalProps } from "../../../@types/props/addLeadsModal.props";
import Confirmleads from "../addLeadsModal/confirmleads/Confirmleads";

const stripePromise = loadStripe(
	STRIPE_PUBLIC_KEY
);

const AddLeadsCardWrapper: React.FC<AddLeadsModalProps> = (props) => {
	const [defaultLeads, setDefaultLeads] = useState<number>(props.no_of_leads);
	const [hasConfirmed, setHasConfirmed] = useState<boolean>(false);

	const handleSetNoOfLeads = (no_of_leads: number) => {
		setDefaultLeads(no_of_leads);
		setHasConfirmed(true);
	}

	return (
		<div>
			{
				hasConfirmed ? (
					<Elements stripe={stripePromise}>
						<AddLeadsModal no_of_leads={defaultLeads} onClose={props.onClose} />
					</Elements>

				) : (
					<Confirmleads no_of_leads={defaultLeads} handleConfirmNoOfLeads={handleSetNoOfLeads} onClose={props.onClose} />
				)
			}
		</div>
	);
};

export default AddLeadsCardWrapper;
