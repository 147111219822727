import { Button, Chip, Paper, styled, TextField } from '@mui/material'
import React, { useState } from 'react'
import { IAnnouncement } from '../../../../@types/interface/announcement.interface';
import { api } from '../../../../utils/api';
import { useNavigate } from 'react-router-dom';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
const VisuallyHiddenInput = styled("input")({
	clip: "rect(0 0 0 0)",
	clipPath: "inset(50%)",
	height: 1,
	overflow: "hidden",
	position: "absolute",
	bottom: 0,
	left: 0,
	whiteSpace: "nowrap",
	width: 1,
});

const AddAnnouncement = () => {
	const navigate = useNavigate();
	const [formData, setFormData] = useState<IAnnouncement>({
		title: "",
		description: "",
		media: "",
		mediaType: "image",
	});
	const [file, setFile] = useState<File | null>(null);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setFormData((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const selectedFile = event.target.files?.[0];
		if (selectedFile) {
			setFile(selectedFile);

			// Determine media type
			const fileType = selectedFile.type.startsWith("image") ? "image" : "video";
			setFormData((prev) => ({
				...prev,
				mediaType: fileType,
			}));
		}
	};

	// Handle form submission
	const handleSubmit = async () => {
		if (!formData.title || !formData.description) {
			alert("Please enter title and description.");
			return;
		}

		if (!file) {
			alert("Please upload an image or video.");
			return;
		}

		try {
			const formPayload = new FormData();
			formPayload.append("title", formData.title);
			formPayload.append("description", formData.description);
			formPayload.append("mediaType", formData.mediaType);
			formPayload.append("media", file);

			// Trigger API call
			const response = await api.announcement.createAnnouncement(formPayload);

			if (response) {
				alert("Announcement added successfully");
				console.log("ADDED__RESPONSE---->", response);
				navigate("/super-admin/announcements");

				// Reset form after successful submission
				setFormData({
					title: "",
					description: "",
					media: "",
					mediaType: "image",
				});
				setFile(null);
			} else {
				throw new Error("API request failed");
			}
		} catch (error) {
			console.error("Error while adding:", error);
		}
	};

	return (
		<>
			<h1 className='text-2xl font-semibold text-gray-600 mb-6'>Enter announcement details</h1>
			<Paper component="form">
				<div className="flex-input-field">
					<label>Announcement Title:</label>
					<TextField
						type="text"
						name="title"
						value={formData.title}
						onChange={handleChange}
						variant="outlined"
						className="inputbox"
						sx={{
							"& .MuiOutlinedInput-root": {
								"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
									borderColor: "#7c00fe",
								},
							},
						}}
					/>
				</div>

				<div className="flex-input-field">
					<label>Description:</label>
					<TextField
						type="text"
						name="description"
						value={formData.description}
						onChange={handleChange}
						variant="outlined"
						className="inputbox"
						sx={{
							"& .MuiOutlinedInput-root": {
								"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
									borderColor: "#7c00fe",
								},
							},
						}}
					/>
				</div>

				<div className="flex-input-field   flex items-center justify-between" >
					<label>Upload image/video:</label>
					<div

					>
						<Button
							component="label"
							variant="contained"
							className='button-violet'
							endIcon={<CloudUploadIcon />}
							style={{ marginTop: 20, backgroundColor: "#49BB43", marginRight: "15px" }}
						>
							Upload image/video
							<VisuallyHiddenInput type="file" accept="image/*,video/*" onChange={handleFileChange} />
						</Button>

						{file && (
							<Chip
								label={file.name}
								onDelete={() => setFile(null)}
								variant="outlined"
								sx={{ marginTop: 1 }}
							/>
						)}
					</div>
				</div>


			</Paper>
			<div className="w-full flex justify-end p-8">
				<Button onClick={handleSubmit} variant="contained" className='button-dark-blue'>
					Submit
				</Button>
			</div>
		</>
	);
};

export default AddAnnouncement;
