import { colors } from "@mui/material";

const colorConfigs = {
  sidebar: {
    bg: "#132A38",
    color: "#EFFFFF",
    hoverBg: "#ccccff",
    activeBg: "#b3b3ff",
    border: " #808080",
  },
  customerSidebar: {
    bg: "hsl(240, 100%, 97%)",
    color: "#132A38",
    hoverBg: "#ccccff",
    activeBg: "#b3b3ff",
    border: " #808080",
  },
  adminSidebar: {
    bg: "#E7F6F2",
    color: "#eeeeee",
    hoverBg: "blue",
    activeBg: "red",
  },
  topbar: {
    bg: "#EFFFFF",
    color: "#000",
  },
  mainBg: colors.grey["100"],
};

export default colorConfigs;
