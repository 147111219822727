import React, { useState } from 'react'
import { IConfirmLeadsModalProps } from '../../../../@types/props/confirmLeadsModal.props'

const Confirmleads: React.FC<IConfirmLeadsModalProps> = ({ no_of_leads, handleConfirmNoOfLeads, onClose }) => {
	const [noOfLeads, setNoOfLeads] = useState<number>(no_of_leads);

	const handleChangeLeads = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		setNoOfLeads(parseInt(value));
	}

	const handleSelectLeads = (no_of_leads: number) => {
		setNoOfLeads(no_of_leads);
	}
	return (
		<div className="bg-white rounded-lg shadow-lg w-full max-w-md p-6">
			<div className="flex justify-between items-center mb-4">
				<h2 className="text-2xl font-bold text-blue-600">Purchase Leads</h2>
				<button
					className="text-gray-400 hover:text-gray-600"
					onClick={onClose}
				>
					✕
				</button>
			</div>
			<div className="flex flex-col items-center space-y-4">
				<div>
					<label htmlFor="leads" className="text-sm font-medium text-gray-800">Number of Leads to Purchase</label>
					<input
						type="number"
						value={noOfLeads}
						onChange={handleChangeLeads}
						className="border border-gray-300 rounded p-2 w-full"
					/>
				</div>
				<div className='mt-3 w-full'>
					<input
						id="leads-range"
						type="range"
						value={noOfLeads}
						onChange={handleChangeLeads}
						className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
						style={{ background: `linear-gradient(to right, #1d4ed8 0%, #3b82f6 ${(noOfLeads / 100) * 100}%, #d1d5db ${(noOfLeads / 100) * 100}%, #d1d5db 100%)` }}
					/>
				</div>
				<button
					onClick={handleConfirmNoOfLeads.bind(this, noOfLeads)}
					className="bg-blue-600 text-white rounded p-2 w-1/2 hover:bg-blue-700"
				>
					Next
				</button>
			</div>
		</div>
	)
}

export default Confirmleads