import { useContext, useState } from "react";
import { api } from "../../api";
import CustomerContext from "../../../contexts/customerContext/customerContext";

const useCancelSubscription = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { user, setUser } = useContext(CustomerContext);

  const handleCancelSubscription = async () => {
    try {
      setLoading(true);
      const payload = {
        customer_object_id: user?._id,
      };
      const response = await api.payment.cancelSubscription(payload);
      if (response) {
        setUser(response);
        return response;
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return { handleCancelSubscription, loading };
};

export default useCancelSubscription;
