import { ListItemButton, ListItemIcon } from "@mui/material";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import colorConfigs from "../../../../config/colorConfigs";
import { RootState } from "../../../../redux/store";
import { RouteType } from "../../../../routes/config";

type Props = {
	item: RouteType;
};

const SidebarItem = ({ item }: Props) => {
	const { appState } = useSelector((state: RootState) => state.appState);

	return item.sidebarProps && item.path ? (
		<ListItemButton
			component={Link}
			to={item.path}
			sx={{
				backgroundColor: appState === item.state ? colorConfigs.sidebar.activeBg : "unset",
				"&: hover": {
					backgroundColor: colorConfigs.sidebar.hoverBg
				},
				paddingY: "12px",
				paddingX: "24px"
			}}
		>
			<ListItemIcon
				sx={{
					color: colorConfigs.sidebar.color
				}}
			>
				{item.sidebarProps.icon}
			</ListItemIcon>
			{item.sidebarProps.displayText}
		</ListItemButton>
	) : null;
};

export default SidebarItem;
