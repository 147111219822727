import { ColDef } from "ag-grid-community";
// import LeadCellRenderer from "../../components/pages/leads/leadCellRenderer/LeadCellRenderer";
import ExclusiveLeadCellRenderer from "../premiumLeadCellRenderer/PremiumLeadCellRenderer";
import FormattedDateCellRenderer from "./dateCellRenderer/FormattedDateCellRenderer";
import PremiumLeadsDeleteCellRenderer from "../premiumLeadsDeleteCellRenderer/PremiumLeadsDeleteCellRenderer";
export const PremiumLeadColDefs: ColDef[] = [
  {
    field: "lead_name",
    headerName: "Name",
    suppressSizeToFit: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
  },
  {
    field: "lead_email",
    headerName: "Email",
    suppressSizeToFit: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
  },

  {
    field: "lead_phone",
    headerName: "Phone No.",
    suppressSizeToFit: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
  },
  {
    field: "lead_purchased_left",
    headerName: "Purchased Left",
    suppressSizeToFit: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
  },
  {
    field: "selling_timeline",
    headerName: "Selling Timeline",
    suppressSizeToFit: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
  },
  {
    field: "createdAt",
    headerName: "Date",
    cellRenderer: FormattedDateCellRenderer,
    suppressSizeToFit: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
  },
  {
    field: "action",
    headerName: "Action",
    cellRenderer: ExclusiveLeadCellRenderer,
    suppressSizeToFit: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
  },
  {
    field: "delete",
    headerName: "Delete",
    cellRenderer: PremiumLeadsDeleteCellRenderer,
    suppressSizeToFit: true,
  },
];
