import { request } from "../api";
import { headers } from "../../../config/config";
import { MESSAGE } from "../../../constants/api/message";

const { get } = request;

const initialRoute = "project";

export const getAdminAndBuilder = async () => {
	try {
		const endpoint = `${initialRoute}/get-adminsandbuilder`;
		const response = await get(endpoint, {
			...headers
		});

		console.log("API Response:", response);

		if (response && response.data) {
			const { message, result } = response.data;
			if (message === MESSAGE.patch.succ) {
				return result;
			} else {
				throw new Error(`Unexpected message: ${message}`);
			}
		} else {
			throw new Error("No response data");
		}
	} catch (error: any) {
		console.error("Error fetching admin and builder data:", error);
		throw error;
	}
};
