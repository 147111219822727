import React, { useContext, useEffect, useState } from "react";
import UIContext from "../../../contexts/uiContext/UIContext";
import AnnouncementCard from "../../shared/announcementCard/AnnouncementCard";
import { Button } from "@mui/material";
import CampaignIcon from "@mui/icons-material/Campaign";
import { useNavigate } from "react-router-dom";
import { IAnnouncement } from "../../../@types/interface/announcement.interface";
import { api } from "../../../utils/api";

const Announcement = () => {
	const navigate = useNavigate();
	const { setDashboardHeader } = useContext(UIContext);
	const [announcements, setAnnouncements] = useState<IAnnouncement[]>([]);
	const [loading, setLoading] = useState<boolean>(true); // Loading state

	const handleNavigateToAddAnnouncement = () => {
		navigate("/super-admin/add-announcement");
	};

	// Fetch announcements
	const fetchAnnouncements = async () => {
		try {
			setLoading(true);
			const response = await api.announcement.getAnnouncements({});
			console.log("API Response:", response);
			setAnnouncements(response);
			// Ensure response.result is an array before updating state
			// if (Array.isArray(response.result)) {
			// 	setAnnouncements(response.result);
			// 	console.log("Announcements:", response.result);
			// } else {
			// 	console.error("Unexpected API response format:", response);
			// }
		} catch (err) {
			console.error("Error fetching announcements:", err);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchAnnouncements();
	}, []);

	useEffect(() => {
		setDashboardHeader("Announcement");
	}, [setDashboardHeader]);

	return (
		<>
			<div className="w-full flex justify-end items-end mb-4">
				<Button className="button-violet" endIcon={<CampaignIcon />} onClick={handleNavigateToAddAnnouncement}>
					Add Announcement
				</Button>
			</div>


			<div className="w-full flex flex-col gap-4  p-4">
				{loading ? (
					<p>Loading announcements...</p>
				) : announcements.length > 0 ? (
					announcements.map((announcement) => (
						<AnnouncementCard
							key={announcement._id}
							title={announcement.title || "No title"}
							description={announcement.description || "No description"}
							mediaUrl={announcement.media || ""}
							mediaType={announcement.mediaType || "image"}
						/>
					))
				) : (
					<p>No announcements available.</p>
				)}
			</div>
		</>
	);
};

export default Announcement;
