import { useContext, useState } from "react"
import CustomerContext from "../../../contexts/customerContext/customerContext"
import { api } from "../../api";

const useUpdateCustomerPhoto = () => {
	const { user, setUser } = useContext(CustomerContext);
	const [loading, setLoading] = useState<boolean>(false);

	const handleChangeCustomerPhoto =
		async (event: React.ChangeEvent<HTMLInputElement>) => {
			console.log("===> file");
			const file = event.target.files?.[0];

			if (!file || !user?._id) return;
			setLoading(true);
			const formData = new FormData();
			formData.append("photo", file);
			formData.append("customerId", user._id);

			try {
				const customer = await api.customer.updateCustomerPhoto(formData);
				if (customer) {
					setUser(customer);
				}
			} catch (error) {
				console.error("Error updating customer photo:", error);
			} finally {
				setLoading(false);
			}
		}
	return { handleChangeCustomerPhoto, loading }
}

export default useUpdateCustomerPhoto