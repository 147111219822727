import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useCallback, useContext, useEffect, useState } from "react";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import GridOnIcon from "@mui/icons-material/GridOn";
import { FilterModel } from "ag-grid-community";
import { formatFilters } from "../../../../utils/commonFunctions/formatApiFilters";
import UIContext from "../../../../contexts/uiContext/UIContext";
import BasicPagination from "../../../shared/basicPagination/BasicPagination";
import { ILeads } from "../../../../@types/interface/leadDetails.interface";
import { IPagination } from "../../../../@types/interface/pagination";
import { api } from "../../../../utils/api";
import DataGrid from "../../../shared/dataGrid/DataGrid";
import { LeadColDefs } from "../../../../constants/leads/leadsColDefs";
import { MARKETPLACE_LEADS_SHEETS_URL } from "../../../../config/config";

const MarketplaceLeads = () => {
	const navigate = useNavigate();
	const { setDashboardHeader } = useContext(UIContext);
	const [loading, setLoading] = useState<boolean>(false);
	const [rowData, setRowData] = useState<ILeads[]>([]);
	const [filters, setFilters] = useState([]);
	const [pagination, setPagination] = useState<IPagination>({
		currentPage: 1,
		pageCount: 1
	});

	const handleFilterChange = (filterModel: FilterModel) => {
		setFilters((prevFilters: any) => {
			const sanitizedFilters = { ...prevFilters };
			Object.keys(sanitizedFilters).forEach((key: any) => {
				if (!filterModel[key]) {
					delete sanitizedFilters[key];
				}
			});
			const updatedFilters = { ...sanitizedFilters, ...filterModel };
			console.log("Updated Filters-->", updatedFilters);
			return updatedFilters;
		});
	};

	const handlePageChange = useCallback((event: React.ChangeEvent<unknown>, value: number) => {
		setPagination((prevPagination: IPagination) => ({
			...prevPagination,
			currentPage: value
		}));
	}, []);

	const handleLeadBulkUploadClick = useCallback(() => {
		const pathname = "/super-admin/marketplace-leads-bulk-upload";
		navigate(pathname);
	}, [navigate]);

	const handleLeadUpload = () => {
		navigate("/super-admin/add-marketplace-leads");
	};

	const handleOpenGoogleSheet = () => {
		window.open(MARKETPLACE_LEADS_SHEETS_URL, "_blank");
	};

	const getLeadList = useCallback(async () => {
		try {
			const formattedFilter = formatFilters(filters);
			console.log("Formatted filters-->", formattedFilter);
			setLoading(true);

			const filter = {
				...formattedFilter,
				page: pagination.currentPage,
				limit: 30
			};
			const response = await api.leads.getLeads(filter);
			if (response) {
				setRowData(response.result);
				setPagination(response.pagination);
			}
		} catch (error) {
			console.error("Error while fetching data:", error);
		} finally {
			setLoading(false);
		}
	}, [pagination.currentPage, filters]);

	useEffect(() => {
		setDashboardHeader("All Exclusive Leads");
	}, [setDashboardHeader]);

	useEffect(() => {
		getLeadList();
	}, [getLeadList]);

	return (
		<div className="builders-container">
			<div className="add-btn">
				<Button
					component="label"
					className="white-btn"
					variant="outlined"
					endIcon={<GridOnIcon />}
					onClick={handleOpenGoogleSheet}
				>
					Open Google Sheet
				</Button>
				<Button
					className="button-dark-blue"
					endIcon={<AddCircleOutlineOutlinedIcon />}
					onClick={handleLeadUpload}
				>
					Add Manual Lead
				</Button>
				<Button
					component="label"
					// role={undefined}
					variant="contained"
					tabIndex={-1}
					className="button-violet"
					endIcon={<CloudUploadOutlinedIcon />}
					onClick={handleLeadBulkUploadClick}
				>
					Lead Bulk Upload
				</Button>
			</div>
			<DataGrid colDefs={LeadColDefs} rowData={rowData} key={0} onFilterChange={handleFilterChange} />

			<BasicPagination
				currentPage={pagination.currentPage}
				pageCount={pagination.pageCount}
				handlePageChange={handlePageChange}
			/>
			{/* <BasicPagination /> */}
		</div>
	);
};

export default MarketplaceLeads;
