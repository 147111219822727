import React, { useCallback, useEffect, useState } from 'react'
import { api } from '../../api';
import { toast } from 'react-toastify';

const useGetCustomerCartAmmount = () => {
	const [totalAmount, setTotalAmount] = useState<number>(0);
	const getTotal = useCallback(async () => {
		try {
			const response = await api.customers.getCustomerCartAmmount();
			setTotalAmount(response);
		} catch (error) {
			toast.error("Failed to get cart amount")
		}
	}, [])

	useEffect(() => {
		getTotal()
	}, [getTotal]);

	return totalAmount
}

export default useGetCustomerCartAmmount