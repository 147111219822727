import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import ChangeCardModal from "../changeCardModal/ChangeCardModal";
import { ISubScriptionModalProps } from "../../../@types/props/subscriptionModalProps.types";
import { STRIPE_PUBLIC_KEY } from "../../../config/config";

const stripePromise = loadStripe(
	STRIPE_PUBLIC_KEY
);

const SubscriptionCardWrapper: React.FC<ISubScriptionModalProps> = (props) => {
	return (
		<Elements stripe={stripePromise}>
			<ChangeCardModal {...props} />
		</Elements>
	);
};

export default SubscriptionCardWrapper;
