import { useCallback, useContext, useEffect, useState } from "react";
import CustomerContext from "../../../contexts/customerContext/customerContext";
import { api } from "../../api";

const useGetLeadCount = () => {
	const { user } = useContext(CustomerContext);
	const [leadCounts, setLeadCounts] = useState<any>({});
	const getLeadCount = useCallback(async () => {
		try {
			const payload = {
				customer_object_id: user?._id
			};
			const res = await api.leads.getLeadCount(payload);
			setLeadCounts({ ...res, remaining_leads: user?.premium_leads_limit });
			return res;
		} catch (error) {
			console.log("Error ==>", error);
		}
	}, [user]);

	useEffect(() => {
		getLeadCount();
	}, [getLeadCount]);
	return leadCounts;
};

export default useGetLeadCount;
