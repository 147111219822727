
import { useState } from "react";
import { FaSortAmountDownAlt, FaSortAmountUpAlt } from "react-icons/fa";
import PriceRangeSlider from "../../../../shared/priceRange/PraceRange";
import { STATE_LIST } from "../../../../../constants/stateList/StateList";

const FilterSidebar = ({ filters, handleFilterChange, handlePriceRangeChange }: any) => {

  const [selectedOption, setSelectedOption] = useState("lowToHigh"); // Default sorting option

  const property_type_options = [
    {
      value: "Apartment",
      label: "Apartment",
    },
    {
      value: "Condo",
      label: "Condo",
    },
    {
      value: "Multi Family",
      label: "Multi Family",
    },
    {
      value: "Single Family",
      label: "Single Family",
    },
    {
      value: "Mobile Home",
      label: "Mobile Home",
    },
  ];


  return (

    <div className="filter-sidebar justify-between flex flex-wrap gap-2 shadow-lg p-6 rounded-xl w-full">

      <form className="w-full mx-auto flex-1">
        <label htmlFor="countries" className="block mb-2 text-sm font-medium text-gray-900 ">Select State</label>
        <select id="countries" name="state" value={filters.state} onChange={handleFilterChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
          <option selected>All</option>
          {STATE_LIST.map((state) => (
            <option key={state.value} value={state.value}>
              {state.label}
            </option>
          ))}
        </select>
      </form>
      <form className="w-full mx-auto flex-1">
        <label htmlFor="property_type" className="block mb-2 text-sm font-medium text-gray-900 ">Select Property Type</label>
        <select id="property_type" name="property_type" value={filters.property_type} onChange={handleFilterChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
          <option selected>All</option>
          {
            property_type_options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))
          }
        </select>
      </form>
      <div className="w-full flex-1">
        <PriceRangeSlider onPriceRangeChange={handlePriceRangeChange} />
      </div>

      {/* Sort by options */}

      {/* Price Range Slider */}
      {/* <div className="w-full h-full bg-gray-100">
        <PriceRangeSlider onPriceRangeChange={handlePriceRangeChange} />
      </div> */}
    </div>
  );
};

export default FilterSidebar;
