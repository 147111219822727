import { useContext, useState } from "react";
import CustomerContext from "../../../contexts/customerContext/customerContext";
import { useNavigate } from "react-router-dom";
import { api } from "../../api";
import useOpenConfetti from "../openConfetti/useOpenConfetti";

const usePurchaseExclusiveLeads = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const { user } = useContext(CustomerContext);
	const navigate = useNavigate();
	const { handleExplosion } = useOpenConfetti();
	const handleConfirm = async (lead_object_id: string) => {
		try {
			if (!user) {
				alert("Please Login to Continue");
				return;
			}
			setLoading(true);
			const payload = {
				lead_object_id,
				customer_object_id: user?._id
			};
			const response = await api.customers.createLeadBucket(payload);
			if (response) {
				handleExplosion();
				setTimeout(() => {
					navigate("/customer/purchased-leads/exclusive-leads");
				}, 1000);
			} else {
				alert("Something went wrong");
			}
		} catch (error) {
			alert("failed to create lead bucket");
		} finally {
			setLoading(false);
		}
	};
	return { loading, handleConfirm };
};

export default usePurchaseExclusiveLeads;
