import { useCallback, useEffect, useState } from "react";
import { api } from "../../api";
import { IExclusiveLeads } from "../../../@types/interface/exclusiveLeads.interface";
import { IUseGetLeadsProps } from "../../../@types/props/useGetLeads.props";

const useGetPremiumLeads = ({ pagination, setPagination, filter, accept_full_data }: IUseGetLeadsProps) => {
	const [leads, setLeads] = useState<IExclusiveLeads[]>([]);
	if (!pagination) {
		pagination = {
			currentPage: 0,
			pageCount: 0
		};
	}
	const getPremiumLeads = useCallback(async () => {
		try {
			if (!accept_full_data && Object.keys(filter).length === 0) return;
			const filterToSearch = {
				...filter,
				is_purchased: false,
				page: pagination ? pagination.currentPage : 0
			};
			const response = await api.leads.getExclusiveLeads(filterToSearch);
			if (response) {
				setLeads(response.result);
				if (setPagination) {
					setPagination(response.pagination);
				}
			}
		} catch (error) {
			console.error("Error while fetching data:", error);
		}
	}, [filter, pagination.currentPage, setPagination]);

	useEffect(() => {
		getPremiumLeads();
	}, [getPremiumLeads]);

	return leads;
};

export default useGetPremiumLeads;
