import { useCallback, useContext, useEffect, useState } from "react";
import { CustomerColDefs } from "../../../constants/customer/customerColDefs";
import { api } from "../../../utils/api";
import { ICustomer } from "../../../@types/interface/Customer.interface";
import DataGrid from "../../shared/dataGrid/DataGrid";
import UIContext from "../../../contexts/uiContext/UIContext";
import BasicPagination from "../../shared/basicPagination/BasicPagination";
import Download from "../../shared/downloadbtn/Download";
import { IPagination } from "../../../@types/interface/pagination";
import { FilterModel } from "ag-grid-community";
import { formatFilters } from "../../../utils/commonFunctions/formatApiFilters";
import DownloadButton from "../../shared/downloadButton/DownloadButton";
// import DataGrid from "../../shared/dataGrid/DataGrid";

const AllCustomersPage = () => {
	const { setDashboardHeader } = useContext(UIContext);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [filters, setFilters] = useState([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [customerData, setCustomerData] = useState<ICustomer[]>([]);
	const [pagination, setPagination] = useState<IPagination>({
		currentPage: 1,
		pageCount: 1,

	});

	const handleFilterChange = (filterModel: FilterModel) => {
		setFilters((prevFilters: any) => {
			const sanitizedFilters = { ...prevFilters };
			Object.keys(sanitizedFilters).forEach((key: any) => {
				if (!filterModel[key]) {
					delete sanitizedFilters[key];
				}
			});
			const updatedFilters = { ...sanitizedFilters, ...filterModel };
			console.log("Updated Filters-->", updatedFilters);
			return updatedFilters;
		});
	};

	const getCustomerList = useCallback(async () => {
		try {
			const formattedFilter = formatFilters(filters);
			console.log("Formatted filters-->", formattedFilter);
			setLoading(true);

			const filter = {
				...formattedFilter,
				page: pagination.currentPage,
			};
			const response = await api.customer.getCustomer(filter);
			if (response) {
				setCustomerData(response.result);
				setPagination(response.pagination);
			}
		} catch (error) {
			console.error("Error while fetching data:", error);
		} finally {
			setLoading(false);
		}
	}, [pagination.currentPage, filters]);

	const getAllCustomer = useCallback(async () => {
		try {
			const formattedFilter = formatFilters(filters);
			console.log("Formatted filters-->", formattedFilter);
			setLoading(true);

			const filter = {
				...formattedFilter
			};
			const response = await api.customer.getCustomer(filter);
			return response;
		} catch (error) {
			console.error("Error while fetching data:", error);
		} finally {
			setLoading(false);
		}
	}, [filters]);

	// const onDownloadClick = downloadExcel(customerData, "customer");
	const handlePageChange = useCallback((event: React.ChangeEvent<unknown>, value: number) => {
		setPagination((prevPagination: IPagination) => ({
			...prevPagination,
			currentPage: value
		}));
	}, []);

	const editPremiumLeadsLimit = async (payload: any) => {
		try {
			const result = await api.leads.updatePremiumLeadsLimit(payload);
			if (result) {
				alert("Lead limit updated");
			}
		} catch (error) {
			console.error("Error updating user details:", error);
		}
	};

	useEffect(() => {
		getCustomerList();
	}, [getCustomerList]);

	useEffect(() => {
		setDashboardHeader("All Customers");
	}, [setDashboardHeader]);

	return (
		<>
			<div className="add-btn">
				<DownloadButton apiCall={getAllCustomer} fileName="Customer-Data" key={"customer"} />
			</div>

			<DataGrid colDefs={CustomerColDefs} rowData={customerData} key={0} onFilterChange={handleFilterChange} handleCellValueChange={editPremiumLeadsLimit} />

			<BasicPagination
				currentPage={pagination.currentPage}
				handlePageChange={handlePageChange}
				pageCount={pagination.pageCount}
			/>
		</>
	);
};

export default AllCustomersPage;
