import { useCallback, useContext, useEffect, useState } from "react";
import UIContext from "../../../../contexts/uiContext/UIContext";
import ProjectCard from "../../../shared/projectCard/ProjectCard";
import BasicPagination from "../../../shared/basicPagination/BasicPagination";
import { IPagination } from "../../../../@types/interface/pagination";
import useModalOpen from "../../../../utils/hooks/modalOpen/useModalOpen";
import useQuerySearch from "../../../../utils/hooks/querySearch/useQuerySearch";
import CommonModal from "../../../shared/commonModal/CommonModal";
import CofirmPurchase from "../../../shared/payButton/confirmPurchase/CofirmPurchase";
import useSelectLeads from "../../../../utils/hooks/selectLeadstId/useSelectLeads";
import ProjectCardVertical from "../../../shared/projectCardV2/ProjectCardV2";
import { api } from "../../../../utils/api";

const RecentMarketplace = () => {
	const { setDashboardHeader } = useContext(UIContext);
	const [pagination, setPagination] = useState<IPagination>({
		currentPage: 1,
		pageCount: 1
	});
	const [leads, setLeads] = useState<any>([]);

	const getRowData = async () => {
		const response: any = await api.leads.getLastTwoDaysExclusiveLeads({});
		setLeads(response.result);
		console.log("=====>recent primuim plans", response.result);
	};

	const { modalOpen, handleOpenModal, handleCloseModal } = useModalOpen();
	const { selectedLead, handleSetLeads } = useSelectLeads();
	const item = useQuerySearch("item");

	const handlePageChange = useCallback((event: React.ChangeEvent<unknown>, value: number) => {
		setPagination((prevPagination: IPagination) => ({
			...prevPagination,
			currentPage: value
		}));
	}, []);

	const handlePurchaseLead = (leadsId: string) => {
		handleSetLeads(leadsId);
		handleOpenModal();
	};

	useEffect(() => {
		setDashboardHeader("Latest Exclusive Leads");
	}, [setDashboardHeader]);

	useEffect(() => {
		if (item) {
			handlePurchaseLead(item);
		}
	}, [item]);

	useEffect(() => {
		getRowData();
	}, []);

	return (
		<div>
			{/* <div className="w-full h-auto flex mt-20 items-center justify-center">
				<SearchBar
					handleCityChange={handleCityChange}
					handleSearchFilterChange={handleSearchFilterChange}
					searchFilterValue={searchFilterValue}
					selectedCity={city.name}
					key={0}
				/>
			</div> */}
			{/* {leads.map((leads, index) => (
				<ProjectCard key={index} title={data.title} description={data.description} image={data.image} leads={leads} />
			))} */}
			{leads.map((lead: any) => (
				<ProjectCardVertical
					key={lead._id}
					lead={lead}
					mode={"MARKETPLACE"}
					handlePurchaseLead={handlePurchaseLead}
				/>
			))}
			<BasicPagination
				currentPage={pagination.currentPage}
				pageCount={pagination.pageCount}
				handlePageChange={handlePageChange}
			/>
			<CommonModal open={modalOpen} handleClose={handleCloseModal}>
				{selectedLead ? (
					<CofirmPurchase lead_object_id={selectedLead} key={selectedLead} onClose={handleCloseModal} />
				) : (
					<div>Something went wrong</div>
				)}
			</CommonModal>
		</div>
	);
};

export default RecentMarketplace;
