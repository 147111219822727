import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { api } from "../../../utils/api";
import { Box, Button, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import CustomerContext from "../../../contexts/customerContext/customerContext";
import useQuerySearch from "../../../utils/hooks/querySearch/useQuerySearch";

const PlanSuccess = () => {
	const { user, setUser } = useContext(CustomerContext);
	const navigate = useNavigate();

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				height: "100vh",
				bgcolor: "#f9f9f9"
			}}
		>
			{/* Animated Checkmark */}
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					flexDirection: "column",
					mt: -10
				}}
			>
				<DotLottieReact
					src="https://lottie.host/7b3012b9-807d-4cbd-baf1-5cd9b6ea98c9/CeOEs3Jgt9.lottie"
					//   loop
					autoplay
					style={{ height: "150px", width: "150px" }}
				/>
				<Typography
					sx={{
						mt: 2,
						fontSize: "24px",
						fontWeight: "bold",
						color: "#333"
					}}
				>
					You Have Been Subscribed Successfully
				</Typography>
				<Button startIcon={<ArrowBackIcon />} onClick={() => navigate("/customer/subscription")}>
					Go Back
				</Button>
			</Box>
		</Box>
	);
};

export default PlanSuccess;
