import { useCallback, useEffect, useState } from "react";
import { useFetchWallet } from "../../../../utils/hooks/fetchWalletBalance/useFetchWallet";
import useExclusiveLeadDetails from "../../../../utils/hooks/exclusiveLeadDetails/useExclusiveLeadDetails";
import Purchase from "./purchase/Purchase";
import InSuffecientBalance from "./inSuffecientBalance/InSuffecientBalance";
import usePurchaseExclusiveLeads from "../../../../utils/hooks/purchaseExclusiveLeads/usePurchaseExclusiveLeads";
import WalletCardWrapper from "../../walletCardWrapper/WalletCardWrapper";

const CofirmPurchase = ({ lead_object_id, onClose }: { lead_object_id: string, onClose: () => void; }) => {
	const [isCustomerEligible, setIsCustomerEligible] = useState<boolean>(false);

	const { walletBalance, loading: walletLoading } = useFetchWallet();
	const leadDetails = useExclusiveLeadDetails(lead_object_id);
	const { loading, handleConfirm } = usePurchaseExclusiveLeads();

	const handleClickConfirm = (e: any) => {
		e.preventDefault();
		handleConfirm(lead_object_id);
	}

	const eligibleCustomer = useCallback(() => {
		if (leadDetails) {
			if (walletBalance >= leadDetails?.bidding_price) {
				setIsCustomerEligible(true);
			}
		}
	}, [walletBalance, leadDetails]);

	useEffect(() => {
		eligibleCustomer();
	}, [eligibleCustomer]);

	console.log("===>leadDetails", lead_object_id);

	return (
		<div>
			{
				walletLoading ? (
					<div className="bg-white p-5">
						"Loading wallet balance..."
					</div>) :
					isCustomerEligible ? (
						<Purchase handleConfirm={handleClickConfirm} loading={loading} key={lead_object_id} />
					) : (
						<WalletCardWrapper minimumAmount={(leadDetails?.bidding_price || 0) - walletBalance} lead_object_id={lead_object_id} onClose={onClose} mode="PURCHASE" />
					)}
		</div>
	);
};

export default CofirmPurchase;
