import { request } from "../api";
import { MESSAGE } from "../../../constants/api/message";
import { Params, Payload } from "../../../@types/api/api.types";
import { headers } from "../../../config/config";

const { post } = request;

const initialRoute = "leads";

export const premiumLeadsBulkUpload = async (payload: FormData) => {
  try {
    const endpoint = `${initialRoute}/bulk-upload-premium-leads`;
    const response = await post(endpoint, payload, {
      ...headers,
      "Content-Type": "multipart/form-data",
    });

    if (response?.status === 200) {
      const { message, result } = response.data;
      if (message === MESSAGE.post.succ) {
        return result; // Return the result
      }
    }
    throw new Error();
  } catch (error: any) {
    console.error("Error while login:", error);
    throw error;
  }
};

export const manualUploadExclusiveLeads = async (payload: Payload) => {
  try {
    const endpoint = `${initialRoute}/addmanual-exclusive-leads`;
    const response = await post(endpoint, payload, {
      ...headers,
    });

    if (response) {
      const {
        data: { message },
      } = response;
      if (message === MESSAGE.post.succ) {
        const {
          data: { result },
        } = response;
        return result;
      }
    }
    throw new Error();
  } catch (error: any) {
    console.log(error);
    throw error;
  }
};

export const getExclusiveLeads = async (params: Params) => {
  try {
    const endpoint = `${initialRoute}/get-exclusive-leads`;
    const response = await request.get(
      endpoint,
      {
        ...headers,
      },
      params
    );

    if (response?.status === 200) {
      const { message, result, pagination } = response.data;
      if (message === MESSAGE.get.succ) {
        return { result, pagination }; // Return the result
      }
    }
    throw new Error();
  } catch (error: any) {
    console.error("Error while fetching leads:", error);
    throw error;
  }
};

export const getLastTwoDaysPremiumLeads = async (params: Params) => {
  try {
    const endpoint = `${initialRoute}/get-new-premium-leads`;
    const response = await request.get(
      endpoint,
      {
        ...headers,
      },
      params
    );

    if (response?.status === 200) {
      const { message, result, pagination } = response.data;
      if (message === MESSAGE.get.succ) {
        return { result, pagination }; // Return the result
      }
    }
    throw new Error();
  } catch (error: any) {
    console.error("Error while fetching leads:", error);
    throw error;
  }
};

export const getExclusiveLeadDetails = async (filter: Params) => {
  try {
    const endpoint = `${initialRoute}/get-exclusive-lead-detail`;
    const response = await request.get(
      endpoint,
      {
        ...headers,
      },
      filter
    );

    if (response?.status === 200) {
      const { message, result } = response.data;
      if (message === MESSAGE.get.succ) {
        return result; // Return the result
      }
    }
    throw new Error();
  } catch (error: any) {
    console.error("Error while fetching leads:", error);
    throw error;
  }
};
