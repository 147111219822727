import { useCallback, useEffect, useState } from "react";
import { ILeads } from "../../../@types/interface/leadDetails.interface";
import { formatFilters } from "../../commonFunctions/formatApiFilters";
import { IPagination } from "../../../@types/interface/pagination";
import { api } from "../../api";

const useGetPurchasedPremiumLeads = (
	filters: any,
	pagination: IPagination,
	setPagination: React.Dispatch<React.SetStateAction<IPagination>>,
	userId: string
) => {
	const [rowData, setRowData] = useState<ILeads[]>([]);
	const [loading, setLoading] = useState<boolean>(false);

	const getPremiumLeadList = useCallback(async () => {
		try {
			const formattedFilter = formatFilters(filters);
			console.log("Formatted filters-->", formattedFilter);
			setLoading(true);

			const filter = {
				...formattedFilter,
				page: pagination.currentPage,
				customer_object_id: userId
			};
			const response = await api.leads.getCustomerExclusiveLeads(filter);
			if (response) {
				setRowData(response.result);
				setPagination(response.pagination);
			}
		} catch (error) {
			console.error("Error while fetching data:", error);
		} finally {
			setLoading(false);
		}
	}, [pagination.currentPage, filters, userId]);

	useEffect(() => {
		getPremiumLeadList();
	}, [getPremiumLeadList]);

	return { rowData, loading };
};

export default useGetPurchasedPremiumLeads;
