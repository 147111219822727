import { ColDef } from "ag-grid-community";
import LeadCellRenderer from "../../components/pages/leads/leadCellRenderer/LeadCellRenderer";
import FormattedDateCellRenderer from "../../components/shared/dateCellRenderer/FormattedDateCellRenderer";
import DateCellRenderer from "../../components/shared/dateCellRenderer/FormattedDateCellRenderer";
import DeleteLeadCellRenderer from "./deleteLeadCellRenderer/DeleteLeadCellRenderer";

export const LeadColDefs: ColDef[] = [
  {
    field: "lead_name",
    headerName: "Name",
    suppressSizeToFit: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
  },
  {
    field: "lead_email",
    headerName: "Email",
    suppressSizeToFit: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
  },
  {
    field: "state",
    headerName: "State",
    suppressSizeToFit: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
  },

  {
    field: "lead_phone",
    headerName: "Phone No.",
    suppressSizeToFit: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
  },
  {
    field: "lead_type",
    headerName: "Lead Status",
    suppressSizeToFit: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
  },
  {
    field: "bidding_price",
    headerName: "Bidding Price (USD)",
    suppressSizeToFit: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
  },
  {
    field: "createdAt",
    headerName: "Date",
    cellRenderer: DateCellRenderer,
    suppressSizeToFit: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
  },
  {
    field: "action",
    headerName: "Action",
    cellRenderer: LeadCellRenderer,
    suppressSizeToFit: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
  },
  {
    field: "action",
    headerName: "Action",
    cellRenderer: DeleteLeadCellRenderer,
  },
];
