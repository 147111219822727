import React, { useContext, useEffect, useState } from "react";
import { api } from "../../../utils/api";
import useUpdateWallet from "../../../utils/hooks/updateWallet/useUpdateWallet";
import useOpenConfetti from "../../../utils/hooks/openConfetti/useOpenConfetti";
import { IWallet } from "../../../@types/interface/wallet.interface";
import usePurchaseExclusiveLeads from "../../../utils/hooks/purchaseExclusiveLeads/usePurchaseExclusiveLeads";
import useQuerySearch from "../../../utils/hooks/querySearch/useQuerySearch";
import CustomerContext from "../../../contexts/customerContext/customerContext";
import useGetPaymentMethod from "../../../utils/hooks/useGetPaymentMethod/useGetPaymentMethod";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { WalletModalProps } from "../../../@types/props/walletModal.props";
import { LOGIN_REDIRECTION } from "../../../config/config";
import usePurchaseCartLead from "../../../utils/hooks/purchasedCartLead/usePurchasedCartLead";


const WalletModal: React.FC<WalletModalProps> = ({
    mode,
    onClose,
    setWallet,
    minimumAmount,
    lead_object_id
}) => {
    const { user } = useContext(CustomerContext);
    const [billingName, setBillingName] = useState(user?.first_name || "" + user?.last_name || "");
    const [billingAddress, setBillingAddress] = useState("");
    const [discountCoupon, setDiscountCoupon] = useState("");
    const [amount, setAmount] = useState(""); // New amount field
    const [isProcessing, setIsProcessing] = useState(false);
    const [hasChangeCard, setHasChangeCard] = useState<boolean>(false);
    const { updateWallet } = useUpdateWallet();
    const { handleExplosion } = useOpenConfetti();
    const item = useQuerySearch('item');
    const { handleConfirm } = usePurchaseExclusiveLeads();
    const { handleConfirmPurchaseFromCart, loading: cartLoading } = usePurchaseCartLead();
    const { paymentMethod } = useGetPaymentMethod();
    const stripe = useStripe();
    const elements = useElements();

    const handleChangeCard = () => {
        setHasChangeCard(!hasChangeCard);
    }
    const handlePayment = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsProcessing(true);
        try {
            // Validate amount
            if (!amount || isNaN(Number(amount)) || Number(amount) <= 0) {
                alert("Please enter a valid amount.");
                setIsProcessing(false);
                return;
            }
            let response = null;
            if (!paymentMethod || hasChangeCard) {
                if (!stripe || !elements) {
                    console.error("Stripe.js has not yet loaded");
                    return;
                }
                const cardElement = elements.getElement(CardElement);
                if (!cardElement) {
                    console.error("Card element not found");
                    return;
                }
                const { error, paymentMethod } = await stripe.createPaymentMethod({
                    type: "card",
                    card: cardElement
                });
                response = await api.payment.createPayment({
                    customer_object_id: user?._id,
                    amount: parseFloat(amount), // Parse the amount to a float
                    payment_method_id: paymentMethod?.id,
                    description: "Wallet deposit",
                });
            }
            else {
                response = await api.payment.createPayment({
                    customer_object_id: user?._id,
                    amount: parseFloat(amount), // Parse the amount to a float
                    payment_method_id: paymentMethod?.id,
                    description: "Wallet deposit",
                });
            }

            if (response) {
                const wallet = await updateWallet({
                    customer_id: user?._id,
                    amountInDollar: parseFloat(amount),
                    paymentType: "card",
                    transactionId: response.paymentIntentId,
                });
                console.log("===>wallet", wallet);
                if (setWallet) {
                    setWallet(wallet.response);
                }
                if (item || lead_object_id) {
                    if (item) {
                        await handleConfirm(item);
                    } else if (lead_object_id) {
                        await handleConfirm(lead_object_id);
                    }
                }
                else {
                    if (mode === "PURCHASE_CART") {
                        handleConfirmPurchaseFromCart();
                    }
                    handleExplosion();
                }
                onClose();
            }
        } catch (error) {
            console.error("Error processing payment:", error);
            alert("Failed to process payment. Please try again.");
        }
        setIsProcessing(false);
    };

    useEffect(() => {
        if (minimumAmount) {
            setAmount(minimumAmount.toString());
        }
    }, [minimumAmount])

    return (
        <div className="bg-white rounded-lg shadow-lg w-full max-w-md p-6">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-bold text-blue-600">Recharge Your Wallet</h2>
                <button
                    className="text-gray-400 hover:text-gray-600"
                    onClick={onClose}
                >
                    ✕
                </button>
            </div>
            <form onSubmit={handlePayment}>
                <div className="mb-4">
                    <label className="block text-sm font-medium mb-2">
                        Billing Name
                    </label>
                    <input
                        type="text"
                        className="w-full border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring focus:ring-blue-200"
                        value={billingName}
                        onChange={(e) => setBillingName(e.target.value)}
                        placeholder="Enter your name"
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium mb-2">
                        Billing Address
                    </label>
                    <input
                        type="text"
                        className="w-full border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring focus:ring-blue-200"
                        value={billingAddress}
                        onChange={(e) => setBillingAddress(e.target.value)}
                        placeholder="Enter your address"
                    />
                </div>
                {/* <div className="mb-4">
                    <label className="block text-sm font-medium mb-2">
                        Discount Coupon
                    </label>
                    <div className="flex items-center space-x-2">
                        <input
                            type="text"
                            className="w-full border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring focus:ring-blue-200"
                            value={discountCoupon}
                            onChange={(e) => setDiscountCoupon(e.target.value)}
                            placeholder="Enter discount code"
                        />
                        <button className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2 text-center inline-flex items-center ">
                            Apply
                        </button>
                    </div>
                </div> */}

                {/* Conditional rendering of card details */}
                {paymentMethod && !hasChangeCard ? (
                    <>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">
                                Card Number
                            </label>
                            <input
                                type="text"
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                placeholder="XXXX - XXXX - XXXX - XXXX"
                                value={`XXXX - XXXX - XXXX -${paymentMethod.card.last4}`}
                                readOnly
                            />
                        </div>
                        <div className="mb-4 flex flex-row gap-2">
                            <div>
                                <label htmlFor="expiry" className="block text-sm font-medium">
                                    Expiry
                                </label>
                                <input
                                    type="text"
                                    id="expiry"
                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    placeholder="MM/YY"
                                    value={`${paymentMethod.card.exp_month}/${paymentMethod.card.exp_year}`}
                                    readOnly
                                />
                            </div>
                            <div>
                                <label htmlFor="cvc" className="block text-sm font-medium">
                                    CVC
                                </label>
                                <input
                                    type="text"
                                    id="cvc"
                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    placeholder="CVC"
                                    value={"XXX"}
                                    readOnly
                                />
                            </div>
                        </div>
                    </>) : <CardElement className="card-element" />
                }

                <div className="mb-4">
                    <label className="block text-sm font-medium mb-2">Type in the amount you would like to add to your wallet</label>
                    <input
                        type="number"
                        className="w-full border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring focus:ring-blue-200"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        placeholder="Enter amount"
                    />
                </div>

                <div className="w-full">
                    {
                        paymentMethod ?
                            hasChangeCard ?
                                <div>
                                    <button type="button" onClick={handleChangeCard} className="focus:outline-none text-black bg-white border border-gray-300 w-full hover:bg-gray-200 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2">Go to Previously Added Card</button>
                                </div> :
                                <div>
                                    <button type="button" onClick={handleChangeCard} className="focus:outline-none text-white bg-red-70 w-full hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 bg-red-600 ">Change Card & Proceed</button>
                                </div> : null
                    }
                </div>
                <div className="mb-3 flex items-center">
                    <input
                        id="terms"
                        name="terms"
                        type="checkbox"
                        className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                        required
                    />
                    <label htmlFor="terms" className="ml-2 block text-sm text-gray-900">
                        I agree to the{" "}
                        <a href={`${LOGIN_REDIRECTION}refund`} className="text-indigo-600 hover:underline" target="_blank" rel="noopener noreferrer">
                            leads refund policy
                        </a>
                    </label>
                </div>
                <button
                    type="submit"
                    className="w-full bg-blue-700 text-white py-2 px-4 rounded-lg hover:bg-blue-800 focus:outline-none focus:ring focus:ring-red-200"
                    disabled={isProcessing}
                >
                    {isProcessing ? "Processing..." : `Pay Now ${amount ? "$" + amount : ""}`}
                </button>
            </form>
        </div>
    );
};

export default WalletModal;
