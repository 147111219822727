import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { userDynamicRoutes } from "../../../../constants/userDynamicRoutes/UserDynamicRoutes";
import { ILeads } from "../../../../@types/interface/leadDetails.interface";

const LeadCellRenderer = ({ data }: { data: ILeads }) => {
	const navigate = useNavigate();

	const handleRouteToLeadDetails = () => {
		navigate(`/super-admin/marketplace-lead-details?lid=${data._id}`);
	};
	return (
		<div>
			<Button variant="outlined" className="purple-outlined-button" onClick={handleRouteToLeadDetails}>
				See Details
			</Button>
		</div>
	);
};

export default LeadCellRenderer;
