import { useCallback } from "react";
import { api } from "../../api";

const useUpdateWallet = () => {
  const updateWallet = useCallback(
    async ({
      customer_id,
      amountInDollar,
      paymentType,
      transactionId,
    }: any) => {
      try {
        const payload = {
          customer_id,
          amountInDollar,
          paymentType,
          transactionId,
        };
        const response = await api.payment.updatewallet(payload);
        return response;
      } catch (error) {
        console.error("Error updating wallet:", error);
      } finally {
        localStorage.removeItem("@payment");
      }
    },
    []
  );
  return {
    updateWallet,
  };
};

export default useUpdateWallet;
