import { Box, Tab, Tabs } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import a11yProps from "../../../constants/tabPanelProps/ayProps";
import TabPanel from "../../shared/tabPanel/TabPanel";
import UIContext from "../../../contexts/uiContext/UIContext";
import DataGrid from "../../shared/dataGrid/DataGrid";

const Revenue = () => {
	const [value, setValue] = useState<number>(0);
	const { setDashboardHeader } = useContext(UIContext);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	useEffect(() => {
		setDashboardHeader("Revenue Earned");
	}, [setDashboardHeader]);
	return (
		<div>
			<Box sx={{ width: "100%" }}>
				<Box>
					<Tabs
						value={value}
						onChange={handleChange}
						aria-label="basic tabs example"
						sx={{ fontSize: "20px" }}
						TabIndicatorProps={{
							style: {
								backgroundColor: "#7c00fe"
							}
						}}
						className="glb-tab-panel"
					>
						<Tab label="Tab1" {...a11yProps(0)} className="tab-label" />
						<Tab label="Tab2" {...a11yProps(1)} className="tab-label" />
						<Tab label="Tab3" {...a11yProps(2)} className="tab-label" />
					</Tabs>
				</Box>
				<TabPanel value={value} index={0}>
					<DataGrid rowData={[]} colDefs={[]} />
				</TabPanel>
				<TabPanel value={value} index={1}>
					<DataGrid rowData={[]} colDefs={[]} />
				</TabPanel>
				<TabPanel value={value} index={2}>
					<DataGrid rowData={[]} colDefs={[]} />
				</TabPanel>
			</Box>
		</div>
	);
};

export default Revenue;
