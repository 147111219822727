import { useContext } from "react";
import { api } from "../../api";
import CustomerContext from "../../../contexts/customerContext/customerContext";
import useOpenConfetti from "../openConfetti/useOpenConfetti";

const useSubscriptionCancelOffer = () => {
  const { user, setUser } = useContext(CustomerContext);
  const { handleExplosion } = useOpenConfetti();

  const handleClaimOffer = async () => {
    try {
      const payload = {
        customerId: user?._id,
      };
      const response = await api.payment.cancelOfferClaim(payload);
      if (response && user && user._id) {
        setUser({ ...user, has_cancelled_claim: true });
        handleExplosion();
        return response;
      }
    } catch (error) {
      console.log(error);
    }
  };
  return { handleClaimOffer };
};

export default useSubscriptionCancelOffer;
