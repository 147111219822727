import React, { useContext, useEffect, useState } from "react";
import { LEAD_TYPE } from "../../../constants/leadTypeData/LeadTypeData";
import LeadCard from "../leadCard/LeadCard";
import useGetLeadCount from "../../../utils/hooks/getLeadsCount/getLeadsCount";

const LeadCardContainer = () => {
	const leadCount = useGetLeadCount();
	console.log("Lead Count-->", leadCount);
	return (
		<div className="grid grid-cols-2 lg:grid-cols-4 mb-6">
			{LEAD_TYPE.map((lead) => {
				return (
					<LeadCard
						key={lead.id}
						className={lead.className}
						label={lead.label}
						content={leadCount[lead.field]}
					/>
				);
			})}
		</div>
	);
};

export default LeadCardContainer;
