import { useContext, useRef, useState } from "react";
// import { api } from "../../../../utils/api";

// import AuthContext from "../../../../contexts/authContext/authContext";
import Otp from "./Otp";

import { IOtpModalProps } from "../../../@types/props/otpModal.props";
import CommonModal from "../commonModal/CommonModal";
import AlertModal from "../alertModal/AlertModal";
import { Bounce, toast, ToastContainer } from "react-toastify";

const OtpModal = ({ handleSignup, open, setOpen, expectedOtp }: IOtpModalProps) => {
	const [alertOpen, setAlertOpen] = useState(false);
	const [otp, setOtp] = useState(new Array(4).fill(""));
	const inputsRef = useRef<Array<HTMLInputElement | null>>([]);

	const handleClose = () => setOpen(false);

	const handleAlertClose = () => setAlertOpen(false);

	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
		if (e.key === "Backspace" && !otp[index]) {
			if (index > 0) {
				inputsRef.current[index - 1]?.focus();
			}
		}
	};

	const handleOtpChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
		const value = e.target.value;
		if (isNaN(Number(value)) || value.length > 1) return;
		const newOtp = [...otp];
		newOtp[index] = value;
		setOtp(newOtp);

		if (index < 3 && value !== "") {
			inputsRef.current[index + 1]?.focus();
		}
	};

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		const enteredOtp = otp.join("");
		if (enteredOtp === expectedOtp) {
			handleClose();
		} else {
			handleClose();
		}
	};

	const handleCreateAccount = async () => {
		try {
			const final_otp = otp[0] + otp[1] + otp[2] + otp[3];
			console.log("===>fianl otp", final_otp);
			console.log("===>expected otp", expectedOtp);
			if (String(final_otp) === String(expectedOtp)) {
				await handleSignup();
			} else {
				setAlertOpen(true);
			}
		} catch (error) {
			toast("Incorrect Otp", {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: false,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
				transition: Bounce
			});
		}
	};

	return (
		<div className="flex flex-row justify-between">
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick={false}
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="colored"
				className={"mt-20"}
			/>
			<CommonModal handleClose={handleClose} open={open}>
				<Otp
					handleOtpChange={handleOtpChange}
					otp={otp}
					handleSubmit={handleSubmit}
					handleSignup={handleCreateAccount}
					inputsRef={inputsRef}
					handleKeyDown={handleKeyDown}
				/>
			</CommonModal>

			<AlertModal open={alertOpen} handleClose={handleAlertClose} />
		</div>
	);
};

export default OtpModal;
