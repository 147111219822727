import { useContext } from "react";
import CustomerContext from "../../../contexts/customerContext/customerContext";
import { api } from "../../api";

const useFavPremiumLeads = () => {
	const { user } = useContext(CustomerContext);

	const handleFavouriteLead = async (leadObjectId: string, is_fav: boolean) => {
		try {
			if (!is_fav) {
				const payload = {
					customer_object_id: user?._id,
					premium_lead_object_id: leadObjectId
				};
				const res = await api.leads.postCustomerFavPremiumLeads(payload);
				return res;
			} else {
				return null;
			}
		} catch (error) {
			alert("Insufficient balance !!");
			console.log("Error ==>", error);
		}
	};
	return { handleFavouriteLead };
};

export default useFavPremiumLeads;
