import { request } from "../api";
import { MESSAGE } from "../../../constants/api/message";
import { Params, Payload } from "../../../@types/api/api.types";
import { headers } from "../../../config/config";

const { post } = request;

const initialRoute = "leads";



const { patch } = request;


export const updatePremiumLeadsLimit = async (payload: Payload) => {
	try {
		const endpoint = `${initialRoute}/update-premium-leads-limit`;

		const response = await patch(
			endpoint,
			payload,
			{
				...headers
			},
		);
		if (response) {
			const {
				data: { message }
			} = response;
			if (message === MESSAGE.patch.succ) {
				const {
					data: { result }
				} = response;
				return result;
			}
		}
		throw new Error();
	} catch (error) {
		console.log("Failed to add user details");
		throw error;
	}
};
