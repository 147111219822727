import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import { store } from "./redux/store";
import reportWebVitals from "./reportWebVitals";
import UIContextProvider from "./contexts/uiContext/Provider";
import "./index.css";
import CustomerContextProvider from "./contexts/customerContext/Provider";
import AdminContextProvider from "./contexts/adminContext/Provider";
import { googleMapsApiKey } from "./config/googleMaps.config";
import { APIProvider } from "@vis.gl/react-google-maps";

import { GoogleOAuthProvider } from "@react-oauth/google";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
	<Provider store={store}>
		<GoogleOAuthProvider clientId="832803521704-gvkgoibm78d3tf6kklqg14pavfb8j2u6.apps.googleusercontent.com">
			<UIContextProvider>
				<CustomerContextProvider>
					<AdminContextProvider>
						<APIProvider apiKey={googleMapsApiKey}>
							<App />
						</APIProvider>
					</AdminContextProvider>
				</CustomerContextProvider>
			</UIContextProvider>
		</GoogleOAuthProvider>
	</Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
