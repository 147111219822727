/* eslint-disable quotes */
import * as XLSX from "xlsx";
import { convertNameToUnderScoreTrimmed } from "./convertNameToUnderScore";

const excelToJson = async (excelFile: File) => {
	const file = excelFile;
	const reader = new FileReader();
	return new Promise((resolve, reject) => {
		reader.onerror = () => {
			reader.abort();
			reject(new DOMException("Problem parsing input file."));
		};
		reader.onload = function (excel) {
			try {
				if (excel.target) {
					const data: string | ArrayBuffer | null = excel.target.result;
					const readedData = XLSX.read(data, { type: "binary", dateNF: "YYYY-MM-DD" });
					const wsname = readedData.SheetNames[0];
					const ws = readedData.Sheets[wsname];
					const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1, raw: false });

					// Check if the parsed data is empty or has insufficient rows
					if (dataParse.length < 2) {
						resolve([]);
						return;
					}

					// Transform the parsed data into JSON format
					const headers: any = dataParse[0];
					const jsonData = dataParse
						.slice(1)
						.map((row: any) => {
							const obj: { [key: string]: any } = {};
							headers.forEach((header: string, index: number) => {
								obj[convertNameToUnderScoreTrimmed(header)] = row[index];
							});
							return obj;
						})
						.filter((row: { [key: string]: any }) => {
							// Filter out rows where all values are undefined or empty
							return Object.values(row).some((value: any) => value !== undefined && value !== "");
						});

					resolve(jsonData);
				}
			} catch (err: unknown) {
				if (typeof err === "string") {
					reject(new DOMException(err));
				}
				reject(err);
			}
		};

		if (file) {
			reader.readAsBinaryString(file);
		}
	});
};

export default excelToJson;
