import { Autocomplete, Button, Paper, TextField } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import "./profile.css";
import UIContext from "../../../../contexts/uiContext/UIContext";
import CustomerContext from "../../../../contexts/customerContext/customerContext";
import { State } from "../../../shared/state/State";

const CustomerProfile = () => {
	const { setDashboardHeader } = useContext(UIContext);
	const { user } = useContext(CustomerContext);
	const [isReadOnly, setIsReadOnly] = useState<boolean>(true);

	useEffect(() => {
		setDashboardHeader("Profile Settings");
	}, [setDashboardHeader]);
	return (
		<div id="customer-profile">
			<h3>Customer Details</h3>
			<div>
				<Paper>
					<div className="flex-input-field">
						<label>Name:</label>
						<TextField
							type="text"
							sx={{
								"& .MuiOutlinedInput-root": {
									"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
										borderColor: "#7c00fe"
									},

									"& .MuiOutlinedInput-notchedOutline": {
										borderWidth: "1px"
									}
								}
							}}
							className="inputbox"
							variant="outlined"
							value={user?.first_name}
							inputProps={{ readOnly: isReadOnly }}
						/>
					</div>
					<div className="flex-input-field">
						<label>Email:</label>
						<TextField
							type="email"
							sx={{
								"& .MuiOutlinedInput-root": {
									"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
										borderColor: "#7c00fe"
									},

									"& .MuiOutlinedInput-notchedOutline": {
										borderWidth: "1px"
									}
								}
							}}
							className="inputbox"
							variant="outlined"
							value={user?.email}
							InputProps={{ readOnly: isReadOnly }}
						/>
					</div>
					<div className="flex-input-field">
						<label>Phone Number:</label>
						<TextField
							className="inputbox"
							sx={{
								"& .MuiOutlinedInput-root": {
									"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
										borderColor: "#7c00fe"
									},

									"& .MuiOutlinedInput-notchedOutline": {
										borderWidth: "1px"
									}
								}
							}}
							variant="outlined"
							value={user?.phone_number}
							inputProps={{ readOnly: true }}
						/>
					</div>
				</Paper>
			</div>
		</div>
	);
};

export default CustomerProfile;
