import React, { useContext, useState } from 'react'
import CustomerContext from '../../../../contexts/customerContext/customerContext'
import Chips from '../../chips/Chips';
import { AddLeadsOption } from '../../../../constants/addLeadsOption/AddLeadsOption';
import CommonModal from '../../commonModal/CommonModal';
import AddLeadsCardWrapper from '../../addleadsWrapper/AddLeadsCardWrapper';

const BottomUserDetails = () => {
	const { user } = useContext(CustomerContext);
	const [selectedLeads, setSelectedLeads] = useState<number>(0);
	const [isLeadsAddModal, setIsLeadsAddModal] = useState<boolean>(false);

	const handleCloseLeadsAddModal = () => {
		setIsLeadsAddModal(false);
	}

	const handleOpenLeadsAddModal = (leads: number) => {
		setSelectedLeads(leads);
		setIsLeadsAddModal(true);
	}

	return (
		<div className='px-4 mb-4'>
			<div className='flex items-center mb-2'>
				{
					user?.photo ? (
						<img
							src={user.photo}
							alt="profile"
							style={{ width: 35, height: 35, borderRadius: "50%" }} />
					) :
						(

							<div className='w-10 h-10 rounded-full bg-orange-400 flex items-center justify-center text-white font-medium'>
								{user?.first_name?.charAt(0)}{user?.last_name?.charAt(0)}
							</div>
						)
				}
				<div className='ml-3'>
					<div className='text-sm font-medium'>{user?.first_name} {user?.last_name}</div>
					<div className='text-xs text-gray-600'>{user?.email}</div>
				</div>
			</div>


			<div className="w-full bg-gray-300 rounded-full h-1.5 mb-2">
				<div className="bg-blue-600 h-1.5 rounded-full" style={{ width: `${(user?.premium_leads_limit || 0)}%` }}></div>
			</div>
			<div className="mb-1 text-sm text-gray-600 font-medium">{user?.premium_leads_limit} Premium Leads Remaining</div>
			{
				user?.is_subscription ?
					<div className='mt-3'>
						<p className='text-sm mb-1 text-gray-600'>Add Leads</p>
						<div className='flex'>
							{
								AddLeadsOption.map((option, index) => (
									<Chips key={index} text={option.label} isRecommended={option.isRecommended} handleClick={() => handleOpenLeadsAddModal(option.leads)} />
								))
							}
						</div>
					</div> : null
			}
			<div className='flex gap-1 items-start justify-start mt-1'>

				<p className='text-lg text-gray-700'>Wallet Balance</p>
				<h1 className='px-2 font-bold text-black mb-2 text-xl'>${user?.wallet_balance || 0}</h1>

			</div>

			<CommonModal open={isLeadsAddModal} handleClose={handleCloseLeadsAddModal} key={"wallet_modal"}>
				<AddLeadsCardWrapper
					no_of_leads={selectedLeads}
					onClose={handleCloseLeadsAddModal}
				/>
			</CommonModal>
		</div>
	)
}

export default BottomUserDetails