import { request } from "../api";
import { MESSAGE } from "../../../constants/api/message";
import { Params, Payload } from "../../../@types/api/api.types";
import { headers } from "../../../config/config";

const { get, post } = request;

const initialRoute = "wallet";

export const getWalletTransaction = async (filter: Params) => {
  try {
    const endpoint = `${initialRoute}/get-wallet-transaction`;
    const response = await get(
      endpoint,
      {
        ...headers,
      },
      filter
    );

    if (response?.status === 200) {
      const { message, result } = response.data;
      if (message === MESSAGE.get.succ) {
        return result; // Return the result
      }
    }
    throw new Error();
  } catch (error: any) {
    console.error("Error while Checkout:", error);
    throw error;
  }
};

export const updateBalanceByAdmin = async (payload: Payload) => {
  try {
    const endpoint = `${initialRoute}/update-wallet-balance`;
    const response = await post(endpoint, payload, {
      ...headers,
    });

    if (response?.status === 200) {
      const { message, result } = response.data;
      if (message === MESSAGE.post.succ) {
        return result; // Return the result
      }
    }
    throw new Error();
  } catch (error: any) {
    console.error("Error while Checkout:", error);
    throw error;
  }
};

export const getWalletDiscountPrice = async (filter: Params) => {
  try {
    const endpoint = `${initialRoute}/get-wallet-discount-price`; // Update endpoint name
    const response = await get(
      endpoint,
      {
        ...headers,
      },
      filter
    );

    if (response?.status === 200) {
      const { message, result } = response.data;
      if (message === MESSAGE.get.succ) {
        return result; // Return the payment intent details
      }
    }
    throw new Error();
  } catch (error: any) {
    console.error("Error while creating payment intent:", error);
    throw error;
  }
};
