import { request } from "../api";
import { MESSAGE } from "../../../constants/api/message";
import { headers } from "../../../config/config";

const { get, post, patch } = request;

const initialRoute = "plan";

export const createSubscriptionPlan = async (payload: any) => {
	try {
		const endpoint = `${initialRoute}/create-plan`;
		const response = await post(endpoint, payload, {
			...headers
		});

		if (response?.status === 200) {
			const { message, result } = response.data;
			if (message === MESSAGE.post.succ) {
				return result;
			}
		}
		throw new Error();
	} catch (error: any) {
		console.error("Error while Checkout:", error);
		throw error;
	}
};

export const updateSubscriptionPlan = async (payload: any) => {
	try {
		const endpoint = `${initialRoute}/update-plan`;
		const response = await patch(endpoint, payload, {
			...headers
		});

		if (response?.status === 200) {
			const { message, result } = response.data;
			if (message === MESSAGE.patch.succ) {
				return result;
			}
		}
		throw new Error();
	} catch (error: any) {
		console.error("Error while Checkout:", error);
		throw error;
	}
};

export const getAllSubscriptionPlan = async (filter: any) => {
	try {
		const endpoint = `${initialRoute}/getplans`;
		const response = await get(
			endpoint,
			{
				...headers
			},
			filter
		);

		if (response?.status === 200) {
			const { message, result } = response.data;
			if (message === MESSAGE.get.succ) {
				return result; // Return the result
			}
		}
		throw new Error();
	} catch (error: any) {
		console.error("Error while Checkout:", error);
		throw error;
	}
};

export const getPlanById = async (planId: any) => {
	try {
		const endpoint = `${initialRoute}/getplan`;
		const response = await get(
			endpoint,
			{
				...headers
			},
			planId
		);

		if (response?.status === 200) {
			const { message, result } = response.data;
			if (message === MESSAGE.get.succ) {
				return result; // Return the result
			}
		}
		throw new Error();
	} catch (error: any) {
		console.error("Error while Checkout:", error);
		throw error;
	}
};

export const customerPurchasePlan = async (payload: any) => {
	try {
		const endpoint = `${initialRoute}/subscribe`;
		const response = await post(endpoint, payload, {
			...headers
		});

		if (response?.status === 200) {
			const { message, result } = response.data;
			if (message === MESSAGE.post.succ) {
				return result;
			}
		}
		throw new Error();
	} catch (error: any) {
		console.error("Error while Checkout:", error);
		throw error;
	}
};
export const getActivatedPlanById = async (id: any) => {
	try {
		const endpoint = `${initialRoute}/getplan/${id}`;
		const response = await get(endpoint, {
			...headers
		});
		if (response?.status === 200) {
			const { message, result } = response.data;

			if (message === MESSAGE.get.succ) {
				return result;
			}
		}
		throw new Error();
	} catch (error: any) {
		console.error("Error while Checkout:", error);
		throw error;
	}
};
