import { api } from "../../api";
import useQuerySearch from "../querySearch/useQuerySearch";

const useDeleteExclusiveLeadsBucket = () => {
	const customerId = useQuerySearch("customerId");

	const deleteExclusiveLeadsBucket = async (lead_id: string) => {
		const response = await api.leads.deleteExclusiveLeadBucket(customerId || "", lead_id);
		if (response) {
			alert("Lead Successfully Deleted !!");
			window.location.reload();
		}
	};
	return { deleteExclusiveLeadsBucket };
};

export default useDeleteExclusiveLeadsBucket;
