import React, { useState } from 'react'
import { FormControl, IconButton } from '@mui/material'
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import useShowpassword from '../../../../../utils/hooks/useShowPassword/useShowpassword';
import useChangePassword from '../../../../../utils/hooks/useChnagePassword/useChangePassword';

const CustomerChnagePassword = ({ customerId }: { customerId: string }) => {
	const { showPassword, handleShowPassword } = useShowpassword();
	const { showPassword: showRePassword, handleShowPassword: handleShowRePassword } = useShowpassword();
	const { handleChangePassword } = useChangePassword();
	const [showError, setShowError] = useState<boolean>(false);
	const [passwordCred, setPasswordCred] = useState<{
		password: string,
		rePassword: string
	}>({
		password: '',
		rePassword: ''
	});

	const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value, name } = e.target;
		setShowError(false);
		setPasswordCred({
			...passwordCred,
			[name]: value
		})
	}

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (passwordCred.password !== passwordCred.rePassword) {
			setShowError(true);
		} else {
			await handleChangePassword(customerId, passwordCred.password);
		}
	}
	return (
		<form onSubmit={handleSubmit} autoComplete="on" method="">
			<FormControl className="form-container" id="form-container">
				<div className="mb-3">
					<label htmlFor="password" className="block mb-1 text-sm font-medium text-gray-900">
						Your Password
					</label>
					<div className="relative">
						<input
							name="password"
							id="password"
							type={showPassword ? "text" : "password"}
							autoComplete="your password"
							className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 px-4 py-3.5  "
							placeholder="Password"
							value={passwordCred.password}
							onChange={handlePasswordChange}
							required
						/>
						<div className="absolute inset-y-0 end-0 flex items-center pe-3.5">
							<IconButton onClick={handleShowPassword}>
								{showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
							</IconButton>
						</div>
					</div>
				</div>
				<div className="mb-3">
					<label htmlFor="password" className="block mb-1 text-sm font-medium text-gray-900">
						Your Password
					</label>
					<div className="relative">
						<input
							name="rePassword"
							id="rePassword"
							type={showPassword ? "text" : "password"}
							autoComplete="your password"
							className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 px-4 py-3.5  "
							placeholder="Password"
							value={passwordCred?.rePassword}
							onChange={handlePasswordChange}
							required
						/>
						<div className="absolute inset-y-0 end-0 flex items-center pe-3.5">
							<IconButton onClick={handleShowRePassword}>
								{showRePassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
							</IconButton>
						</div>
					</div>
					{
						showError && (
							<p className="text-red-500 text-sm mt-1">
								Password does not match
							</p>
						)
					}
				</div>
				{/* <ForgetCredentialFooterLink /> */}
				<div className="flex justify-center" id="login-button-container">
					<button
						type="submit"
						className="text-white w-80 rounded-full
											bg-blue-800 mt-3 
											 font-medium text-lg px-5 py-2 text-center me-2"
					>
						Change Password & Continue
					</button>
				</div>
			</FormControl>
		</form>
	)
}

export default CustomerChnagePassword