import { useContext } from "react";
import { api } from "../../api";
import CustomerContext from "../../../contexts/customerContext/customerContext";
import { BillingType } from "../../../@types/types/billing/billing.type";
import { IInfluencer } from "../../../@types/interface/influencer.interface";

const useCreateInfluencer = () => {
  const addInfluencer = async (influencerData: IInfluencer) => {
    try {
      const response = await api.influencer.createInfluencer(influencerData);
      if (response) {
        console.log("===>response", response);
        return response;
      }
    } catch (error) {
      console.log(error);
    }
  };

  return { addInfluencer };
};

export default useCreateInfluencer;
