import React, { useCallback, useContext, useEffect, useState } from 'react'
import CustomerContext from '../../../contexts/customerContext/customerContext';
import { api } from '../../api';

const useGetPaymentMethod = () => {
	const [paymentMethod, setPaymentMethod] = useState<any | null>(null);
	const { user } = useContext(CustomerContext);

	console.log("===>user", user);

	const getPaymentMethod = useCallback(async () => {
		try {
			console.log("===>calling");
			if (user && user.stripeCustomerId && user.payment_method_id) {
				const response = await api.customer.getCustomerPaymentMethod({
					customerId: user.stripeCustomerId
				})
				if (response) {
					setPaymentMethod(response[0]);
				}
			}
		} catch (error) {
			console.log(error);
		}
	}, [user]);

	useEffect(() => {
		getPaymentMethod();
	}, [getPaymentMethod]);

	return { paymentMethod }
}

export default useGetPaymentMethod