import { request } from "../api";
import { headers } from "../../../config/config";
import { MESSAGE } from "../../../constants/api/message";
import { Params, Payload } from "../../../@types/api/api.types";
import { AUTHORIZATION } from "../../../constants/api/auth";

const { post, get } = request;
const { Authorization, Bearer } = AUTHORIZATION;

const initialRoute = "customers";

export const createLeadBucket = async (payload: Payload) => {
  try {
    const endpoint = `${initialRoute}/customer-lead-bucket`;
    const response = await post(endpoint, payload, {
      ...headers,
    });

    if (response?.status === 200) {
      const { message, result } = response.data;

      if (message === MESSAGE.post.succ) {
        return result; // Return the result
      } else {
        throw new Error("Login failed");
      }
    }
  } catch (error: any) {
    console.error("Error while login:", error.message);
  }
};

export const purchaseLeadFromBucket = async (payload: Payload) => {
  try {
    const endpoint = `${initialRoute}/purchased-lead-bucket`;
    const token = localStorage.getItem("@token");
    const response = await post(endpoint, payload, {
      ...headers,
      [Authorization]: `${Bearer} ${token}`,
    });

    if (response?.status === 200) {
      const { message, result } = response.data;

      if (message === MESSAGE.post.succ) {
        return result; // Return the result
      } else {
        throw new Error("Login failed");
      }
    }
  } catch (error: any) {
    console.error("Error while login:", error.message);
  }
};

export const createMultipleExclusiveLeadBucket = async (payload: Payload) => {
  try {
    const endpoint = `${initialRoute}/create-multiple-customer-exclusive-leads`;
    const response = await post(endpoint, payload, {
      ...headers,
    });

    if (response?.status === 200) {
      const { message, result } = response.data;

      if (message === MESSAGE.post.succ) {
        return result; // Return the result
      } else {
        throw new Error("Login failed");
      }
    }
  } catch (error: any) {
    console.error("Error while login:", error.message);
  }
};

export const getCustomerCartAmmount = async () => {
  try {
    const endpoint = `${initialRoute}/get-cart-ammount`;
    const token = localStorage.getItem("@token");
    const response = await get(endpoint, {
      ...headers,
      [Authorization]: `${Bearer} ${token}`,
    });
    if (response) {
      const {
        data: { message },
      } = response;
      if (message === MESSAGE.get.succ) {
        const {
          data: { result },
        } = response;
        return result;
      }
    }
    // throw new Error();
  } catch (error: any) {
    console.log(error);
    throw error;
  }
};
