import React from "react";
import { IDate } from "../../../@types/interface/date";
import { ILeads } from "../../../@types/interface/leadDetails.interface";

const FormattedDateCellRenderer = ({ data }: { data: ILeads }) => {
	return (
		<div>
			<span> {data.createdAt ? new Date(data.createdAt).toLocaleString() : "N/A"}</span>
		</div>
	);
};

export default FormattedDateCellRenderer;
