import {
	Button,
	Chip,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	InputAdornment,
	Paper,
	TextField,
	Typography,
} from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import WalletOutlinedIcon from "@mui/icons-material/WalletOutlined";
import PhonelinkRingOutlinedIcon from "@mui/icons-material/PhonelinkRingOutlined";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import TransactionDetails from "../../allCustomers/customerDetails/transactionDetails/TransactionDetails";
import { CustomSwitch } from "../../../shared/switch/CustomSwitch";
import UIContext from "../../../../contexts/uiContext/UIContext";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import SaveIcon from "@mui/icons-material/Save";
import ClearIcon from "@mui/icons-material/Clear";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import Alert from "@mui/material/Alert";

import ChangeCardModal from "../../../shared/changeCardModal/ChangeCardModal";
import { api } from "../../../../utils/api";
import CustomerContext from "../../../../contexts/customerContext/customerContext";
import { IWallet } from "../../../../@types/interface/wallet.interface";
import { ITransaction } from "../../../../@types/interface/transaction.interface";
import useQuerySearch from "../../../../utils/hooks/querySearch/useQuerySearch";
import AddBalanceModal from "../../../shared/addBalanceModal/AddBalanceModal";
import useUpdateBalanceByAdmin from "../../../../utils/hooks/updateBalanceByAdmin/useUpdateBalanceByAdmin";
import Modal from "../../../shared/removeCardModal/RemoveCardModal";
import CancelComponent from "./cancelComponent/CancelComponent";
import WalletCardWrapper from "../../../shared/walletCardWrapper/WalletCardWrapper";
import CommonModal from "../../../shared/commonModal/CommonModal";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialogContent-root": {
		padding: theme.spacing(2),
	},
	"& .MuiDialogActions-root": {
		padding: theme.spacing(1),
	},
}));

const CustomerBilling = () => {
	const { user } = useContext(CustomerContext);
	const customerId = useQuerySearch("customerId");
	const { updateWalletBalance } = useUpdateBalanceByAdmin();
	const [isOn, setIsOn] = useState<boolean>(false);
	const { setDashboardHeader } = useContext(UIContext);
	const [wallet, setWallet] = useState<IWallet | null>(null);
	const [subscription, setSubscription] = useState<any>("");
	const [open, setOpen] = React.useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [depositModalOpen, setDepositModalOpen] = useState(false);
	const [transactionDetails, setTransactionDetails] = useState<ITransaction[]>(
		[]
	);
	const [openAddBalanceModal, setOpenAddBalanceModal] = useState(false);
	const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] = useState(false);
	const [isVisible, setIsVisible] = useState(false);

	const handleClose = () => {
		setOpen(false);
	};
	const handleModalClose = () => {
		setIsModalOpen(false);
	};
	const handleModalOpen = () => {
		setIsModalOpen(true);
	};

	const handleOpenDialog = () => {
		setOpen(true);
	};

	const handleOpenDepositModal = () => setDepositModalOpen(true);
	const handleCloseDepositModal = () => setDepositModalOpen(false);

	const handleToggleModal = () => {
		setIsModalOpen(!isModalOpen);
	}

	const handleOpenAddBalanceModal = () => {
		setOpenAddBalanceModal(true);
	};
	const handleCloseAddBalanceModal = () => {
		setOpenAddBalanceModal(false);
	};
	useEffect(() => {
		setDashboardHeader("");
	}, [setDashboardHeader]);

	const handleDeposit = async (amount: number) => {
		try {
			const payload = {
				customer_id: user?._id, // Send customer_id here
				lineItems: [
					{
						price_data: {
							currency: "usd",
							product_data: { name: "Deposit" },
							unit_amount: Math.round(amount * 100), // Convert dollars to cents
						},
						quantity: 1,
					},
				],
				successUrl: `${window.location.origin}/customer/billing/success`,
				cancelUrl: `${window.location.origin}/customer/billing`,
			};

			const response = await api.payment.createPaymentCheckout(payload);
			console.log("========>payment response", response);

			localStorage.setItem("@payment", JSON.stringify(response));

			const sessionUrl = await response.sessionUrl;
			if (sessionUrl) {
				window.location.href = sessionUrl;
			} else {
				// console.error("Failed to create checkout session", session.message);
				// alert("Unable to start checkout session.");
			}
		} catch (error) {
			console.error("Error initiating checkout:", error);
			// alert("An error occurred. Please try again.");
		}
	};

	const getWalletTransaction = useCallback(async () => {
		try {
			const filter = {
				cutomerId: customerId ? customerId : user?._id,
			};
			const response = await api.wallet.getWalletTransaction(filter);
			const { wallet, transaction, subscription_details } = response;
			setWallet(wallet);
			setTransactionDetails(transaction);
			setSubscription(subscription_details);
		} catch (error) {
			console.error("Error getting wallet transaction:", error);
			// alert("An error occurred. Please try again.");
		}
	}, [user, customerId]);

	useEffect(() => {
		getWalletTransaction();
	}, [getWalletTransaction]);

	const handleOpenSubscriptionModal = () => {
		setIsSubscriptionModalOpen(true);
	};

	const handleCloseSubscriptionModal = () => {
		setIsSubscriptionModalOpen(false);
		getWalletTransaction();
	};
	return (
		<div>
			<div className="mt-5 p-5 bg-white shadow-md rounded-lg">
				<div className="flex justify-between items-center mb-4">
					<div className="flex items-center">
						<WalletOutlinedIcon className="mr-2" />
						<label className="font-semibold">Wallet Balance</label>
					</div>

					<div>
						<p className="text-lg font-bold">${wallet?.wallet_balance || 0}</p>
						<p className="text-sm text-gray-500">
							Notify if drops under ${wallet?.dropped_amount_alert || 100}
						</p>
					</div>
					{customerId ? (
						<div>
							<Button
								variant="contained"
								endIcon={<AttachMoneyIcon />}
								className="w-full sm:w-40"
								onClick={handleOpenAddBalanceModal}
							>
								Add balance
							</Button>
							<AddBalanceModal
								open={openAddBalanceModal}
								handleClose={handleCloseAddBalanceModal}
								onConfirm={(amount: string) =>
									updateWalletBalance(parseFloat(amount))
								}
							/>
						</div>
					) : (
						<div>
							<Button
								variant="contained"
								onClick={handleOpenSubscriptionModal}
								className="w-full sm:w-32"
							>
								Deposit
							</Button>
						</div>
					)}

					<CommonModal open={isSubscriptionModalOpen} handleClose={handleCloseSubscriptionModal} key={"wallet_modal"}>
						<WalletCardWrapper
							setWallet={setWallet}
							onClose={handleCloseSubscriptionModal}
							mode="ADD_FUNDS"
						/>
					</CommonModal>
				</div>

				{isVisible ? (
					<div className="text-center text-gray-500">No card details are there</div>
				) : (
					<>
						{user?.is_subscription ? (
							<>
								<div className="flex flex-col items-start justify-start md:border p-5 rounded-lg shadow-lg ">
									<h1 className="text-xl font-bold mb-4">Subscription Details</h1>
									<div className="w-full block items-start md:flex justify-between items-center mb-4">
										<div className="md:items-start mb-4 md:mb-0">
											<CreditCardOutlinedIcon className="mr-2" />
											<label className="font-semibold">Card Details</label>
										</div>

										<div className="mb-4 md:mb-0">
											<p className="text-lg font-bold">XXXX XXXX XXXX 4242</p>
											<p className="text-sm text-gray-500">Card number</p>
										</div>
										<div>
											<Button
												variant="contained"
												className="bg-red-500 text-white"
												endIcon={<DeleteIcon />}
												onClick={handleModalOpen}
											>
												Cancel Subscription
											</Button>
											{isModalOpen && (
												<CommonModal handleClose={() => setIsModalOpen(false)} open={isModalOpen}>
													<CancelComponent handleToggleModal={handleToggleModal} />
												</CommonModal>
											)}
										</div>
									</div>

									<div className="w-full flex flex-col md:flex-row gap-4 items-start mb-4">
										<div className="md:mb-0 ">
											<AutorenewIcon className="mr-2" />
											<label className="font-semibold">Next due in: </label>
										</div>

										<div>
											<p className="text-lg font-bold">
												{subscription?.next_due_date
													? new Date(
														subscription.next_due_date
													).toLocaleDateString("en-US", {
														year: "numeric",
														month: "long",
														day: "numeric",
													})
													: ""}
											</p>
										</div>
									</div>
									<div className="w-full flex gap-4 md:flex-row items-start md:items-center justify-start">
										<div className="flex items-center md:mb-0">
											<AttachMoneyIcon className="mr-2" />
											<label className="font-semibold">Due amount: </label>
										</div>

										<div>
											<p className="text-xl font-bold">
												${subscription?.due_amount}
											</p>
										</div>
									</div>
								</div>
							</>
						) : null}
					</>
				)}
			</div>
			<BootstrapDialog
				onClose={handleClose}
				aria-labelledby="customized-dialog-title"
				open={open}
			>
				<DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
					Auto Recharge Settings
				</DialogTitle>
				<IconButton
					aria-label="close"
					onClick={handleClose}
					sx={(theme: any) => ({
						position: "absolute",
						right: 8,
						top: 8,
						color: theme.palette.grey[500],
					})}
				>
					<CloseIcon />
				</IconButton>
				<DialogContent dividers>
					<Alert
						severity="warning"
						sx={{ fontSize: "1rem", width: "500px" }}
						className="disclaimer-container"
					>
						<strong className="disclaimer">Disclaimer:</strong> If this is your
						first time enabling auto-recharge & your balance is <b>$0</b> ,
						auto-recharge will go on effect in a few minutes. If you do a manual
						deposit you will be charged twice.
					</Alert>
					<div className="flex-headings">
						<h3>Enable auto-recharge</h3>
						<CustomSwitch />
					</div>

					<div>
						<h4>Deposit the following amount</h4>

						<TextField
							id="input-with-icon-textfield"
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<AttachMoneyIcon />
									</InputAdornment>
								),
							}}
							variant="outlined"
							sx={{ width: "500px" }}
						/>
					</div>
					<div>
						<h4>When my balance falls below</h4>

						<TextField
							id="input-with-icon-textfield"
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<AttachMoneyIcon />
									</InputAdornment>
								),
							}}
							variant="outlined"
							sx={{ width: "500px" }}
						/>
					</div>
				</DialogContent>
				<DialogActions>
					<Button
						autoFocus
						onClick={handleClose}
						variant="outlined"
						endIcon={<ClearIcon />}
					>
						Cancel
					</Button>
					<Button
						autoFocus
						onClick={handleClose}
						variant="contained"
						className="button-blue"
						sx={{ width: "80px", margin: "10px" }}
						endIcon={<SaveIcon />}
					>
						Save
					</Button>
				</DialogActions>
			</BootstrapDialog>
			<TransactionDetails transactionDetails={transactionDetails} />
		</div>
	);

};

export default CustomerBilling;
