import { useContext, useEffect, useState } from "react"
import CustomerContext from "../../../contexts/customerContext/customerContext"
import { api } from "../../api"

const useGetWalletDiscountPrice = () => {
	const { user } = useContext(CustomerContext);
	const [couponCode, setCouponCode] = useState<string>("");
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [hasError, setHasError] = useState<boolean>(false);
	const [hasAppliedCoupon, setHasAppliedCoupon] = useState<boolean>(false);
	const [discountPrice, setDiscountPrice] = useState<number | null>(null);

	const handleChangeCoupon = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		setHasAppliedCoupon(false);
		setHasError(false);
		setCouponCode(value);
	}

	const getDiscountPrice = async (ammount: number) => {
		try {
			setIsLoading(true)
			const filter = {
				ammount,
				couponId: couponCode,
				customerId: user?.stripeCustomerId
			}
			const response = await api.wallet.getWalletDiscountPrice(filter);
			console.log("===>", response);
			if (response !== null) {
				setDiscountPrice(response)
				setHasAppliedCoupon(true)
			}
			else {
				setHasError(true)
			}
		} catch (error: any) {
			console.error("Error while getting discount price:", error)
			setHasError(true);
			throw error
		} finally {
			setIsLoading(false)
		}
	}

	useEffect(() => {
		if (user?.coupon_code && user?.coupon_code !== "" && !user?.has_applied_coupon) {
			setCouponCode(user.coupon_code)
		}
	}, [user])

	return { getDiscountPrice, discountPrice, isLoading, hasAppliedCoupon, couponCode, handleChangeCoupon, hasError }
}

export default useGetWalletDiscountPrice