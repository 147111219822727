import React, { useCallback, useEffect, useRef, useState } from "react";
import { api } from "../../../utils/api";
import { Box, Button, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import useQuerySearch from "../../../utils/hooks/querySearch/useQuerySearch";
import usePurchaseExclusiveLeads from "../../../utils/hooks/purchaseExclusiveLeads/usePurchaseExclusiveLeads";
import useUpdateWallet from "../../../utils/hooks/updateWallet/useUpdateWallet";

const BillingSuccess = () => {
	const navigate = useNavigate();
	const hasUpdated = useRef<boolean>(false); // Track if the update has already happened
	const item = useQuerySearch("item");
	const { handleConfirm } = usePurchaseExclusiveLeads();
	const { updateWallet } = useUpdateWallet();
	const pay = localStorage.getItem("@payment");

	const completePurchase = useCallback(async () => {
		if (hasUpdated.current) {
			return;
		}
		if (pay) {
			const paymentData = JSON.parse(pay);

			await updateWallet({
				customer_id: paymentData.customer_id,
				amountInDollar: paymentData.amountInDollar,
				paymentType: paymentData.paymentType,
				transactionId: paymentData.transactionId
			});
			hasUpdated.current = true; // Set the ref to true
			if (item) {
				await handleConfirm(item);
			}
		} else {
			console.log("No payment data found in localStorage.");
		}
	}, [pay, item, handleConfirm, updateWallet]);

	useEffect(() => {
		completePurchase();
	}, [completePurchase, hasUpdated]); // Add updateWallet as a dependency

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				height: "100vh",
				bgcolor: "#f9f9f9"
			}}
		>
			{/* Back Button */}
			<Box
				sx={{
					position: "absolute",
					top: "20px",
					left: "20px"
				}}
			>
				<Button startIcon={<ArrowBackIcon />} onClick={() => navigate("/customer/billing")}>
					Back
				</Button>
			</Box>

			{/* Animated Checkmark */}
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					flexDirection: "column",
					mt: -10
				}}
			>
				<DotLottieReact
					src="https://lottie.host/7b3012b9-807d-4cbd-baf1-5cd9b6ea98c9/CeOEs3Jgt9.lottie"
					//   loop
					autoplay
					style={{ height: "150px", width: "150px" }}
				/>
				<Typography
					sx={{
						mt: 2,
						fontSize: "24px",
						fontWeight: "bold",
						color: "#333"
					}}
				>
					Your money deposit is successful!
				</Typography>
				<Button startIcon={<ArrowBackIcon />} onClick={() => navigate("/customer/billing")}>
					Go Back
				</Button>
			</Box>
		</Box>
	);
};

export default BillingSuccess;
