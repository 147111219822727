import { Button, IconButton } from "@mui/material";
import { IExclusiveLeads } from "../../../../../../../@types/interface/exclusiveLeads.interface";
import useDeletePremiumLeadsBucket from "../../../../../../../utils/hooks/deletePremiumLeadsBucket/useDeletePremiumLeadsBucket";
import useControlModal from "../../../../../../../utils/hooks/controlModal/useControlModal";
import ConfirmRemove from "./confirmRemove/ConfirmRemove";

const RemoveCellRenderer = ({ data }: { data: IExclusiveLeads }) => {
	const { deletePremiumLeadsBucket } = useDeletePremiumLeadsBucket();
	const handleDeleteLead = () => {
		deletePremiumLeadsBucket(data._id);
		handleCloseModal();
	};
	const { modalOpen, handleOpenModal, handleCloseModal } = useControlModal();
	return (
		<div>
			<Button variant="outlined" className="purple-outlined-button" onClick={handleOpenModal}>
				Remove lead
			</Button>

			<ConfirmRemove
				modalOpen={modalOpen}
				handleCloseModal={handleCloseModal}
				handleDeleteLead={handleDeleteLead}
			/>
		</div>
	);
};

export default RemoveCellRenderer;
