import { Box, Tab, Tabs } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import a11yProps from "../../../../constants/tabPanelProps/ayProps";
import TabPanel from "../../../shared/tabPanel/TabPanel";
import UIContext from "../../../../contexts/uiContext/UIContext";
import CustomerAnalytics from "./analytics/CustomerAnalytics";
import CustomerData from "./customerData/CustomerData";
import CustomerLeadsData from "./customerLeadsData/CustomerLeadsData";
import PaymentDetails from "./paymentDetails/PaymentDetails";
import Billing from "./billing/Billing";
import CustomerBilling from "../../customerPortal/customerBilling/CustomerBilling";
import ExclusiveLeadDetails from "./customerLeadsData/exclusiveLeadDetails/ExclusiveLeadDetails";
import PremiumLeadDetails from "./customerLeadsData/premiumLeadDetails/PremiumLeadDetails";

const CustomerDetails = () => {
	const [value, setValue] = useState<number>(0);
	const { setDashboardHeader } = useContext(UIContext);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	useEffect(() => {
		setDashboardHeader("Customer Details");
	}, [setDashboardHeader]);
	return (
		<div>
			<Box sx={{ width: "100%" }}>
				<Box>
					<Tabs
						value={value}
						onChange={handleChange}
						aria-label="basic tabs example"
						sx={{ fontSize: "20px" }}
						TabIndicatorProps={{
							style: {
								backgroundColor: "#7c00fe"
							}
						}}
						className="glb-tab-panel"
					>
						{/* <Tab label="Customer Analytics" {...a11yProps(0)} className="tab-label" /> */}
						<Tab label="Customer Details" {...a11yProps(0)} className="tab-label" />
						<Tab label="Purchased Exclusive Leads" {...a11yProps(1)} className="tab-label" />
						<Tab label="Purchased Premium Leads" {...a11yProps(2)} className="tab-label" />
						<Tab label="Wallet Balance" {...a11yProps(3)} className="tab-label" />
						<Tab label="Notification Recieved" {...a11yProps(4)} className="tab-label" />
					</Tabs>
				</Box>
				{/* <TabPanel value={value} index={0}>
					<CustomerAnalytics />
				</TabPanel> */}
				<TabPanel value={value} index={0}>
					<CustomerData />
				</TabPanel>
				<TabPanel value={value} index={1}>
					{/* <CustomerLeadsData /> */}
					<ExclusiveLeadDetails />
				</TabPanel>
				<TabPanel value={value} index={2}>
					<PremiumLeadDetails />
				</TabPanel>
				<TabPanel value={value} index={3}>
					<CustomerBilling />
				</TabPanel>
			</Box>
		</div>
	);
};

export default CustomerDetails;
