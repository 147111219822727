import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { PremiumLeadsDialogProps } from "../../../../../@types/props/premiumLeadsDialog.props";
import { useContext } from "react";
import CustomerContext from "../../../../../contexts/customerContext/customerContext";
import { useNavigate } from "react-router-dom";

const PremiumLeadsDialog = ({ open, handleClose, selectedLead, handleBuyLead }: PremiumLeadsDialogProps) => {
	const { user } = useContext(CustomerContext);
	const navigate = useNavigate();
	const handleSubscribe = () => {
		navigate(`/customer/subscription/?item=${selectedLead._id}`);
	};
	return (
		<Dialog open={open} onClose={handleClose} className="">
			{(user?.premium_leads_limit ?? 0) <= 0 ? (
				<>
					<DialogTitle className="text-center text-xl font-semibold">Subscription Required</DialogTitle>
					<DialogContent className="dialog-content text-center">
						<span className="dialog-content-subtitle block mb-4">
							Please subscribe to get access to premium leads.
						</span>
						<Button
							onClick={handleSubscribe}
							color="primary"
							variant="contained"
							className="button-bright-violet"
						>
							Subscribe
						</Button>
					</DialogContent>
					<DialogActions className="justify-center">
						<Button onClick={handleClose} color="primary">
							Cancel
						</Button>
					</DialogActions>
				</>
			) : (
				<>
					<DialogTitle>Do you want to buy this lead?</DialogTitle>
					<DialogContent className="dialog-content">
						<span className="dialog-content-subtitle">
							YOU HAVE {user?.premium_leads_limit} PREMIUM LEADS REMAINING
						</span>
						<span>Lead Type : {selectedLead.lead_type}</span>
						<span>Location : {selectedLead.city}</span>
						<span>Zip : {selectedLead.zip}</span>
						<span>Deal Type : {selectedLead.deal_type}</span>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose} color="error">
							CANCEL
						</Button>
						<Button onClick={handleBuyLead} color="primary">
							CONFIRM
						</Button>
					</DialogActions>
				</>
			)}
		</Dialog>
	);
};

export default PremiumLeadsDialog;
