import { useContext } from "react";
import CustomerContext from "../../../contexts/customerContext/customerContext";
import { api } from "../../api";
import { useNavigate } from "react-router-dom";
import useQuerySearch from "../querySearch/useQuerySearch";
import useOpenConfetti from "../openConfetti/useOpenConfetti";

const usePurchasePremiumLeads = () => {
  const { user, setUser } = useContext(CustomerContext);
  const navigate = useNavigate();
  const item = useQuerySearch("item");
  const { handleExplosion } = useOpenConfetti();

  const buyLeadHandler = async (leadObjectId: string) => {
    try {
      if (user && user?.premium_leads_limit > 0) {
        const payload = {
          customer_object_id: user?._id,
          exclusive_lead_object_id: leadObjectId,
        };
        const res = await api.leads.postCustomerExclusiveLeads(payload);
        if (res) {
          const prevUser = user;
          prevUser.premium_leads_limit -= 1;
          setUser(prevUser);

          handleExplosion();
          setTimeout(() => {
            navigate("/customer/purchased-leads/premium-leads");
          }, 1000);
        }
      } else {
        if (item) {
          navigate(`/customer/subscription?item=${item}`);
        } else {
          navigate("/customer/subscription");
        }
      }
    } catch (error) {
      alert("Insufficient balance !!");
      console.log("Error ==>", error);
    }
  };
  return { buyLeadHandler };
};

export default usePurchasePremiumLeads;
