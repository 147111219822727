import { request } from "../api";
import { headers } from "../../../config/config";
import { MESSAGE } from "../../../constants/api/message";
import { Params, Payload } from "../../../@types/api/api.types";
import { AUTHORIZATION } from "../../../constants/api/auth";

const { Authorization, Bearer } = AUTHORIZATION;
const { get, patch, post, del } = request;

const initialRoute = "customers";

export const getCustomer = async (filterQuery: Params) => {
  try {
    const endpoint = `${initialRoute}/get-customer-list`;
    const response = await get(
      endpoint,
      {
        ...headers,
      },
      filterQuery
    );
    if (response) {
      const {
        data: { message },
      } = response;
      if (message === MESSAGE.get.succ) {
        const {
          data: { result, pagination },
        } = response;
        return { result, pagination };
      }
    }
    throw new Error();
  } catch (error: any) {
    console.log(error);
    throw error;
  }
};

export const getCustomerDetails = async (filterQuery: Params) => {
  try {
    const endpoint = `${initialRoute}/get-customer-details`;
    const response = await get(
      endpoint,
      {
        ...headers,
      },
      filterQuery
    );
    if (response) {
      const {
        data: { message },
      } = response;
      if (message === MESSAGE.get.succ) {
        const {
          data: { result },
        } = response;
        return result;
      }
    }
    throw new Error();
  } catch (error: any) {
    console.log(error);
    throw error;
  }
};

export const getCustomerDetailsByEmail = async (filterQuery: Params) => {
  try {
    const endpoint = `${initialRoute}/google-login-customer`;
    const response = await get(
      endpoint,
      {
        ...headers,
      },
      filterQuery
    );
    if (response) {
      const {
        data: { message },
      } = response;
      if (message === MESSAGE.get.succ) {
        const {
          data: { result, token },
        } = response;
        return { result, token };
      }
    }
    throw new Error();
  } catch (error: any) {
    console.log(error);
    throw error;
  }
};

export const getCustomerPaymentMethod = async (filterQuery: Params) => {
  try {
    const endpoint = `${initialRoute}/get-customer-payment-method`;
    const response = await get(
      endpoint,
      {
        ...headers,
      },
      filterQuery
    );
    if (response) {
      const {
        data: { message },
      } = response;
      if (message === MESSAGE.get.succ) {
        const {
          data: { result },
        } = response;
        return result;
      }
    }
    throw new Error();
  } catch (error: any) {
    console.log(error);
    throw error;
  }
};

export const getCustomerSubscriptionDetails = async (filterQuery: Params) => {
  try {
    const endpoint = `${initialRoute}/get-subscription-details`;
    const response = await get(
      endpoint,
      {
        ...headers,
      },
      filterQuery
    );
    if (response) {
      const {
        data: { message },
      } = response;
      if (message === MESSAGE.get.succ) {
        const {
          data: { result },
        } = response;
        return result;
      }
    }
    throw new Error();
  } catch (error: any) {
    console.log(error);
    throw error;
  }
};

export const updateCustomerPhoto = async (formdata: FormData) => {
  try {
    const endpoint = `${initialRoute}/update-customer-photo`;
    const response = await patch(endpoint, formdata, {
      ...headers,
      "Content-Type": "multipart/form-data",
    });
    if (response) {
      const {
        data: { message, result },
      } = response;
      if (message === MESSAGE.patch.succ) {
        return result;
      }
    }
    throw new Error();
  } catch (error: any) {
    console.log(error);
    throw error;
  }
};

export const createCustomerCart = async (payload: Payload) => {
  try {
    const endpoint = `${initialRoute}/create-customer-cart`;
    const response = await post(endpoint, payload, {
      ...headers,
      "Content-Type": "application/json",
    });
    if (response) {
      const {
        data: { message, result },
      } = response;
      if (message === MESSAGE.post.succ) {
        return result;
      }
    }
    throw new Error();
  } catch (error: any) {
    console.log(error);
    throw error;
  }
};

export const getCustomerCartLeads = async (filterQuery: Params) => {
  try {
    const endpoint = `${initialRoute}/get-customer-cart`;
    const response = await get(
      endpoint,
      {
        ...headers,
      },
      filterQuery
    );
    if (response) {
      const {
        data: { message },
      } = response;
      if (message === MESSAGE.get.succ) {
        const {
          data: { result, pagination },
        } = response;
        return { result, pagination };
      }
    }
    // throw new Error();
  } catch (error: any) {
    console.log(error);
    throw error;
  }
};

export const deleteLeadsFromCart = async (params: Params) => {
  try {
    if (!params.lead_object_id) {
      throw new Error("Lead ID is required");
    }

    const token = localStorage.getItem("@token"); // Fetch token

    const endpoint = `${initialRoute}/delete-customer-cart/${params.lead_object_id}`;

    const response = await del(endpoint, {
      ...headers,
      [Authorization]: `${Bearer} ${token}`,
    });

    if (response) {
      const {
        data: { message, result },
      } = response;
      if (message === MESSAGE.delete.succ) {
        return result;
      }
    }
  } catch (error: any) {
    console.error("Error while deleting lead:", error.message);
    throw error;
  }
};
