import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { IExclusiveLeads } from "../../../../../@types/interface/exclusiveLeads.interface";

const PremiumLeadCellRenderer = ({ data }: { data: IExclusiveLeads }) => {
	const navigate = useNavigate();

	const handleRouteToLeadDetails = () => {
		navigate(`/super-admin/premium-lead-details?exlid=${data._id}`);
	};
	return (
		<div>
			<Button variant="outlined" className="purple-outlined-button" onClick={handleRouteToLeadDetails}>
				See Details
			</Button>
		</div>
	);
};

export default PremiumLeadCellRenderer;
