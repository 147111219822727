import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { IExclusiveLeads } from "../../../../../@types/interface/exclusiveLeads.interface";
import Tooltip from "@mui/material/Tooltip";
import { Button } from "@mui/material";
import { api } from "../../../../../utils/api";

const PremiumLeadsDeleteCellRenderer = ({
	data,
}: {
	data: IExclusiveLeads;
}) => {
	const navigate = useNavigate();
	const handleDelete = async () => {
		if (!data._id) {
			console.error("Lead ID is missing");
			return;
		}

		try {
			const response = await api.leads.deletePremiumLeads({
				premium_lead_object_id: data._id,
			});

			if (response) {
				alert("Lead deleted successfully");
				window.location.reload();
			}
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<div>
			{/* Tooltip to indicate why the button is disabled */}
			<Tooltip
				title={
					data.lead_purchased_left < 3
						? "This lead cannot be deleted , lead limit is less than 3"
						: "Delete lead"
				}
			>
				<span>
					<Button onClick={handleDelete} disabled={data.is_purchased}>
						<DeleteIcon
							sx={{ color: data.lead_purchased_left < 3 ? "gray" : "red" }}
						/>
					</Button>
				</span>
			</Tooltip>
		</div>
	);
};

export default PremiumLeadsDeleteCellRenderer;
