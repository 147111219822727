/* eslint-disable */
import { useState, useEffect, useRef, Suspense, useCallback, useContext } from "react";
import {
	TextField,
	Paper,
	Grid,
	Button,
	Select,
	MenuItem,
	Tooltip,
	Chip,
	Checkbox,
	CheckboxProps
} from "@mui/material";
// import { withStyles } from "@material-ui/core/styles";
import { Theme } from "@mui/material/styles";

import { useNavigate } from "react-router-dom";
import { green } from "@mui/material/colors";
// import { SnackbarAPI } from "../../../../shared";
// import { SnackbarCloseReason } from "@material-ui/core";

import SendIcon from "@mui/icons-material/Send";
import PublishIcon from "@mui/icons-material/Publish";

import "../../../../../globalStyles/paperFormStyles.css";
import { api } from "../../../../../utils/api";
import UIContext from "../../../../../contexts/uiContext/UIContext";
import { ADMIN_DASHBOARD_HEADER } from "../../../../../constants/captions/adminDashboardHeader";
import downloadFile from "../../../../../utils/commonFunctions/downloadFile";
import shortenFileName from "../../../../../utils/commonFunctions/shortenFileName";
import { leadBulkUploadTemplateFile } from "../../../../../assets";
import excelToJson from "../../../../../utils/commonFunctions/excelToJson";

const GeneralLeadBulkUpload = (): JSX.Element => {
	const [bulkUploadForm, setBulkUploadForm] = useState({});
	const { setDashboardHeader } = useContext(UIContext);
	const [hasSubmitClick, setHasCreateClick] = useState<boolean>(false);
	const [excelFile, setExcelFile] = useState(null);
	const [errorResult, setErrorResult] = useState([]);
	const [loading, setLoading] = useState<boolean>(false);
	const navigate = useNavigate();

	const handleDocumentChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const { files } = event.target;

			if (files) {
				setExcelFile(files[0] as any);
				event.target.value = "";
			}
		},
		[excelFile]
	);

	const handleDeleteDocument = useCallback(() => {
		setExcelFile(null);
	}, [excelFile]);

	const handleSubmitClick = async () => {
		try {
			setLoading(true);
			if (excelFile) {
				const generalLeadList = await excelToJson(excelFile);
				const payload = {
					generalLeadList
				};
				const response = await api.leads.generalLeadsBulkUpload(payload);
				if (response) {
					alert("Leads submitted successfully!");
					navigate("/super-admin/leads/general-leads");
				}
			} else {
				alert("Please upload a file");
			}
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	const errorText = (errorResult: { errorType: string; errorText: string; errorSSNs: string[] }[]) => {
		const errorTexts = [];
		for (let i = 0; i < errorResult.length; i++) {
			const element = errorResult[i];
			errorTexts.push(<h2>Error Type: {element.errorType}</h2>);
			errorTexts.push(
				<h3>
					Error Text: <i>{element.errorText}</i>
				</h3>
			);
			errorTexts.push(<h3>SSNs from defective excel rows are: </h3>);
			for (let j = 0; j < element.errorSSNs.length; j++) {
				const ssn = element.errorSSNs[j];
				errorTexts.push(<h4>{ssn}</h4>);
			}
		}
		return <div>{errorTexts}</div>;
	};

	const resultShow = (successResult: any) => {
		const successTexts = [];
		console.log(successResult);
		for (let i = 0; i < successResult.length; i++) {
			const element = successResult[i];

			successTexts.push(<h3>{element}</h3>);
		}
		return <div>{successTexts}</div>;
	};

	const duplicateResultShow = (duplicateSSNs: string[], code: string) => {
		const _duplicateSSNS = [];
		for (let i = 0; i < duplicateSSNs.length; i++) {
			const element = duplicateSSNs[i];

			_duplicateSSNS.push(<h3>{element}</h3>);
		}
		if (code == "PC") {
			return (
				<div>
					<h3>Duplicate PC Found</h3>
					<div>{_duplicateSSNS}</div>
				</div>
			);
		} else if (code == "ST") {
			return (
				<div>
					<h3>Duplicate ST Found</h3>
					<div>{_duplicateSSNS}</div>
				</div>
			);
		} else {
			return <div>ERROR</div>;
		}
	};

	useEffect(() => {
		setDashboardHeader(ADMIN_DASHBOARD_HEADER.general_lead_bulk_upload);
	}, [setDashboardHeader]);

	useEffect(() => {
		setBulkUploadForm(
			Object.assign(
				{},
				{
					"Lead Bulk Upload": [
						{
							label: "Add Leads Excel",
							fieldLabel: "Upload File",
							name: "excel_file",
							onChange: (event: React.ChangeEvent<HTMLInputElement>) => handleDocumentChange(event),
							value: excelFile,
							type: "file"
						}
					]
				}
			)
		);
	}, [excelFile]);

	return (
		<div className="create-dependent" id="create-dependent">
			{/* <Suspense fallback={<div />}>
				<SnackbarAPI snackbarProps={snackbarAPICallProps} />
			</Suspense> */}
			<>
				<div className="pf-action-button-container" id="pf-action-button-container">
					<Button
						className="button-violet"
						onClick={handleSubmitClick}
						variant="contained"
						// disabled={hasSubmitClick}
						// disableElevation={hasSubmitClick}
						style={{
							cursor: hasSubmitClick ? "not-allowed" : "pointer",
							pointerEvents: "unset"
						}}
					>
						<span className="button-label-with-icon">Submit</span>
						<span>
							<SendIcon className="button-icon" />
						</span>
					</Button>
				</div>
				<Grid container spacing={1} className="pf-grid-container">
					{Object.entries(bulkUploadForm).map(([key, value]: any, index) => {
						return (
							<div key={index} className="pf-div-container">
								<div className="pf-title">{key}</div>
								<Suspense fallback={<div />}>
									<Paper className="pf-paper-container" elevation={3} variant="outlined">
										{value.map((field: any, index: number) => {
											return (
												<Grid key={index} item xl={12} lg={12} md={12} sm={12} xs={12}>
													<Grid container spacing={1} className="pf-label">
														<Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
															<div
																className={
																	field.name === "event_document"
																		? "pf-label-text"
																		: "pf-label-text required"
																}
																id="pf-label-text"
															>
																{field.name === "excel_file"
																	? field.fieldLabel
																	: field.label}
															</div>
														</Grid>
														<Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
															{field.type === "textfield" ? (
																<TextField
																	className="text-field"
																	id={
																		field.name === "country" ||
																		field.schema === "employee"
																			? "disabled-input-text-field"
																			: "input-text-field"
																	}
																	name={field.name}
																	placeholder={field.placeholder}
																	value={field.value}
																	onChange={field.onChange}
																	variant="outlined"
																	InputProps={{
																		readOnly: false
																	}}
																	//style={{ width: "70%", borderRadius: 50 }}
																	//disabled={!isButtonSelected}
																	//disableElevation={!isButtonSelected}
																	style={{
																		width: "340px",
																		borderRadius: 50
																	}}
																	disabled={
																		field.name === "country" ||
																		field.schema === "employee"
																			? true
																			: false
																	}
																/>
															) : field.type === "select" ? (
																<>
																	<Select
																		id="text-align-options"
																		name={field.name}
																		value={field.value}
																		onChange={field.onChange}
																		//disabled={!isButtonSelected}
																		//disableElevation={!isButtonSelected}
																		style={{
																			//cursor: !isButtonSelected ? "not-allowed" : "pointer",
																			pointerEvents: "unset",
																			minWidth: 180,
																			textAlign: "left",
																			// zIndex: 10000,
																			overflowY: "visible"
																		}}
																		MenuProps={{
																			style: { zIndex: 35960 }
																		}}
																		inputProps={{
																			"aria-label": "Without label",
																			readOnly: false
																		}}
																	>
																		<MenuItem value="" disabled>
																			Select Value
																		</MenuItem>
																		{field.options.map((option: string) => (
																			<MenuItem key={option} value={option}>
																				{option}
																			</MenuItem>
																		))}
																	</Select>
																</>
															) : field.type === "checkbox" ? (
																<>
																	<div className="check-support" id="check-support">
																		<span>
																			<Checkbox
																				onChange={field.onChange}
																				name={field.name}
																				checked={field.value}
																			/>
																		</span>
																		<span
																			className="pf-label-text"
																			id="pf-label-text"
																		>
																			{field.label}
																		</span>
																	</div>
																</>
															) : field.type === "file" && field.value === null ? (
																<>
																	<input
																		className={"document-upload"}
																		id={"document-upload"}
																		name={field.name}
																		type="file"
																		onChange={handleDocumentChange}
																		style={{ display: "none" }}
																		accept=".xlsx, .csv"
																	/>
																	<label htmlFor={"document-upload"}>
																		<Button
																			className="button"
																			component="span"
																			variant="contained"
																			style={{
																				backgroundColor: "#3A4652",
																				color: "#ffff",
																				width: 210
																			}}
																		>
																			<span
																				className="button-label-with-icon"
																				style={{ color: "#ffff" }}
																			>
																				{field.label}
																			</span>
																			<span>
																				<PublishIcon
																					className="button-icon"
																					style={{ color: "#ffff" }}
																				/>
																			</span>
																		</Button>
																		<span
																			className="demo-file-label"
																			style={{
																				cursor: "pointer",
																				fontSize: "0.9rem",
																				marginLeft: "0.8rem"
																			}}
																		>
																			<a
																				href={leadBulkUploadTemplateFile}
																				download="Lead Bulk Upload Template File"
																				style={{
																					textDecoration: "none"
																				}}
																			>
																				(Download Template File)
																			</a>
																		</span>
																	</label>
																</>
															) : (
																<Grid
																	item
																	className="chipset"
																	xl={4}
																	lg={4}
																	md={12}
																	sm={12}
																	xs={12}
																>
																	{field.value ? (
																		<Tooltip title={"Click to download"} arrow>
																			<Chip
																				label={
																					field.value
																						? shortenFileName(
																								field.value.name
																							)
																						: "No files added"
																				}
																				onDelete={() => handleDeleteDocument()}
																				onClick={() =>
																					downloadFile(field.value)
																				}
																			/>
																		</Tooltip>
																	) : null}
																</Grid>
															)}
														</Grid>
													</Grid>
												</Grid>
											);
										})}
									</Paper>
								</Suspense>
							</div>
						);
					})}
				</Grid>
				{/* <div style={{ textAlign: "center" }}>
					<div style={{ marginTop: "10px" }}>{errorResult.length > 0 ? errorText(errorResult) : null}</div>
					<div style={{ marginTop: "10px" }}>{result.length > 0 ? resultShow(result) : null}</div>

					<div style={{ marginTop: "10px" }}>
						{response.duplicatePCSSNs.length > 0
							? duplicateResultShow(response.duplicatePCSSNs, "PC")
							: null}
					</div>
					<div style={{ marginTop: "10px" }}>
						{response.duplicateSTSSNs.length > 0
							? duplicateResultShow(response.duplicateSTSSNs, "ST")
							: null}
					</div>
				</div> */}
			</>
		</div>
	);
};
export default GeneralLeadBulkUpload;
