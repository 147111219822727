
import React, { useCallback, useContext, useEffect, useState } from "react";
// import GoogleBtn from "../../GoogleBtn/GoogleBtn";
// import OtpModal from "../otpModal/OtpModal";

import { useNavigate } from "react-router-dom";
import { ROLES } from "../../../constants/roles/Roles";
import { ICustomer } from "../../../@types/interface/Customer.interface";
import { api } from "../../../utils/api";
import CustomerContext from "../../../contexts/customerContext/customerContext";
import AdminContext from "../../../contexts/adminContext/adminContext";
import OtpModal from "../otpModal/OtpModal";
import useQuerySearch from "../../../utils/hooks/querySearch/useQuerySearch";
import SignInGoogle from "../signinGoogle/SignInGoogle";
import GoogleLoginBtn from "../gooleLoginBtn/GoogleLogin";
import { newleeyBlueBrandLogo } from "../../../assets";
import { Bounce, toast, ToastContainer } from "react-toastify";
import { redirectToHome } from "../../../utils/commonFunctions/redirectToHome";
import { LOGIN_REDIRECTION } from "../../../config/config";
import useGetOtp from "../../../utils/hooks/useGetOtp/useGetOtp";

const Register = () => {
	const navigate = useNavigate();
	const { setUser } = useContext(CustomerContext);
	const { setAdmin } = useContext(AdminContext);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const type = useQuerySearch("type");
	const item = useQuerySearch("item");
	const referralCode = useQuerySearch("referralCode");

	const [userPayload, setUserPayload] = useState<Partial<ICustomer>>({
		first_name: "",
		middle_name: "",
		last_name: "",
		email: "",
		role: "CUSTOMER", // Default role
		password: "",
		coupon_code: null
	});

	const { handleSendOtp, loading, otp } = useGetOtp();

	const handleChangeUserPayload = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		const {
			target: { name, value }
		} = event;

		setUserPayload((prevState: any) => ({
			...prevState,
			[name]: value
		}));
	};

	const handleGetOtp = async (event: React.FormEvent) => {
		event.preventDefault();
		if (userPayload.email) {
			await handleSendOtp(userPayload.email);
		}
	};

	const handleSignup = async () => {
		try {
			const response = await api.auth.signupUser(userPayload);
			console.log("===>called", response);
			if (response.role === ROLES.customer) {
				setUser(response);
				if (type && item) {
					navigate(`/customer/${type}-leads?item=${item}`);
				} else {
					navigate("/customer/premium-leads");
				}
				alert("Registration successful");
			} else if (response.role === ROLES.super_admin) {
				setAdmin(response);
				navigate("/super-admin/dashboard");
				alert("Registration successful");
			} else if (response.role === ROLES.admin) {
				setAdmin(response);
				navigate("/admin/dashboard");
				alert("Registration successful");
			} else {
				alert("Registration failed");
			}
		} catch (error) {
			console.error("Error signup registration", error);
			alert("failed to sign up");
		} finally {
			setIsModalOpen(false);
		}
	};

	useEffect(() => {
		if (referralCode) {
			setUserPayload((prevState: any) => ({
				...prevState,
				coupon_code: referralCode
			}));
		}
	}, [referralCode]);

	useEffect(() => {
		if (otp !== "") {
			setIsModalOpen(true);
		}
	}, [otp])
	return (
		<>
			{/* {user && <Navigate to="/" replace={true} />} */}

			<div className="font-[sans-serif] text-[#333] w-full bg-white">
				<ToastContainer
					position="top-right"
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick={false}
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
					theme="colored"
					className={"mt-20"}
				/>
				<div className="min-h-screen flex flex-col items-center justify-center lg:p-6 p-4">
					<div className="w-full flex flex-col items-center justify-center">
						<div className="mt-2 w-full px-6 py-8 space-y-6 max-w-md max-md:mx-auto">
							{/* <div className="flex justify-center text-center w-full">
									<img className="h-auto align-center" src={logo} alt="logo" />
								</div> */}

							<div className="flex justify-center">
								<img
									src={newleeyBlueBrandLogo}
									alt="newleey-blue-brand-logo"
									className="w-1/2 cursor-pointer"
									onClick={redirectToHome}
								/>
							</div>

							<h3 className="text-3xl font-extrabold mb-12 text-center">Create Account Free !</h3>

							<form onSubmit={handleGetOtp}>
								<div className="flex flex-row mb-3 items-center justify-center gap-1">
									<input
										name="first_name"
										type="text"
										autoComplete="first name"
										className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-4 py-3.5  "
										placeholder="First Name"
										value={userPayload?.first_name}
										onChange={handleChangeUserPayload}
										required
									/>

									<input
										name="last_name"
										type="text"
										autoComplete="last name"
										className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-4 py-3.5  "
										placeholder="Last Name"
										value={userPayload?.last_name}
										onChange={handleChangeUserPayload}
										required
									/>
								</div>
								<div className="mb-3">
									<input
										name="email"
										type="email"
										autoComplete="your email"
										className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-4 py-3.5  "
										placeholder="Email"
										value={userPayload?.email}
										onChange={handleChangeUserPayload}
										required
									/>
								</div>
								{/* <p className="text-red-600 font-bold">{userPayload.full_name}</p> */}
								<div className="mb-3">
									<input
										name="password"
										type="password"
										autoComplete="password"
										className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-4 py-3.5  "
										placeholder="Password"
										value={userPayload?.password}
										onChange={handleChangeUserPayload}
										required
									/>
								</div>
								<div className="mb-3">
									<input
										name="re-password"
										type="password"
										autoComplete="current-password"
										className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-4 py-3.5  "
										placeholder="Re Password"
										required
									/>
								</div>
								<div className="mb-3">
									<input
										name="coupon_code"
										type="text"
										className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-4 py-3.5  "
										placeholder="Coupon Code"
										value={userPayload?.coupon_code || ""}
										onChange={handleChangeUserPayload}
									/>
								</div>
								<div className="mb-3 flex items-center">
									<input
										id="terms"
										name="terms"
										type="checkbox"
										className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
										required
									/>
									<label htmlFor="terms" className="ml-2 block text-sm text-gray-900">
										I agree to the{" "}
										<a href={`${LOGIN_REDIRECTION}terms`} className="text-indigo-600 hover:underline" target="_blank" rel="noopener noreferrer">
											terms and conditions
										</a>
									</label>
								</div>
								<button
									type="submit"
									className="w-full bg-indigo-600 text-white text-sm px-4 py-3.5 rounded-md hover:bg-indigo-700 transition-colors duration-300"
								>
									{loading ? "Loading..." : "Create Your Account"}
								</button>
							</form>
							{/* <SignInGoogle setUserPayload={setUserPayload} /> */}
							<GoogleLoginBtn />

							<OtpModal
								expectedOtp={otp}
								handleSignup={handleSignup}
								open={isModalOpen}
								setOpen={setIsModalOpen}
								key={0}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Register;
