import { Box, Button, IconButton, Tooltip } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import dayjs from "dayjs";
import { useState } from "react";
import { Favorite } from "@mui/icons-material";

const LeadsCard = ({ lead, handleClick, handleOpen, handleSetLocation }: { lead: any, handleClick: any, handleOpen: any, handleSetLocation: any }) => {
	const [isFav, setIsFav] = useState<boolean>(lead.customer_fav || false);
	const handleClickFavorite = () => {
		setIsFav(!isFav);
		handleClick(lead?._id);
	}
	return (
		<Box key={lead._id} className="lead-card">
			<div>
				<div>
					<Box className="lead-card-data">
						<div className="inline-flex items-start justify-between w-full">
							<div >
								<p>Date: {dayjs(lead?.date).format("MM/DD/YYYY")}</p>
								<p>Deal Type: {lead?.deal_type}</p>
								<p>State: {lead?.state}</p>
								<p>City: {lead?.city || lead?.state}</p>
								<p>Zip: {lead?.zip}</p>
							</div>
							<IconButton
								color="primary"
								onClick={handleClickFavorite}
							>
								{
									!isFav ? <FavoriteBorderIcon /> : <Favorite />
								}
							</IconButton>
						</div>
					</Box>
					<div className="card-actions">

						<Tooltip title={lead.customer_already_purchased ? "You have already purchased this lead" : ""}>
							<Button
								className="button-blue"
								endIcon={<AddShoppingCartIcon />}
								onClick={() => handleOpen(lead)}
								style={lead.customer_already_purchased ? { cursor: "not-allowed" } : { backgroundColor: "#e6f0ff", cursor: "pointer" }}
							>
								Buy Lead
							</Button>
						</Tooltip>
						<Button
							className="small-purple-outlined-button"
							endIcon={<SearchIcon />}
							onClick={() => {
								handleSetLocation(lead);
							}}
						>
							Search
						</Button>
					</div>
				</div>

			</div>
		</Box>
	)
}

export default LeadsCard