import { request } from "../api";
import { MESSAGE } from "../../../constants/api/message";
import { Params, Payload } from "../../../@types/api/api.types";
import { headers } from "../../../config/config";

const { post } = request;

const initialRoute = "leads";

export const generalLeadsBulkUpload = async (payload: Payload) => {
	try {
		const endpoint = `${initialRoute}/bulk-upload-general-leads`;
		const response = await post(endpoint, payload, {
			...headers
		});

		if (response?.status === 200) {
			const { message, result } = response.data;
			if (message === MESSAGE.post.succ) {
				return result; // Return the result
			}
		}
		throw new Error();
	} catch (error: any) {
		console.error("Error while login:", error);
		throw error;
	}
};

export const manualUploadGeneralLeads = async (payload: Payload) => {
	try {
		const endpoint = `${initialRoute}/addmanual-general-leads`;
		const response = await post(endpoint, payload, {
			...headers
		});

		if (response) {
			const {
				data: { message }
			} = response;
			if (message === MESSAGE.post.succ) {
				const {
					data: { result }
				} = response;
				return result;
			}
		}
		throw new Error();
	} catch (error: any) {
		console.log(error);
		throw error;
	}
};

export const getAllGeneralLeads = async (params: Params) => {
	try {
		const endpoint = `${initialRoute}/get-general-leads`;
		const response = await request.get(
			endpoint,
			{
				...headers
			},
			params
		);

		if (response?.status === 200) {
			const { message, result, pagination } = response.data;
			if (message === MESSAGE.get.succ) {
				return { result, pagination }; // Return the result
			}
		}
		throw new Error();
	} catch (error: any) {
		console.error("Error while fetching leads:", error);
		throw error;
	}
};
