import { Email, Logout, Person } from "@mui/icons-material";
import { Divider, IconButton, ListItemIcon, Menu, MenuItem, Tooltip } from "@mui/material";
import React, { useContext, useState } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import { DropdownProps } from "../../../@types/props/dropdownProps";
import useAuth from "../../../utils/hooks/auth/useAuth";
import CustomerContext from "../../../contexts/customerContext/customerContext";
import { Link } from "react-router-dom";
import CommonModal from "../commonModal/CommonModal";

const DropdownMenu: React.FC<DropdownProps> = ({ onClick }) => {
	const { handleLogout } = useAuth();
	const { user } = useContext(CustomerContext);
	const [isOpenContactUs, setIsOpenContactUs] = useState<boolean>(false);

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	// const isOnSuperAdminPage = location.pathname.startsWith("/super-admin");
	// const isOnAdminPage = location.pathname.startsWith("/admin");
	// const isOnCustomerPage = location.pathname.startsWith("/customer");
	const open = Boolean(anchorEl);

	const handleOpenContactUs = () => {
		setIsOpenContactUs(true);
	}

	const handleCloseContactUs = () => {
		setIsOpenContactUs(false);
	}

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<div>
			<Tooltip title="Account settings">
				<IconButton
					onClick={handleClick}
					size="small"
					aria-controls={open ? "account-menu" : undefined}
					aria-haspopup="true"
					aria-expanded={open ? "true" : undefined}
				>
					{user?.photo ? (
						<img
							src={user.photo}
							alt="profile"
							style={{ width: 32, height: 32, borderRadius: "50%" }} />
					) :
						(
							<AccountCircleIcon sx={{ width: 32, height: 32 }} />
						)
					}
				</IconButton>
			</Tooltip>
			<Menu anchorEl={anchorEl} id="account-menu" open={open} onClose={handleClose} onClick={handleClose} >
				<MenuItem onClick={onClick} sx={{ padding: "15px" }}>
					<ListItemIcon>
						<Person fontSize="small" />
					</ListItemIcon>
					My Profile
				</MenuItem>
				<MenuItem sx={{ padding: "15px" }} onClick={handleOpenContactUs}>
					<ListItemIcon>
						<Email fontSize="small" />
					</ListItemIcon>
					Contact us
				</MenuItem>
				<Divider />

				<MenuItem onClick={handleLogout}>
					<ListItemIcon>
						<Logout fontSize="small" onClick={handleLogout} />
					</ListItemIcon>
					Logout
				</MenuItem>
			</Menu>
			<CommonModal handleClose={handleCloseContactUs} open={isOpenContactUs}>
				<div className="p-4 bg-white rounded-lg">
					<h2 className="text-xl font-semibold">Contact Us</h2>
					<p className="mt-2">If you are facing any lead-related issues or receiving bad leads, please email us.We will reply back and provide a solution as soon as possible.</p>
					<p className="mt-4">Email: <a href="mailto:help@newleey.com" className="text-blue-500">help@newleey.com</a></p>
				</div>
			</CommonModal>
		</div>
	);
};

export default DropdownMenu;
