import React, { useContext, useEffect } from "react";
import CustomerContext from "../../../contexts/customerContext/customerContext";
import { api } from "../../../utils/api";
import { current } from "@reduxjs/toolkit";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
const CurrentPlan = ({ currentPlan, setCurrentPlan }: { currentPlan: any; setCurrentPlan: any }) => {
	const { user } = useContext(CustomerContext);
	// const getActiviatedPlansById = async () => {
	// 	try {
	// 		const response = await api.plan.getActivatedPlanById(user?.purchase_planid);
	// 		setCurrentPlan(response);
	// 	} catch (error) {
	// 		console.error("Error fetching plans:", error);
	// 	}
	// };

	// useEffect(() => {
	// 	getActiviatedPlansById();
	// }, []);

	return (
		<Link
			to="/customer/subscription"
			className="py-1.5 px-5 bg-indigo-600 cursor-pointer shadow-sm rounded-full transition-all duration-500 text-base text-white font-semibold text-center w-fit block mx-auto hover:bg-indigo-700"
		>
			{user?.is_subscription ? "Upgrade" : "Subscribe"}
		</Link>
	);
};

export default CurrentPlan;

export const isSubscribed = (currentPlan: any): boolean => {
	return currentPlan?.plan_name !== undefined && currentPlan?.plan_name !== null;
};
