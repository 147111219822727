import { useCallback, useEffect, useState } from "react";
import Purchase from "./purchase/Purchase";
import { useFetchWallet } from "../../../../../utils/hooks/fetchWalletBalance/useFetchWallet";
import usePurchaseCartLead from "../../../../../utils/hooks/purchasedCartLead/usePurchasedCartLead";
import WalletCardWrapper from "../../../../shared/walletCardWrapper/WalletCardWrapper";

const ConfirmCartPurchase = ({ onClose, totalBalance, coupon_code }: { onClose: () => void; totalBalance: number, coupon_code?: string }) => {
	const [isCustomerEligible, setIsCustomerEligible] = useState<boolean>(false);

	const { walletBalance, loading: walletLoading } = useFetchWallet();

	const { loading, handleConfirmPurchaseFromCart } = usePurchaseCartLead();

	const handleClickConfirm = (e: any) => {
		e.preventDefault();
		handleConfirmPurchaseFromCart(coupon_code);
	}

	const eligibleCustomer = useCallback(() => {
		if (walletBalance >= totalBalance) {
			setIsCustomerEligible(true);
		}
	}, [walletBalance, totalBalance]);

	useEffect(() => {
		eligibleCustomer();
	}, [eligibleCustomer]);

	return (
		<div>
			{
				walletLoading ? (
					<div className="bg-white p-5">
						"Loading wallet balance..."
					</div>) :
					isCustomerEligible ? (
						<Purchase handleConfirm={handleClickConfirm} loading={loading} />
					) : (
						<WalletCardWrapper minimumAmount={totalBalance - walletBalance} onClose={onClose} mode="PURCHASE_CART" />
					)}
		</div>
	);
};

export default ConfirmCartPurchase;
