import { Button, Tooltip } from "@mui/material";
import React from "react";
import { ILeads } from "../../../@types/interface/leadDetails.interface";
import DeleteIcon from "@mui/icons-material/Delete";
import { api } from "../../../utils/api";
import { useNavigate } from "react-router-dom";

const DeleteLeadCellRenderer = ({ data }: { data: ILeads }) => {
	const navigate = useNavigate();
	const handleDelete = async () => {
		if (!data._id) {
			console.error("Lead ID is missing");
			return;
		}

		try {
			const response = await api.leads.deleteExclusiveLead({ exclusive_lead_id: data._id });

			if (response) {
				alert("Lead deleted successfully");
				console.log("Lead deleted successfully:", response.data);
				window.location.reload();
			} else {
				console.error("Error deleting lead:", response.data.message);
			}
		} catch (error) {
			console.error("Error making delete request:", error);
		}
	};

	return (
		<div>
			{/* Tooltip to indicate why the button is disabled */}
			<Tooltip title={data.is_purchased ? "This lead cannot be deleted , it's already purchased" : "Delete lead"}>
				<span>
					<Button onClick={handleDelete} disabled={data.is_purchased}>
						<DeleteIcon sx={{ color: data.is_purchased ? "gray" : "red" }} />
					</Button>
				</span>
			</Tooltip>
		</div>
	);
};

export default DeleteLeadCellRenderer;
