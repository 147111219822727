import { useCallback, useEffect, useState } from "react";
import { ICustomer } from "../../../@types/interface/Customer.interface";
import { api } from "../../api";

const useCustomerDetails = (customerId: string) => {
  const [customerDetails, setCustomerDetails] = useState<ICustomer | null>(
    null
  );
  const [fetched, setFetched] = useState<boolean>(false);
  const getCustomerDetails = useCallback(async () => {
    try {
      if (customerId === "") return;
      const response = await api.customer.getCustomerDetails({ customerId });
      if (response) {
        setCustomerDetails(response);
      }
    } catch (error) {
      console.error("Error while fetching customer details:", error);
    } finally {
      setFetched(true);
    }
  }, [customerId]);

  useEffect(() => {
    getCustomerDetails();
  }, [getCustomerDetails]);
  return { customerDetails, fetched };
};

export default useCustomerDetails;
