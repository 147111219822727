import { Button, FormControl, InputLabel, MenuItem, Paper, Select, Switch, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import UIContext from "../../../../contexts/uiContext/UIContext";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { ILeads } from "../../../../@types/interface/leadDetails.interface";
import { Details } from "@mui/icons-material";
import { CustomSwitch } from "../../../shared/switch/CustomSwitch";
import AddLeadDetails from "../addLeadDetails/AddLeadDetails";
import PropertyDetails from "../propertyDetails/PropertyDetails";
import SaveIcon from "@mui/icons-material/Save";
import { LeadDetailsProps } from "../../../../@types/props/leadDetails.props";
import { api } from "../../../../utils/api";

const LeadDetails = () => {
	const { setDashboardHeader } = useContext(UIContext);
	// const [isEditable, setIsEditable] = useState<boolean>(false);

	const [checked, setChecked] = useState<boolean>(false);
	const [isReadOnly, setIsReadOnly] = useState<boolean>(true);
	const queryParams = new URLSearchParams(window.location.search);
	const leadObjectId = queryParams.get("lid");
	const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);

	const [leadDetails, setLeadDetails] = useState<any>({
		lead_name: "",
		lead_email: "",
		lead_phone: "",
		address_line_1: "",
		city: "",
		lead_type: "",
		bidding_price: 0,
		state: ""
	});

	// const handleEdit = () => {
	// 	setIsEditable(!isEditable);
	// };

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		setLeadDetails((prevDetails: any) => ({
			...prevDetails,
			[name]: value
		}));
	};

	const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setChecked(event.target.checked);
	};
	// const handleSubmit = () => {
	// 	if (isEditable) {
	// 		alert("Edit Details Before Submitting");
	// 	}
	// 	if (!isEditable) {
	// 		setHasSubmitted(true);
	// 	}
	// };
	const getLeadById = async () => {
		try {
			const filter = {
				leadObjectId: leadObjectId
			};
			const result = await api.leads.getLeadDetails(filter);
			console.log("lead details", result);
			setLeadDetails(result);
		} catch (error) {}
	};

	useEffect(() => {
		getLeadById();
	}, []);
	useEffect(() => {
		setDashboardHeader("Lead Details");
	}, [setDashboardHeader]);

	return (
		<div>
			<div className="edit-btn-container">
				<Button
					onClick={() => setIsReadOnly(!isReadOnly)}
					variant="contained"
					className="button-violet"
					endIcon={isReadOnly ? <EditOutlinedIcon /> : <SaveIcon />}
				>
					{isReadOnly ? "Edit" : "Save"}
				</Button>
			</div>
			<h3 className="text-2xl font-bold text-gray-500 mb-4">Lead Details</h3>
			<Paper className="form-container" variant="outlined">
				<div className="flex-input-field">
					<label>Name:</label>
					<TextField
						type="text"
						variant="outlined"
						className="inputbox"
						onChange={handleChange}
						value={leadDetails?.lead_name}
						name="lead_name"
						inputProps={{ readOnly: isReadOnly }}
					/>
				</div>
				<div className="flex-input-field">
					<label>Email:</label>
					<TextField
						type="text"
						variant="outlined"
						className="inputbox"
						onChange={handleChange}
						value={leadDetails?.lead_email}
						name="lead_email"
						inputProps={{ readOnly: isReadOnly }}
					/>
				</div>
				<div className="flex-input-field">
					<label>State:</label>
					<TextField
						type="text"
						variant="outlined"
						className="inputbox"
						onChange={handleChange}
						value={leadDetails?.state}
						name="state"
						inputProps={{ readOnly: isReadOnly }}
					/>
				</div>
				<div className="flex-input-field">
					<label>Phone Number:</label>
					<TextField
						variant="outlined"
						className="inputbox"
						onChange={handleChange}
						value={leadDetails?.lead_phone}
						name="lead_phone"
						inputProps={{ readOnly: isReadOnly }}
					/>
				</div>

				<div className="flex-input-field">
					<label>Address:</label>
					<TextField
						type="text"
						variant="outlined"
						className="inputbox"
						onChange={handleChange}
						value={leadDetails?.address_line_1}
						name="address_line_1"
						inputProps={{ readOnly: isReadOnly }}
					/>
				</div>
				<div className="flex-input-field">
					<label>City:</label>
					<TextField
						type="text"
						variant="outlined"
						className="inputbox"
						onChange={handleChange}
						value={leadDetails?.city}
						name="city"
						inputProps={{ readOnly: isReadOnly }}
					/>
				</div>
				{/* <div className="flex-input-field">
					<label>Lead Status:</label>
				
					<FormControl sx={{ width: "500px" }}>
						<InputLabel id="demo-simple-select-label">Select Status</InputLabel>
						<Select
							labelId="demo-simple-select-label"
							id="demo-simple-select"
							value={leadDetails?.lead_type}
							label="Select Status"
							name="lead_type"
						>
							<MenuItem value={"ACTIVE"}>Active</MenuItem>
							<MenuItem value={"NEW"}>New</MenuItem>
							<MenuItem value={"PENDING"}>Pending</MenuItem>
							<MenuItem value={"CONTACTED"}>Contacted</MenuItem>
						</Select>
					</FormControl>
				</div> */}
				<div className="flex-input-field">
					<label>Bidding Price (USD):</label>

					<TextField
						type="number"
						variant="outlined"
						className="inputbox"
						onChange={handleChange}
						value={leadDetails?.bidding_price}
						name="bidding_price"
						inputProps={{ readOnly: isReadOnly }}
					/>
				</div>
			</Paper>
			{/* <h3>Property Details</h3> */}
			{/* <Paper className="form-container" variant="outlined">
				<div className="flex-input-field">
					<label>Property Type:</label>
					<TextField
						type="text"
						variant="outlined"
						className="inputbox"
						onChange={handleChange}
						value={leadDetails?.property_type}
						name="property_type"
					/>
				</div>
				<div className="flex-input-field">
					<label>Property Condition:</label>
					<TextField
						type="text"
						variant="outlined"
						className="inputbox"
						onChange={handleChange}
						value={leadDetails?.property_condition}
						name="property_condition"
					/>
				</div>
				<div className="flex-input-field">
					<label>Property Owned Duration:</label>
					<TextField
						type="number"
						variant="outlined"
						className="inputbox"
						onChange={handleChange}
						value={leadDetails?.property_owned_duration}
						name="property_owned_duration"
					/>
				</div>
				<div className="flex-input-field">
					<label>Occupied:</label>
					<TextField
						type="number"
						variant="outlined"
						className="inputbox"
						onChange={handleChange}
						value={leadDetails?.occupied}
						name="occupied"
					/>
				</div>
			</Paper> */}
			{/* <AddLeadDetails />

			<PropertyDetails /> */}

			{/* <Paper className="form-container" elevation={2}>
				<div className="flex-input-field">
					<label>Name:</label>
					<TextField
						type="text"
						variant="outlined"
						className="inputbox"
						InputProps={{
							readOnly: !isEditable
						}}
						defaultValue={"John Doe"}
						onChange={handleInputChange}
						name="lead_name"
					/>
				</div>
				<div className="flex-input-field">
					<label>Email:</label>
					<TextField
						type="text"
						variant="outlined"
						className="inputbox"
						InputProps={{
							readOnly: !isEditable
						}}
						defaultValue={"John@example.com"}
						onChange={handleInputChange}
						name="lead_email"
					/>
				</div>
				<div className="flex-input-field">
					<label>Phone Number:</label>
					<TextField
						type="number"
						variant="outlined"
						className="inputbox"
						InputProps={{
							readOnly: !isEditable
						}}
						defaultValue={123456789}
						onChange={handleInputChange}
						name="lead_phone"
					/>
				</div>
				<div className="flex-input-field">
					<label>Status:</label>
					<div className="switch-button-container">
						<CustomSwitch
							checked={checked}
							onChange={handleSwitchChange}
							inputProps={{ "aria-label": "controlled" }}
							name="lead_type"
						/>
					</div>
				</div>
			</Paper> */}

			{/* <div className="button-container">
				{!isEditable ? null : (
					<Button
						variant="contained"
						type="submit"
						className="button-violet"
						endIcon={<SendOutlinedIcon />}
						onClick={handleSubmit}
					>
						Submit
					</Button>
				)}
			</div> */}
		</div>
	);
};

export default LeadDetails;
