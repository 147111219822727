import { Outlet } from "react-router-dom";
import LeadCardContainer from "../../../shared/leadCardContainer/LeadCardContainer";

const PurchasedLeads = () => {
	return (
		<div>
			<LeadCardContainer />
			<Outlet />
		</div>
	);
};

export default PurchasedLeads;
