import { SelectChangeEvent } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import DataGrid from "../../../../shared/dataGrid/DataGrid";
import UIContext from "../../../../../contexts/uiContext/UIContext";
import { IPagination } from "../../../../../@types/interface/pagination";
import { formatFilters } from "../../../../../utils/commonFunctions/formatApiFilters";
import { api } from "../../../../../utils/api";
import { useNavigate } from "react-router-dom";
import { FilterModel } from "ag-grid-community";
import BasicPagination from "../../../../shared/basicPagination/BasicPagination";
import { CustomerLeadsColDefs } from "../../customerLeads/customerLeadsColDefs/customerLeadsColDefs";
import CustomerContext from "../../../../../contexts/customerContext/customerContext";
import { IExclusiveLeads } from "../../../../../@types/interface/exclusiveLeads.interface";
import DownloadButton from "../../../../shared/downloadButton/DownloadButton";
import useGetPurchasedPremiumLeads from "../../../../../utils/hooks/getPurchasedPremiumLeads/useGetPurchasedPremiumLeads";

const PurchasedPremiumLeads = () => {
	const { setDashboardHeader } = useContext(UIContext);
	const { user } = useContext(CustomerContext);
	const [selectedLead, setSelectdLead] = useState("Leads Accepted");
	const [filters, setFilters] = useState([]);
	const [pagination, setPagination] = useState<IPagination>({
		currentPage: 1,
		pageCount: 1
	});
	const { rowData } = useGetPurchasedPremiumLeads(filters, pagination, setPagination, user?._id || "");

	const handleFilterChange = (filterModel: FilterModel) => {
		setFilters((prevFilters: any) => {
			const sanitizedFilters = { ...prevFilters };
			Object.keys(sanitizedFilters).forEach((key: any) => {
				if (!filterModel[key]) {
					delete sanitizedFilters[key];
				}
			});
			const updatedFilters = { ...sanitizedFilters, ...filterModel };
			console.log("Updated Filters-->", updatedFilters);
			return updatedFilters;
		});
	};

	const handlePageChange = useCallback((event: React.ChangeEvent<unknown>, value: number) => {
		setPagination((prevPagination: IPagination) => ({
			...prevPagination,
			currentPage: value
		}));
	}, []);

	const downloadData = async () => {
		const response = await api.leads.getCustomerExclusiveLeads({ customer_object_id: user?._id });
		return response;
	};

	useEffect(() => {
		setDashboardHeader("Purchased Premium Leads");
	}, [setDashboardHeader]);
	return (
		<div>
			{/* <div className="w-[200px] mt-6 px-4 py-2 bg-indigo-100 border border-indigo-700 rounded-full text-indigo-800 flex items-start justify-start">
				<h3 className="text-lg font-medium">This month limit - {user?.exclusive_leads_limit}</h3>
			</div> */}
			<div className="leads-data-container-grid">
				<div className="add-btn">
					<DownloadButton apiCall={downloadData} fileName="Premium Leads" key={"customer"} />
				</div>
				<DataGrid rowData={rowData} colDefs={CustomerLeadsColDefs} onFilterChange={handleFilterChange} />
				<BasicPagination
					currentPage={pagination.currentPage}
					pageCount={pagination.pageCount}
					handlePageChange={handlePageChange}
				/>
			</div>
			{/* leads details grid starts here */}
		</div>
	);
};

export default PurchasedPremiumLeads;
