import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { WalletModalProps } from "../../../@types/props/walletModal.props";
import WalletModal from "../walletModal/WalletModal";
import { STRIPE_PUBLIC_KEY } from "../../../config/config";

const stripePromise = loadStripe(
	STRIPE_PUBLIC_KEY
);

const WalletCardWrapper: React.FC<WalletModalProps> = (props) => {
	return (
		<Elements stripe={stripePromise}>
			<WalletModal {...props} />
		</Elements>
	);
};

export default WalletCardWrapper;
