import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import MainLayout from "./components/layout/MainLayout";
import { routes } from "./routes";
// import Login from "./components/pages/auth/login/Login";
// import PhoneLogin from "./components/pages/auth/otpLogin/PhoneLogin";
// import VerifyLogin from "./components/pages/auth/otpLogin/VerifyLogin";
import { ForgetUsername, GeneratePasswordLink, LeadBulkUpload, Login, ResetPassword } from "./components/pages";
import Register from "./components/shared/register/Register";
import { useCallback, useContext, useEffect } from "react";
import CustomerContext from "./contexts/customerContext/customerContext";
import AdminContext from "./contexts/adminContext/adminContext";
import ExclusiveLeadBulkUpload from "./components/pages/leads/premiumLeads/exclusiveLeadsBulkUpload/ExclusiveLeadsBulkUpload";
import GeneralLeadBulkUpload from "./components/pages/leads/generalLeads/generalLeadBulkUpload/GeneralLeadBulkUpload";
import useCustomerDetails from "./utils/hooks/customerDetails/useCustomerDetails";
import useAdminDetails from "./utils/hooks/useAdminDetails/useAdminDetails";
import CustomerMainLayout from "./components/layout/customerMainLayout/CustomerMainLayout";
import SuperAdminMainLayout from "./components/layout/superAdminMainLayout/SuperAdminMainLayout";
import useAuth from "./utils/hooks/auth/useAuth";
import CustomerLogin from "./components/pages/auth/customerLogin/CustomerLogin";
import AddInfluencer from "./components/pages/influencers/addInfluencers/AddInfluencer";
import useVerifyToken from "./utils/hooks/useVerifyToken/useVerifyToken";
import AutoLogout from "./components/layout/autoLogout/AutoLogout";

const App = () => {
	const { user } = useContext(CustomerContext);
	const { verified, hasFetched } = useVerifyToken();
	const { admin, setAdmin } = useContext(AdminContext);
	const { adminId, token } = useAuth();

	const { adminDetails, fetchedd } = useAdminDetails(adminId || "");


	const getAdmin = useCallback(() => {
		if (adminDetails) {
			setAdmin(adminDetails);
		}
	}, [adminDetails, setAdmin]);

	return (
		<BrowserRouter>
			<AutoLogout />
			<Routes>
				{/* Auth Routes*/}

				<Route path="/admin/login" element={<Login />} />

				<Route path="/login" element={<CustomerLogin />} />
				<Route path="/sign-up" element={<Register />} />
				<Route path="/forget-password" element={<GeneratePasswordLink />} />
				{/* <Route path="/customer/dashboard" element={<CustomerDashboard />} /> */}
				{/* <Route path="/forget-password/verify-token/:token" element={<ResetPassword />} /> */}

				{/* Admin Route */}
				{!hasFetched || !fetchedd ? (
					<Route path="/" element={<div>Loading ...</div>} />
				) : (
					<Route
						path="/"
						element={
							verified || user ? (
								<CustomerMainLayout />
							) : adminDetails || admin ? (
								<SuperAdminMainLayout />
							) : (
								<Navigate to="/login" />
							)
						}
					>
						{routes}
						<Route path="/super-admin/marketplace-leads-bulk-upload" element={<LeadBulkUpload />} />
						<Route path="/super-admin/premium-leads-bulk-upload" element={<ExclusiveLeadBulkUpload />} />
						<Route path="/super-admin/general-leads-bulk-upload" element={<GeneralLeadBulkUpload />} />
					</Route>
				)}
			</Routes>
		</BrowserRouter>
	);
};

export default App;
