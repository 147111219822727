const Otp = ({ handleSubmit, otp, handleOtpChange, handleSignup, inputsRef, handleKeyDown }: any) => {
	return (
		<div className="relative font-inter antialiased">
			<main className="relative min-h-screen flex flex-col justify-center bg-transparent overflow-hidden">
				<div className="w-full max-w-6xl mx-auto px-4 md:px-6 py-24">
					<div className="flex justify-center">
						<div className="max-w-md mx-auto text-center bg-white px-4 sm:px-8 py-10 rounded-xl shadow">
							<header className="mb-8">
								<h1 className="text-2xl font-bold mb-1">Email Verification</h1>
								<p className="text-[15px] text-slate-500">
									Enter the 4-digit verification code that was sent to your email.
								</p>
							</header>
							<form id="otp-form" onSubmit={handleSubmit}>
								<div className="flex items-center justify-center gap-3">
									{/* {otp.map((index: any) => (
                    <input
                      key={index}
                      type="text"
                      value={otp[index]}
                      className="w-14 h-14 text-center text-2xl font-extrabold text-slate-900 bg-slate-100 border border-transparent hover:border-slate-200 appearance-none rounded p-4 outline-none focus:bg-white focus:border-[#FF9037] focus:ring-2 focus:ring-indigo-100"
                      pattern="\d*"
                      maxLength={1}
                      onChange={(e) => handleChange(index, e.target.value)}
                    />
                  ))} */}
									{otp.map((digit: string | number, index: number) => (
										<input
											key={index}
											type="text"
											maxLength={1}
											className="w-14 h-14 text-center text-2xl font-extrabold text-slate-900 bg-slate-100 border border-transparent hover:border-slate-200 appearance-none rounded p-4 outline-none focus:bg-white focus:border-[#FF9037] focus:ring-2 focus:ring-indigo-100"
											value={digit}
											onChange={(e: any) => handleOtpChange(e, index)}
											onKeyDown={(e: any) => handleKeyDown(e, index)}
											ref={(input: any) => (inputsRef.current[index] = input)}
										/>
									))}
								</div>
								<div className="max-w-[260px] mx-auto mt-4">
									<button
										type="submit"
										className="w-full inline-flex justify-center whitespace-nowrap rounded-lg bg-[#8c68e1] px-3.5 py-2.5 text-sm font-medium text-white shadow-sm shadow-indigo-950/10  focus:outline-none focus:ring focus:ring-indigo-300 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 transition-colors duration-150"
										onClick={handleSignup}
									>
										Verify Email
									</button>
								</div>
							</form>
							{/* <Otp/> */}
							<div className="text-sm text-slate-500 mt-4">
								Didn receive any code?{" "}
								<a className="font-medium text-[#8c68e1] " href="#0">
									Resend Code
								</a>
							</div>
						</div>
					</div>
				</div>
			</main>
		</div>
	);
};

export default Otp;
