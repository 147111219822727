import React, { useCallback, useContext, useEffect, useState } from "react";
import DataGrid from "../../shared/dataGrid/DataGrid";
import UIContext from "../../../contexts/uiContext/UIContext";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Groups3Icon from "@mui/icons-material/Groups3";
import { InfluencerColDefs } from "./influencerColDefs/influencerColDefs";
import useGetInfluencers from "../../../utils/hooks/getInfluencers/useGetInfluencers";
import { api } from "../../../utils/api";
import BasicPagination from "../../shared/basicPagination/BasicPagination";
import { IPagination } from "../../../@types/interface/pagination";

const Influencers = () => {
	const navigate = useNavigate();
	const [influencer, setInfluencer] = useState([]);
	const [pagination, setPagination] = useState({ currentPage: 0, pageCount: 0 });
	const { setDashboardHeader } = useContext(UIContext);
	// const { influencers } = useGetInfluencers({ filter });

	const handleNavigateInfluencers = () => {
		navigate("/super-admin/add-influencers");
	};


	const handlePageChange = useCallback((event: React.ChangeEvent<unknown>, value: number) => {
		setPagination((prevPagination: IPagination) => ({
			...prevPagination,
			currentPage: value
		}));
	}, []);

	const fetchAllInfluencers = useCallback(
		async () => {
			try {
				const filter = {
					page: pagination.currentPage,
				}
				const response = await api.influencer.getInfluencers(filter);
				if (response) {
					setInfluencer(response.result);
					setPagination(response.pagination);
				}
			} catch (error) {
				console.error("Error while fetching data:", error);
			}
		}, [pagination.currentPage]
	)

	useEffect(() => {
		fetchAllInfluencers();
	}, [fetchAllInfluencers])
	useEffect(() => {
		setDashboardHeader("Influencers");
	}, [setDashboardHeader]);
	return (
		<div>
			<div className="add-btn">
				<Button
					component="label"
					variant="contained"
					tabIndex={-1}
					className="button-violet"
					endIcon={<Groups3Icon />}
					onClick={handleNavigateInfluencers}
				>
					Add Influencer
				</Button>
			</div>
			<DataGrid rowData={influencer} colDefs={InfluencerColDefs} />
			<BasicPagination currentPage={pagination.currentPage} pageCount={pagination.pageCount} handlePageChange={handlePageChange} />
		</div>
	);
};

export default Influencers;
