import { ColDef } from "ag-grid-community";
import { ReferralCellRenderer } from "./referralCellRenderer/referralCellRenderer";

export const InfluencerColDefs: ColDef[] = [
  {
    field: "full_name",
    headerName: "Name",
    suppressSizeToFit: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
  },
  {
    field: "email",
    headerName: "Email",
    suppressSizeToFit: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
  },
  {
    field: "phone_number",
    headerName: "Phone number",
    suppressSizeToFit: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
  },
  {
    field: "coupon_code",
    headerName: "Coupon code",

    suppressSizeToFit: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
  },
  {
    field: "referral_url",
    headerName: "Referral URL",
    cellRenderer: ReferralCellRenderer,
    suppressSizeToFit: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
  },
  {
    field: "referred_customers_no",
    headerName: "No of customers",

    suppressSizeToFit: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
  },
  {
    field: "instagram_handle",
    headerName: "Instagram handle",
    suppressSizeToFit: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
  },
  {
    field: "twitter_handle",
    headerName: "Twitter handle",
    suppressSizeToFit: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
  },
  {
    field: "facebook_handle",
    headerName: "Facebook handle",
    suppressSizeToFit: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
  },
  {
    field: "percentOff",
    headerName: "Discount offer",
    suppressSizeToFit: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
  },
  {
    field: "duration",
    headerName: "Duration",

    suppressSizeToFit: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
  },

  {
    field: "planIds",
    headerName: "Plan name",

    suppressSizeToFit: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
  },
  {
    field: "durationInMonths",
    headerName: "Month Duration",
  },
];
