import { useState } from "react";
import DataGrid from "../../../../../shared/dataGrid/DataGrid";
import useGetPurchasedExclusiveLeads from "../../../../../../utils/hooks/getPurchasedExclusiveLeads/useGetPurchasedExclusiveLeads";
import { IPagination } from "../../../../../../@types/interface/pagination";
import useQuerySearch from "../../../../../../utils/hooks/querySearch/useQuerySearch";
import { ExclusiveLeadsDataColDefs } from "./exclusiveLeadsDataColDefs/ExclusiveLeadsDataColDefs";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const ExclusiveLeadDetails = () => {
	const navigate = useNavigate();
	const [filters, setFilters] = useState([]);
	const customerId = useQuerySearch("customerId");
	const [pagination, setPagination] = useState<IPagination>({
		currentPage: 1,
		pageCount: 1
	});
	const { rowData: exclusiveLeads } = useGetPurchasedExclusiveLeads(
		filters,
		pagination,
		setPagination,
		customerId || ""
	);
	const handleNavigateToExclusiveLeads = () => {
		navigate(`/super-admin/assign-exclusive-leads?customerId=${customerId}`);
	};
	return (
		<div>
			<div className="add-btn">
				<Button
					className="button-dark-blue"
					endIcon={<AssignmentIcon />}
					onClick={handleNavigateToExclusiveLeads}
					sx={{ padding: "8px" }}
				>
					Assign leads
				</Button>
			</div>
			<DataGrid rowData={exclusiveLeads} colDefs={ExclusiveLeadsDataColDefs} />
		</div>
	);
};

export default ExclusiveLeadDetails;
