import { request } from "../api";
import { MESSAGE } from "../../../constants/api/message";
import { Params, Payload } from "../../../@types/api/api.types";
import { headers } from "../../../config/config";

const { post, get } = request;

const initialRoute = "payments";

export const createPaymentCheckout = async (payload: Payload) => {
  try {
    const endpoint = `${initialRoute}/create-checkout-session`;
    const response = await post(endpoint, payload, {
      ...headers,
    });

    if (response?.status === 200) {
      const { message, result } = response.data;
      if (message === MESSAGE.post.succCheckout) {
        return result; // Return the result
      }
    }
    throw new Error();
  } catch (error: any) {
    console.error("Error while Checkout:", error);
    throw error;
  }
};
export const createSubscriptionPaymentCheckout = async (payload: Payload) => {
  try {
    const endpoint = `${initialRoute}/create-subscription-checkout-session`;
    const response = await post(endpoint, payload, {
      ...headers,
    });
    if (response?.status === 200) {
      const { message, result } = response.data;
      if (message === MESSAGE.post.succCheckout) {
        return result; // Return the result
      }
    }
    throw new Error();
  } catch (error: any) {
    console.error("Error while Checkout:", error);
    throw error;
  }
};

export const updatewallet = async (payload: Payload) => {
  try {
    const endpoint = `${initialRoute}/update-wallet`;
    const response = await post(endpoint, payload, {
      ...headers,
    });

    if (response?.status === 200) {
      const { message, result } = response.data;
      if (message === MESSAGE.post.succWalletUpdate) {
        return result; // Return the result
      }
    }
    throw new Error();
  } catch (error: any) {
    console.error("Error while Checkout:", error);
    throw error;
  }
};

export const createSubscription = async (payload: Payload) => {
  try {
    const endpoint = `${initialRoute}/create-subscription`;
    const response = await post(endpoint, payload, {
      ...headers,
    });

    if (response?.status === 200) {
      const { message, result } = response.data;
      if (message === MESSAGE.post.succCheckout) {
        return result; // Return the result
      }
    }
    throw new Error();
  } catch (error: any) {
    console.error("Error while creating subscription:", error);
    throw error;
  }
};

export const cancelSubscription = async (payload: Payload) => {
  try {
    const endpoint = `${initialRoute}/cancel-subscription`;
    const response = await post(endpoint, payload, {
      ...headers,
    });

    if (response?.status === 200) {
      const { message, result } = response.data;
      if (message === "Subscription Canceled") {
        return result; // Return the canceled subscription result
      }
    }
    throw new Error();
  } catch (error: any) {
    console.error("Error while cancelling subscription:", error);
    throw error;
  }
};

export const cancelOfferClaim = async (payload: Payload) => {
  try {
    const endpoint = `${initialRoute}/cancel-claim-offer`;
    const response = await post(endpoint, payload, {
      ...headers,
    });

    if (response?.status === 200) {
      const { message, result } = response.data;
      if (message === MESSAGE.post.succ) {
        return result; // Return the canceled subscription result
      }
    }
    throw new Error();
  } catch (error: any) {
    console.error("Error while cancelling subscription:", error);
    throw error;
  }
};

export const createCustomer = async (payload: Payload) => {
  try {
    const endpoint = `${initialRoute}/create-customer`;
    const response = await post(endpoint, payload, {
      ...headers,
    });

    if (response?.status === 200) {
      const { message, result } = response.data;
      if (message === "Customer created successfully") {
        return result; // Return the created customer result
      }
    }
    throw new Error();
  } catch (error: any) {
    console.error("Error while creating customer:", error);
    throw error;
  }
};
export const createPayment = async (payload: Payload) => {
  try {
    const endpoint = `${initialRoute}/create-payment`; // Update endpoint name
    const response = await post(endpoint, payload, {
      ...headers,
    });

    if (response?.status === 200) {
      const { message, result } = response.data;
      if (message === MESSAGE.post.succCheckout) {
        return result; // Return the payment intent details
      }
    }
    throw new Error();
  } catch (error: any) {
    console.error("Error while creating payment intent:", error);
    throw error;
  }
};

export const getDiscountPrice = async (filter: Params) => {
  try {
    const endpoint = `${initialRoute}/get-discount-price`; // Update endpoint name
    const response = await get(
      endpoint,
      {
        ...headers,
      },
      filter
    );

    if (response?.status === 200) {
      const { message, result } = response.data;
      if (message === MESSAGE.get.succ) {
        return result; // Return the payment intent details
      }
    }
    throw new Error();
  } catch (error: any) {
    console.error("Error while creating payment intent:", error);
    throw error;
  }
};
