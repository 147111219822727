import { TextField, InputBase } from "@mui/material";
import { withStyles } from "@mui/styles";

export const ThemeCustomInputField = withStyles({
	root: {
		"& .MuiInputBase-root": {
			background: "#F3F3F3",
			borderRadius: "5px",
			fontSize: "16px",
			width: "100%",
			height: "50px",
			margin: "0px 0px",
			paddinTop: "20px",
			//border: "1px solid #8C68E1",

			"&:focus": {
				border: "1px solid #80bdff",
				boxShadow: "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
			}
		},
		"& .MuiInputBase-root input": {
			height: "0.18759999999999977em"
		},
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				border: "none" // Removes the border when not focused
			},
			"&.Mui-focused fieldset": {
				border: "1px solid #8C68E1" // Removes the border when focused
			}
			//height: "0.18759999999999977em"
			// "&:hover fieldset": {
			// 	border: "none" // Removes the border on hover
			// },
			// "&.Mui-focused:not(.Mui-error) .MuiOutlinedInput-notchedOutline": {
			// 	border: "none" // Removes the border when focused and not in error state
			// }
		}
	}
})(TextField);
