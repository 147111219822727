import { Paper, TextField } from "@mui/material";
import PremiumLeadAutoComplete from "../../../shared/premiumLeadsAutoComplete/PremiumLeadAutoComplete";
import { SelectLeadFormProps } from "../../../../@types/props/selectLeadFromProps.types";

const SelectLeadsForm = ({
	searchBy,
	handleSearchBy,
	customerDetails,
	leads,
	handleChange,
	handleSelectLeads
}: SelectLeadFormProps) => {
	return (
		<Paper sx={{ marginTop: "20px" }}>
			<div className="flex-input-field">
				<label>Full Name:</label>
				<TextField
					type="text"
					sx={{
						"& .MuiOutlinedInput-root": {
							"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
								borderColor: "#7c00fe"
							},

							"& .MuiOutlinedInput-notchedOutline": {
								borderWidth: "1px"
							}
						}
					}}
					className="inputbox"
					variant="outlined"
					value={customerDetails?.first_name + " " + customerDetails?.last_name}
					inputProps={{ readOnly: true }}
					disabled={true}
				/>
			</div>
			<div className="flex-input-field">
				<label>Search By:</label>
				<div className="flex space-x-4">
					<label className="flex items-center space-x-2">
						<input
							type="radio"
							name="searchBy"
							value="name"
							onChange={handleSearchBy}
							defaultChecked
							className="form-radio text-purple-600"
						/>
						<span className="text-gray-700">Name</span>
					</label>
					<label className="flex items-center space-x-2">
						<input
							type="radio"
							name="searchBy"
							onChange={handleSearchBy}
							value="zip"
							className="form-radio text-purple-600"
						/>
						<span className="text-gray-700">ZIP</span>
					</label>
				</div>
			</div>
			{searchBy === "name" ? (
				<div className="flex-input-field">
					<label>Select Leads by Name:</label>
					<PremiumLeadAutoComplete
						leads={leads}
						handleChange={handleChange}
						handleSelectLeads={handleSelectLeads}
					/>
				</div>
			) : (
				<div className="flex-input-field">
					<label>Select Leads by ZIP:</label>
					<PremiumLeadAutoComplete
						leads={leads}
						handleChange={handleChange}
						handleSelectLeads={handleSelectLeads}
					/>
				</div>
			)}
		</Paper>
	);
};

export default SelectLeadsForm;
