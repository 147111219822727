import { useCallback, useEffect, useState } from "react";
import { api } from "../../api";

const useAdminDetails = (adminId: string) => {
	const [adminDetails, setAdminDetails] = useState<any | null>(null);
	const [fetchedd, setFetched] = useState<boolean>(false);

	const getAdminDetails = useCallback(async () => {
		try {
			if (adminId === "") return;
			const response = await api.admin.getAdminDetails({ groupOwnerId: adminId });
			if (response) {
				setAdminDetails(response);
			}
		} catch (error) {
			console.error("Error while fetching admin details:", error);
		} finally {
			setFetched(true);
		}
	}, [adminId]);

	useEffect(() => {
		getAdminDetails();
	}, [getAdminDetails]);

	return { adminDetails, fetchedd };
};

export default useAdminDetails;
