
import { useContext, useEffect, useState } from "react";
import UIContext from "../../../contexts/uiContext/UIContext";
import AlertDialog from "./AlertDialog";
import PlanCard from "./planCard/PlanCard";
import useGetAllPlans from "../../../utils/hooks/getAllPlans/useGetAllPlans";
import { BILLING_MODES } from "../../../constants/billingMode/BillingMode";
import { BillingType } from "../../../@types/types/billing/billing.type";
import { IPlan } from "../../../@types/interface/plan.interface";
import useGetPaymentMethod from "../../../utils/hooks/useGetPaymentMethod/useGetPaymentMethod";

const Pricing = () => {
	const { setDashboardHeader } = useContext(UIContext);
	const [billMode, setBillMode] = useState<BillingType>(BILLING_MODES.monthly as unknown as BillingType); // Toggle between monthly/yearly billing
	const [isDialogOpen, setIsDialogOpen] = useState(false); // State for dialog visibility
	const { plans } = useGetAllPlans(billMode); // Fetch all plans based on billing mode
	const { paymentMethod } = useGetPaymentMethod();
	const customPlan: IPlan = {
		_id: "1",
		price: 0,
		plan_name: "Custom Plan",
		plan_details: "Custom Plan",
		is_recommended: false,
		exclusive_leads_limit: 0,
		premium_leads_limit: 0,
		plan_occurance: BILLING_MODES.monthly as unknown as BillingType,
		sequence: 0,
		priceId: "",
		productId: "",
		benefits: [
			{ benifit: "Custom Plan", is_active: true },
			{ benifit: "Contact to Customize", is_active: true }
		]
	};

	const handleToogleDialog = () => {
		setIsDialogOpen((prev) => !prev);
	};

	const handleToofleBilling = () => {
		setBillMode(
			(prevMode) =>
				(prevMode === BILLING_MODES.monthly ? BILLING_MODES.yearly : BILLING_MODES.monthly) as BillingType
		);
	};

	useEffect(() => {
		setDashboardHeader("Subscription Plans");
	}, [setDashboardHeader]);

	return (
		<section className="py-1">
			<div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
				<div className="mb-12">
					<h2 className="font-manrope text-2xl lg:text-5xl text-center font-bold text-gray-900 mb-4">
						Choose your plan
					</h2>

					<div className="flex justify-center items-center">
						<label className="min-w-[3.5rem] text-xl relative text-gray-900 mr-4 font-medium">
							Bill Monthly
						</label>
						<div className="relative inline-flex items-center cursor-pointer" onClick={handleToofleBilling}>
							<input
								type="checkbox"
								id="toggle"
								className="sr-only"
								checked={billMode === BILLING_MODES.yearly}
								onChange={handleToofleBilling}
							/>
							<div
								className={`block w-14 h-8 rounded-full ${billMode === BILLING_MODES.yearly ? "bg-indigo-500" : "bg-indigo-100"
									}`}
							></div>
							<div
								className={`dot absolute top-1 left-1 w-6 h-6 rounded-full bg-white transition-transform ${billMode === BILLING_MODES.yearly ? "translate-x-6" : ""
									}`}
							></div>
						</div>
						<label className="relative min-w-[3.5rem] font-medium text-xl text-gray-500 ml-4">
							Bill Yearly
						</label>
					</div>
				</div>

				<div className="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-8 lg:space-y-0 lg:items-center">
					{plans.map((plan) => (
						<PlanCard key={plan?._id || ""} plan={plan} isYearly={billMode === BILLING_MODES.yearly} paymentMethod={paymentMethod} />
					))}
					<PlanCard key="custom_plan" plan={customPlan} isYearly={billMode === BILLING_MODES.yearly} paymentMethod={paymentMethod} />
				</div>
			</div>
			{isDialogOpen && <AlertDialog isDialogOpen={isDialogOpen} setIsDialogOpen={setIsDialogOpen} />}
		</section>
	);
};

export default Pricing;
