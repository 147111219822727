import { useCallback, useContext, useEffect, useState } from "react";
import { api } from "../../api";
import CustomerContext from "../../../contexts/customerContext/customerContext";

export const useFetchWallet = () => {
  const [walletBalance, setWalletBalance] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const { user } = useContext(CustomerContext);
  const getWalletBalance = useCallback(async () => {
    if (!user) {
      return;
    }
    try {
      setLoading(true);
      const filter = {
        cutomerId: user?._id,
      };
      const response = await api.wallet.getWalletTransaction(filter);
      const { wallet } = response;
      setWalletBalance(wallet.wallet_balance);
    } catch (error) {
      console.error("Failed to get wallet balance");
    } finally {
      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    console.log("===>hello");
    getWalletBalance();
  }, [getWalletBalance]);

  return { walletBalance, loading };
};
