const AlertDialog = ({
	isDialogOpen,
	setIsDialogOpen
}: {
	isDialogOpen: boolean;
	setIsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
	const closeDialog = () => {
		setIsDialogOpen(false); // Close dialog without action
	};

	const confirmCancelSubscription = async () => {
		try {
			console.log("Subscription canceled!");
			setIsDialogOpen(false); // Close dialog
		} catch (error) {
			console.error("Error canceling subscription:", error);
		}
	};
	return (
		<>
			{isDialogOpen && (
				<div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
					<div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
						<h3 className="font-bold text-lg mb-4">Cancel Subscription</h3>
						<p className="mb-4">Are you sure you want to cancel your subscription?</p>
						<div className="flex justify-end space-x-4">
							<button
								className="bg-gray-200 text-gray-800 px-4 py-2 rounded hover:bg-gray-300"
								onClick={closeDialog}
							>
								Cancel
							</button>
							<button
								className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700"
								onClick={confirmCancelSubscription}
							>
								Confirm
							</button>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default AlertDialog;
