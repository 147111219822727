import { useContext, useState } from "react";
import TimerCountDown from "../../../../shared/timer/TimerCountDown";
import useSubscriptionCancelOffer from "../../../../../utils/hooks/subscriptionCancelOffer/useSubscriptionCancelOffer";
import CustomerContext from "../../../../../contexts/customerContext/customerContext";
import Modal from "../../../../shared/removeCardModal/RemoveCardModal";
import ConfirmCancel from "../confirmCancel/ConfirmCancel";
import CommonModal from "../../../../shared/commonModal/CommonModal";

const CancelComponent = (
	{ handleToggleModal }: { handleToggleModal: () => void }
) => {

	const [confirmCancelModal, setConfirmCancelModal] = useState<boolean>(false);
	const { user } = useContext(CustomerContext);
	const { handleClaimOffer } = useSubscriptionCancelOffer();

	const handleOpenConfirmCancelModal = () => {
		setConfirmCancelModal(true);
	}

	const handleCancelConfirmCancelModal = () => {
		setConfirmCancelModal(false);
		handleToggleModal();
	}

	const handleClaimButtonClick = () => {
		if (user?.has_cancelled_claim) {
			handleToggleModal();
			return;
		};
		handleToggleModal();
		handleClaimOffer();
	};
	return (
		<div className="w-full bg-white p-4 md:p-0">
			<h2 className="text-lg md:text-2xl font-semibold mb-3">
				Cancel this <span className="text-blue-700">Subscription?</span>
			</h2>
			<div className="flex flex-col gap-2 items-center">
				{!user?.has_cancelled_claim ? (
					<p className="mb-4 py-2 text-gray-600 rounded text-sm md:text-base text-center">
						Don't cancel this subscription & get up to 50% offer on next subscription.
						Offer valid for <TimerCountDown />. Hurry up!
					</p>
				) : (
					<p className="mb-4 py-2 text-gray-600 rounded text-sm md:text-base text-center">
						Don't cancel your subscription, we will miss you! If you cancel now, you
						will not receive any new leads next month. Stay with us and continue
						enjoying the benefits of your subscription.
					</p>
				)}
			</div>

			<div className="flex flex-row gap-2 mt-2">
				<button
					onClick={handleOpenConfirmCancelModal}
					className="w-full md:w-auto text-black bg-gray-200 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
				>
					Cancel Plan
				</button>
				<button
					onClick={handleClaimButtonClick}
					className="w-full md:w-auto text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
				>
					{!user?.has_cancelled_claim ? "Claim offer" : "Enjoy benefits"}
				</button>
			</div>

			{confirmCancelModal && (
				<CommonModal handleClose={handleCancelConfirmCancelModal} open={confirmCancelModal}>
					<ConfirmCancel handleToogleConfirmCancelModal={handleCancelConfirmCancelModal} />
				</CommonModal>
			)}
		</div>

	);
};

export default CancelComponent;
