import { useContext, useEffect, useState } from "react";
import UIContext from "../../../contexts/uiContext/UIContext";
import { Button } from "@mui/material";
import DataGrid from "../../shared/dataGrid/DataGrid";
import { PlanColDefs } from "../../../constants/plans/plansColDefs";
import { useNavigate } from "react-router-dom";
import { api } from "../../../utils/api";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { IPlan } from "../../../@types/interface/plan.interface";

const Plans = () => {
	const navigate = useNavigate();

	const { setDashboardHeader } = useContext(UIContext);
	const [rowData, setRowData] = useState<IPlan[]>([]);
	const handleAddPlans = () => {
		navigate("/super-admin/add-plans");
	};

	useEffect(() => {
		setDashboardHeader("Subscription Plans");
	}, [setDashboardHeader]);

	const getAllPlans = async () => {
		try {
			const filter = {};
			const response = await api.plan.getAllSubscriptionPlan(filter);
			console.log("=====>plans list", response);
			setRowData(response);
		} catch (error) {}
	};

	useEffect(() => {
		getAllPlans();
	}, []);

	return (
		<div className="builders-container">
			<div className="add-btn">
				<Button
					component="label"
					variant="contained"
					tabIndex={-1}
					className="button-violet"
					startIcon={<AddCircleIcon />}
					onClick={handleAddPlans}
				>
					Create Subscription Plan
				</Button>
			</div>
			<DataGrid colDefs={PlanColDefs} rowData={rowData} key={0} />

			{/* <BasicPagination
				currentPage={pagination.currentPage}
				pageCount={pagination.pageCount}
				handlePageChange={handlePageChange}
			/> */}
			{/* <BasicPagination /> */}
		</div>
	);
};

export default Plans;
