import { ColDef } from "ag-grid-community";
import DateCellRenderer from "../../components/shared/dateCellRenderer/FormattedDateCellRenderer";
import ActionCellRenderer from "../../components/pages/plans/actionCellRenderer/ActionCellRenderer";

export const PlanColDefs: ColDef[] = [
	{
		field: "plan_name",
		headerName: "Name",
		suppressSizeToFit: true,
		filter: "agTextColumnFilter",
		floatingFilter: true,
		flex: 1
	},
	{
		field: "price",
		headerName: "Monthly Price",
		suppressSizeToFit: true,
		filter: "agTextColumnFilter",
		floatingFilter: true,
		flex: 1
	},
	{
		field: "plan_occurance",
		headerName: "Billing Type",
		suppressSizeToFit: true,
		filter: "agTextColumnFilter",
		floatingFilter: true,
		flex: 1
	},
	{
		field: "exclusive_leads_limit",
		headerName: "Ex. Leads Lmt",
		flex: 1
	},
	{
		field: "premium_leads_limit",
		headerName: "Prem. Leads Lmt",
		flex: 1
	},
	{
		field: "createdAt",
		headerName: "Date",
		cellRenderer: DateCellRenderer,
		flex: 1
	},
	{
		field: "Action",
		headerName: "Action",
		cellRenderer: ActionCellRenderer,
		flex: 1
	}
];
