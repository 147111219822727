import { request } from "../api";
import { headers } from "../../../config/config";
import { MESSAGE } from "../../../constants/api/message";
import { Payload } from "../../../@types/api/api.types";

const { post } = request;

const initialRoute = "customers";

export const createExclusiveLeadBucket = async (payload: Payload) => {
	try {
		const endpoint = `${initialRoute}/customer-exclusive-lead-bucket`;
		const response = await post(endpoint, payload, {
			...headers
		});

		if (response?.status === 200) {
			const { message, result } = response.data;

			if (message === MESSAGE.post.succ) {
				return result; // Return the result
			} else {
				throw new Error("Login failed");
			}
		}
	} catch (error: any) {
		console.error("Error while login:", error.message);
	}
};
export const createMultiplePremiumLeadBucket = async (payload: Payload) => {
	try {
		const endpoint = `${initialRoute}/create-multiple-customer-premium-leads`;
		const response = await post(endpoint, payload, {
			...headers
		});

		if (response?.status === 200) {
			const { message, result } = response.data;

			if (message === MESSAGE.post.succ) {
				return result; // Return the result
			} else {
				throw new Error("Lead Insert failed");
			}
		}
	} catch (error: any) {
		console.error("Error while login:", error.message);
	}
};
