import { useCallback, useEffect, useState } from "react";
import { IPlan } from "../../../@types/interface/plan.interface";
import { api } from "../../api";
import { BillingType } from "../../../@types/types/billing/billing.type";

const useGetAllPlans = (billMode?: BillingType) => {
  // Make billMode optional
  const [plans, setPlans] = useState<IPlan[]>([]);

  const getAllPlans = useCallback(async () => {
    try {
      const response = await api.plan.getAllSubscriptionPlan(
        billMode ? { plan_occurance: billMode } : {} // Fetch all plans if billMode is not provided
      );
      setPlans(response);
    } catch (error) {
      console.error("Error fetching plans:", error);
    }
  }, [billMode]);

  useEffect(() => {
    getAllPlans();
  }, [getAllPlans]);

  return { plans };
};

export default useGetAllPlans;
